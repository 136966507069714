define('awesome2/helpers/payplan-class', ['exports', 'lodash'], function (exports, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.payplanClass = payplanClass;


  const payplanType = [{ type: 'free', panel: 'panel-grey', btn: 'btn-default' }, { type: 'intermediate', panel: 'panel-blue', btn: 'btn-primary' }, { type: 'pro', panel: 'panel-green', btn: 'btn-success' }];

  function payplanClass(params /*, hash*/) {
    let [payplan_id, domElement] = params;
    return _lodash.default.map(_lodash.default.filter(payplanType, { 'type': payplan_id }), domElement)[0];
  }

  exports.default = Ember.Helper.helper(payplanClass);
});