define('awesome2/routes/purchase/checkout', ['exports', 'awesome2/adapters/vintage', 'awesome2/adapters/product', 'awesome2/routes/base', 'ember-local-storage', 'lodash', 'awesome2/utils/purchase-utils', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-route-history/mixins/routes/route-history'], function (exports, _vintage, _product, _base, _emberLocalStorage, _lodash, _purchaseUtils, _authenticatedRouteMixin, _routeHistory) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend(_authenticatedRouteMixin.default, _routeHistory.default, {

        //authenticationRoute : "/accounts/login", //replace by captcha
        authenticationRoute: "/accounts/captcha", // NOK yet return jwt but no email...
        //authenticationRoute : "/accounts/identification", //OK return email
        routeHistory: Ember.inject.service(),

        wineryService: Ember.inject.service("winery"),
        productService: Ember.inject.service("product"),
        orderService: Ember.inject.service("order"),
        cart: (0, _emberLocalStorage.storageFor)("cart"),
        user: (0, _emberLocalStorage.storageFor)("user"),
        model(params) {
            //return this.get('orderService').checkout(this);
            const currentRouteName = this.get('routeHistory.current');
            if ("purchase.index" != currentRouteName) {
                this.transitionTo("/purchase");
            }
            let items = this.get("cart.content");
            if (items.length == 0) {
                this.transitionTo("/purchase");
            }
            return getInfo(this);
        }
    });


    function getInfo(that) {
        let model = that.store.createRecord("customer-purchase");
        let userStorage = that.get("user");
        if (userStorage && userStorage.get("login")) {
            let userLogin = userStorage.get("login");
            model.set("customerEmail", userLogin);
        } else {
            model.set("customerEmail", "test@test.com");
        }
        let winery = that.store.createRecord("winery");

        winery.set("name", "Sample winery");
        winery.set("contact", "Contact");
        model.set("winery", winery);
        //https://discuss.emberjs.com/t/how-to-make-this-code-a-promise/13210/4
        let promesses = [];
        let items = that.get("cart.content");
        let vintageAdapter = _vintage.default.create();
        let productAdapter = _product.default.create();

        _lodash.default.forEach(items, d => {
            let productLine = that.store.createRecord("product-line");
            if (d.eventId) {
                let vintagePromise = productAdapter.findProductItemPriceByEventIdAndProductItemPriceId(d.eventId, d.id).then(function (data) {
                    let pi = data.EventWinePriceItemOut[0];
                    return (0, _purchaseUtils.convertEventWinePriceItemToProductLine)(pi, d, productLine);
                });
                promesses.push(vintagePromise);
            } else {
                let vintagePromise = productAdapter.findProductItemPriceByWineryIdAndProductItemPriceId(d.wineryId, d.id).then(function (data) {
                    let pi = data.WineryWinePriceItemOut[0];
                    return (0, _purchaseUtils.convertEventWinePriceItemToProductLine)(pi, d, productLine);
                    //return convertWineryWinePriceItemToProductLine (pi, d, productLine);
                });
                promesses.push(vintagePromise);
            }
        });

        return Ember.RSVP.hash(promesses).then(function (data) {
            model.productLines = [];
            _lodash.default.forEach(data, d => {
                model.productLines.push(d);
            });
            model.wineryProductLines = (0, _purchaseUtils.convertProductLinesToWineryProductLines)(model.productLines, that);
            return model;
        });
    }
});