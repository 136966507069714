define('awesome2/authenticators/captcha2', ['exports', 'ember-simple-auth/authenticators/base', 'awesome2/config/environment', 'ember-local-storage'], function (exports, _base, _environment, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { host, namespace } = _environment.default.DS;
  const baseUrl = host + "/" + namespace;

  // captchadummy -> points to dummy
  // captchadummy2 -> points to dummy with login field as input
  const captchaEndpoint = `${baseUrl}/data/jwt/captcha`;

  const { RSVP: { Promise }, $: { ajax }, run } = Ember;

  exports.default = _base.default.extend({
    tokenEndpoint: captchaEndpoint, //OK
    tokenJwt: (0, _emberLocalStorage.storageFor)("tokenJwt"),
    restore(data) {
      return new Promise((resolve, reject) => {
        if (!Ember.isEmpty(data.token)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },
    authenticate(data) {
      const requestOptions = {
        url: this.tokenEndpoint,
        type: 'POST',
        data,
        mode: 'cors'
      };
      //https://github.com/simplabs/ember-simple-auth/issues/1618
      return new Promise((resolve, reject) => {
        ajax(requestOptions).then(response => {
          const jwt = response;
          run(() => {
            resolve({
              token: jwt
              //userInfo : jwt,//TODO Add userInfo
            });
          });
        }, error => {
          run(() => {
            reject(error);
          });
        });
      });
    },
    invalidate(data) {
      return Promise.resolve(data);
    }
  });
});