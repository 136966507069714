define('awesome2/components/card/wine/wine-card-list', ['exports', 'awesome2/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        environment: _environment.default.ENVIRONMENT,
        actions: {
            setWineCardProductFilter(filter) {
                this.sendAction("forwardFromWineCardList", filter);
            }
        }
    });
});