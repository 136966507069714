define('awesome2/controllers/description', ['exports', 'awesome2/adapters/post-entity', 'awesome2/models/description', 'awesome2/controllers/base', 'awesome2/utils/notification-utils'], function (exports, _postEntity, _description, _base, _notificationUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({

    /*TODO use ember validation */
    isValidEmail: Ember.computed.match('model.email', /^.+@.+\..+$/),
    isValidDescription: Ember.computed.gte('model.description.length', 20),

    isSent: false,
    isDisabledOnAttribute: Ember.computed('isValidDescription', 'isValidEmail', function () {
      return !(this.get('isValidDescription') && this.get('isValidEmail'));
    }),
    isDisabledOnSend: Ember.computed('isSent', function () {
      return this.get('isSent');
    }),
    isDisabled: Ember.computed('isDisabledOnAttribute', 'isDisabledOnSend', function () {
      return this.get('isDisabledOnAttribute') || this.get('isDisabledOnSend');
    }),
    actions: {
      selectLanguage(language) {
        this.set("selectedLanguage", language);
        this.set("model.languageCode", language.code);
      },
      sendDescription(description) {
        this.set('isSent', true);
        if (this.get("origin")) {
          this.set("model.origin", this.get("origin"));
        }
        var adapter = _postEntity.default.create();
        adapter.sendDescription(this.get("model")).then(data => {
          (0, _notificationUtils.notifySuccess)(this, "notification.description-submitted");
          //reset model
          //DOES NOT work 2nd time this.set("model", {});
          //DOES NOT work at all this.set("model", Feedback.create());
          this.set('isSent', false);
          this.set("model.email", "");
          this.set("model.languageCode", "");
          this.set("model.description", "");
        }).catch(data => {
          (0, _notificationUtils.notifyFailure)(this, "notification.error");
        });
        //newFeedback.save().then(() => this.transitionTo('payplans'));
      }
    }
  });
});