define("awesome2/components/snippet/pager-container-filter-snippet", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        actions: {
            addProductFilter(event) {
                this.sendAction("forwardActionFilter", event);
            }
        }
    });
});