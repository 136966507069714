define('awesome2/controllers/accounts/identification', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        identification: null,
        user: (0, _emberLocalStorage.storageFor)("user"),
        cart: (0, _emberLocalStorage.storageFor)("cart"),
        session: Ember.inject.service(),
        actions: {
            authenticate: function () {
                let userLogin = this.get("user");
                let identification = this.get("identification");
                userLogin.set("login", identification);
                var credentials = this.getProperties('identification'),
                    authenticator = 'authenticator:dummy'; //dummy
                this.get('session').authenticate(authenticator, credentials).catch(reason => {
                    this.set('errorMessage', reason.error || reason);
                });
            }
        }
    });
});