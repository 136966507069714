define('awesome2/components/card/region/region-card-abstract', ['exports', 'awesome2/utils/winery-utils'], function (exports, _wineryUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        autonomous: null,
        region: null,
        regionWebPath: null,

        regionService: Ember.inject.service("region"),
        didInsertElement() {
            this._super(...arguments);
            if (this.get('autonomous') && this.get('regionWebPath')) {
                this.get('regionService').find(this.get("regionWebPath")).then(data => {
                    //TODO replace by webpath
                    let region = data.RegionBadgeOut[0];
                    (0, _wineryUtils.prepareRegion)(region);
                    this.set('region', region);
                });
            }
        }
    });
});