define("awesome2/locales/fr/translations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "nav": {
      "grapes": "Cépages",
      "wineries": "Vignobles",
      "prices": "Prix",
      "search": "Chercher",
      "countries": "Pays",
      "appellations": "Appelations"
    },

    "title": {
      "main": "Main"
    },

    "field": {
      "details": "Détails",
      "color": "Couleur",
      "is-bio": "Is bio",
      "number-of-millesimes": "Nombre de millésimes",
      "years": "Années",
      "best-moment-to-consume": "Best moment to consume",
      "years-after-harvesting": "année(s) après les vendanges",
      "min": "Minimum",
      "max": "Maximum",
      "conservation": "Conservation",
      "harvesting-method": "Méthode de vendange",
      "alcohol": "Alcool",
      "tags": "Etiquettes",

      "registration": "Inscription",
      "first-name": "Prénom",
      "last-name": "Nom",
      "email": "Email",
      "domain": "Domaine",
      "country": "Pays",
      "region": "Région",
      "lieuDit": "Lieu dit",
      "grapes": "Cépages",
      "language": "Language",
      "description": "Description",
      "language-for-description": "Langue utilisée pour la description",

      "wine-properties": "Caractéristiques",

      "appellations": "Appelations"

    },

    "action": {
      "request-domain-creation": "Demandez la création de votre domaine"
    },

    "notification": {
      "unexpected-error-processing-request": "Une erreur est survenue",
      "domain-successfully-requested": "La demande du domaine a été envoyée",
      "feedback-submitted": "Votre feedback a été envoyé"
    }

    // "some.translation.key": "Text for some.translation.key",
    //
    // "a": {
    //   "nested": {
    //     "key": "Text for a.nested.key"
    //   }
    // },
    //
    // "key.with.interpolation": "Text with {{anInterpolation}}"
  };
});