define('awesome2/instance-initializers/storage', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(appInstance /**/) {
    // appInstance.inject('route', 'foo', 'service:foo');
    let countryService = appInstance.lookup('service:country');
    let grapeService = appInstance.lookup('service:grape');
    let colorService = appInstance.lookup('service:color');
    countryService.store();
    grapeService.store();
    colorService.store();
  }

  exports.default = {
    name: 'storage',
    initialize
  };
});