define('awesome2/components/swiper-slide', ['exports', 'ember-cli-swiper/components/swiper-slide'], function (exports, _swiperSlide) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _swiperSlide.default;
    }
  });
});