define('awesome2/services/order', ['exports', 'awesome2/models/customer-purchase', 'awesome2/adapters/vintage', 'awesome2/adapters/product', 'ember-local-storage', 'lodash', 'awesome2/utils/purchase-utils'], function (exports, _customerPurchase, _vintage, _product, _emberLocalStorage, _lodash, _purchaseUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        checkout(that) {
            return getInfo(this);
        },
        confirm() {
            return getInfo(this);
        }

    });


    function getInfo(that) {
        let model = that.store.createRecord("customer-purchase");
        model.set("customerEmail", that.get("user.login"));

        let winery = that.store.createRecord("winery");

        winery.set("name", "Sample winery");
        winery.set("contact", "Contact");
        model.set("winery", winery);

        let pl = that.store.createRecord("product-line");

        //https://discuss.emberjs.com/t/how-to-make-this-code-a-promise/13210/4
        let promesses = [];
        let items = that.get("cart.content");
        let vintageAdapter = _vintage.default.create();
        let productAdapter = _product.default.create();
        _lodash.default.forEach(items, d => {
            let productLine = that.store.createRecord("product-line");
            if (d.eventId) {
                let vintagePromise = productAdapter.findProductItemPriceByEventIdAndProductItemPriceId(d.eventId, d.id).then(function (data) {
                    let pi = data.EventWinePriceItemOut[0];
                    return (0, _purchaseUtils.convertEventWinePriceItemToProductLine)(pi, d, productLine);
                });
                promesses.push(vintagePromise);
            } else {
                let vintagePromise = productAdapter.findProductItemPriceByWineryIdAndProductItemPriceId(d.wineryId, d.id).then(function (data) {
                    let pi = data.WineryWinePriceItemOut[0];
                    return (0, _purchaseUtils.convertEventWinePriceItemToProductLine)(pi, d, productLine);
                    //return convertWineryWinePriceItemToProductLine (pi, d, productLine);
                });
                promesses.push(vintagePromise);
            }
        });

        return Ember.RSVP.hash(promesses).then(function (data) {
            model.productLines = [];
            _lodash.default.forEach(data, d => {
                model.productLines.push(d);
            });
            model.wineryProductLines = (0, _purchaseUtils.convertProductLinesToWineryProductLines)(model.productLines);
            return model;
        });
    }
});