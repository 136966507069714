define('awesome2/components/language/menu-item', ['exports', 'awesome2/utils/common-utils'], function (exports, _commonUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        i18n: Ember.inject.service(),
        translationsFetcher: Ember.inject.service(),
        actions: {
            changeLanguage(event) {
                Ember.$(event.target).closest("li").siblings().removeClass('active');
                Ember.$(event.target).closest("li").addClass('active');

                var value = event.target.parentElement.getAttribute("data-lang");
                /*
                if (value!="en" && value!="fr") {
                    value = "en";//as default fall back for untranslated items
                }
                */
                (0, _commonUtils.setUserLanguage)(value);
                this.get('translationsFetcher').fetch();

                // debugger
                // let dom = getDOM(this);
                // let ele = findElementById(dom, 'change-language');
                // ele.removeClass("show")
                // //$(".navbar-toggle").click();

                Ember.$("#change-language").removeClass("show");
            }
        }
    });
});