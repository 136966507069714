define('awesome2/components/snippet/li-snippet', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'li',
        activate: false,
        classNameBindings: ['active'],
        active: Ember.computed('activate', function () {
            return this.get('activate');
        })
        /*
        active: function() {
            return this.get('childViews').anyBy('active');
        }.property('childViews.@each.active')
        */
    });
});