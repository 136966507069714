define('awesome2/routes/blog/index', ['exports', 'awesome2/routes/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        titleToken: ['nav.blog'],
        newsletterService: Ember.inject.service("newsletter"),
        model(params) {
            return this.get('newsletterService').blogList(params);
        }
    });
});