define('awesome2/controllers/purchase/checkout', ['exports', 'awesome2/utils/notification-utils', 'ember-local-storage', 'awesome2/utils/common-utils', 'awesome2/adapters/purchase', 'lodash'], function (exports, _notificationUtils, _emberLocalStorage, _commonUtils, _purchase, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        cart: (0, _emberLocalStorage.storageFor)("cart"),
        user: (0, _emberLocalStorage.storageFor)("user"),
        actions: {
            cancelOrderController(id) {
                this.transitionToRoute('/purchase');
            },
            orderController(id) {
                let cart = this.get("cart");
                let adapter = _purchase.default.create();
                let userStorage = this.get("user");
                let email = "";
                let gender = "";
                let firstName = "";
                let lastName = "";
                if (userStorage && userStorage.get("login")) {
                    email = userStorage.get("login");
                    gender = userStorage.get("gender");
                    firstName = userStorage.get("firstName");
                    lastName = userStorage.get("lastName");
                } else {
                    //TODO redirect to login
                    this.transitionToRoute("/login"); //TODO inverse logic : if not logged the login and after simple-auth redirects perform purchase-order
                }
                let userLanguage = (0, _commonUtils.getUserLanguage)();
                let poKey = getPoKeyFromCart(cart);
                adapter.confirmPurchaseOrder(email, gender, firstName, lastName, userLanguage, getPoKeyFromCart(cart), cartAsJson(cart)).then(data => {
                    (0, _notificationUtils.notifySuccess)(this, "notification.purchase-order-submitted");
                    //empty cart
                    //unfortunately cannot clear cart because transitionToRoute does not pass the value of poKey, so keep cart
                    //this.get("cart").clear();
                    let queryParams = { queryParams: { poKey: poKey } };
                    this.transitionToRoute("/purchase/overview", queryParams);
                });
            }

        }
    });


    function cartAsJson(cart) {
        return JSON.stringify(cart.content);
    }

    function getPoKeyFromCart(cart) {
        return cart.poKey;
    }
});