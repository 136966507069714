define('awesome2/components/card/grade/grade-card-abstract-item', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        autonomous: null,
        grade: null,
        gradeWebPath: null,

        gradeService: Ember.inject.service("grade"),
        didInsertElement() {
            this._super(...arguments);
            if (this.get('autonomous') && this.get('gradeWebPath')) {
                this.get('gradeService').findByWebPath(this.get("gradeWebPath")).then(data => {
                    //TODO replace by webpath
                    this.set('data', data.CepageDescriptionOut[0]);
                });
            }
        }

    });
});