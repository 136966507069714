define('awesome2/routes/countries/country', ['exports', 'awesome2/routes/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({

        countryService: Ember.inject.service("country"),
        model(params) {
            return this.get('countryService').findOverview(params.webpath);
        },
        afterModel(model, transition) {
            this.set("titleToken", ['field.country', model.country.name]);
        }
    });
});