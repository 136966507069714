define('awesome2/routes/appellations/appellation', ['exports', 'awesome2/routes/base', 'awesome2/utils/common-utils'], function (exports, _base, _commonUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        appellationService: Ember.inject.service("appellation"),
        titleToken: ['field.appellation'],

        model(params) {
            //TODO retrieve user language
            let userLanguage = (0, _commonUtils.getUserI18n)();
            return this.get('appellationService').findOverview(params.webpath, userLanguage);
        },
        afterModel(model, transition) {
            this.set("titleToken", ['field.appellation', model.appellation.name]);
        }
    });
});