define('awesome2/components/snippet/modal/wp-modal/body', ['exports', 'awesome2/templates/components/snippet/modal/wp-modal/body'], function (exports, _body) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _body.default,
    classNames: ['modal-body']
  });
});