define('awesome2/components/snippet/newsletter-snippet', ['exports', 'awesome2/adapters/newsletter', 'awesome2/utils/notification-utils'], function (exports, _newsletter, _notificationUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        email: null,
        isSent: false,

        isValidEmail: Ember.computed.match('email', /^.+@.+\..+$/),
        /*isDisabled: Ember.computed('isValidEmail',
            function() {
            return !(this.get('isValidEmail') 
            )
        }),
        */
        isDisabledOnSend: Ember.computed('isSent', function () {
            return this.get('isSent');
        }),
        isDisabled: Ember.computed('isValidEmail', 'isDisabledOnSend', function () {
            return !this.get('isValidEmail') || this.get('isDisabledOnSend');
        }),

        actions: {
            request(request) {
                this.set('isSent', true);
                var adapter = _newsletter.default.create();
                adapter.requestNewsletter(this.get("email")).then(data => {
                    this.set('isSent', false);
                    this.set("email", "");
                    (0, _notificationUtils.notifySuccess)(this, "notification.newsletter-successfully-requested");
                }).catch(x => {
                    this.set('isSent', false);
                    (0, _notificationUtils.notifyFailure)(this, "notification.unexpected-error-processing-request");
                    console.log("error " + x.responseJSON.cause);
                });
            }
        }
    });
});