define("awesome2/helpers/color-to-rgb", ["exports", "awesome2/utils/common-utils"], function (exports, _commonUtils) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.colorToRgb = colorToRgb;
  function colorToRgb(params /*, hash*/) {
    let [color] = params;
    return (0, _commonUtils.getColor)(color);
  }

  exports.default = Ember.Helper.helper(colorToRgb);
});