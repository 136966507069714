define('awesome2/controllers/test', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        showMyModal: false,
        actions: {
            toggleShow() {
                this.set('showMyModal', !this.get('showMyModal'));
            }
        }
    });
});