define('awesome2/routes/payplans', ['exports', 'awesome2/adapters/payplans'], function (exports, _payplans) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        payplanModal: false,
        model() {
            var adapter = _payplans.default.create();
            return adapter.findAll().then(function (data) {
                return data.PayPlanConditionMatrixOut;
            }).catch(function (error) {
                console.log("error = " + error);
                //reject(error);
            });
        },
        actions: {
            /*
            Modal action do not goes there!!! but in controller
            request() {
                this.set('payplanModal', !this.get('payplanModal'));
            }
            */
        }
    });
});