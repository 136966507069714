define('awesome2/adapters/appellation', ['exports', 'ember-data', 'awesome2/utils/business-utils', 'awesome2/utils/common-utils', 'awesome2/adapters/base', 'awesome2/config/environment'], function (exports, _emberData, _businessUtils, _commonUtils, _base, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const { host, namespace } = _environment.default.DS;
    const baseUrl = host + "/" + namespace;

    exports.default = _base.default.extend({

        //abstract autonomous + overview
        find: function (webpath, language) {
            let filters = { webpath: webpath, language: language };
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/AppelationBadgeIn' + (0, _businessUtils.formatFilter)(filters));
        },
        // abstract all + filter
        findAll: function (filters) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/AppelationBadgeIn' + (0, _businessUtils.formatFilter)(filters)); //TODO format filters
        },
        // abstract child (= all + custom filter by parent web path)
        findByRegionWebPath: function (regionWebPath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/AppelationBadgeIn?regionWebPath=' + regionWebPath); //TODO format filters
        },
        findByCountryWebPath: function (countryWebPath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/AppelationBadgeIn?countryWebPath=' + countryWebPath); //TODO format filters
        },
        findByGradeWebPath: function (gradeWebPath) {
            return findByFilter({ grade: gradeWebPath });
        },
        findByGrapeWebPath: function (grapeWebPath) {
            return findByFilter({ grape: grapeWebPath }); //TODO replace grade by grape in both place
        },
        findByPlaceWebPath: function (placeWebpath) {
            return findByFilter({ place: placeWebpath });
        },
        findConservationMethodByAppellationWebPath: function (appellationWebpath) {
            return findConservationMethodByAppellationWebPath({ appellationWebPath: appellationWebpath });
        }
    });


    function findByFilter(filters) {
        return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/AppelationBadgeIn' + (0, _businessUtils.formatFilter)(filters)); //TODO format filters
    }

    function findConservationMethodByAppellationWebPath(filters) {
        return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/AppelationConservationBadgeIn' + (0, _businessUtils.formatFilter)(filters)); //TODO format filters
    }
});