define('awesome2/controllers/events/event', ['exports', 'awesome2/utils/common-utils'], function (exports, _commonUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        // coming from /route/events/event
        queryParams: ['wineryCountry', 'wineryRegion', 'wineryCepage', 'wineryBio', 'country', 'region', 'year', 'wineryLabel', 'productLabel', 'bio', 'appellation', 'grape', 'sweetness', 'bubbleness', 'color', 'caracteristic', 'orderByPrice', 'orderByWineryPrice', 'orderByPreOrderPrice', 'orderByYear', 'orderByCountry', 'orderByRegion', 'orderByWinery'],
        wineryCountry: null,
        wineryRegion: null,
        wineryCepage: null,
        wineryBio: null,
        country: null,
        region: null,
        year: null,
        wineryLabel: null,
        productLabel: null,
        bio: null,
        appellation: null,
        grape: null,
        sweetness: null,
        bubbleness: null,
        color: null,
        caracteristic: null,
        orderByPrice: null,
        orderByWineryPrice: null,
        orderByPreOrderPrice: null,
        orderByYear: null,
        orderByCountry: null,
        orderByRegion: null,
        orderByWinery: null,
        orderByPriceVariation: null,
        orderByPreOrderPriceVariation: null,

        actions: {
            setEventWineFilter(filter) {
                // TODO add grape to the already present ones, (if all selected remove)
                if (filter) {
                    (0, _commonUtils.setFilter)(this, filter, "wineryCountry");
                    (0, _commonUtils.setFilter)(this, filter, "wineryRegion");
                    (0, _commonUtils.setFilter)(this, filter, "wineryCepage");
                    (0, _commonUtils.setFilter)(this, filter, "wineryBio");

                    (0, _commonUtils.setFilter)(this, filter, "country");
                    (0, _commonUtils.setFilter)(this, filter, "region");
                    (0, _commonUtils.setFilter)(this, filter, "year");
                    (0, _commonUtils.setFilter)(this, filter, "wineryLabel");
                    (0, _commonUtils.setFilter)(this, filter, "productLabel");
                    (0, _commonUtils.setFilter)(this, filter, "bio");
                    (0, _commonUtils.setFilter)(this, filter, "appellation");
                    (0, _commonUtils.setFilter)(this, filter, "grape");
                    (0, _commonUtils.setFilter)(this, filter, "bubbleness");
                    (0, _commonUtils.setFilter)(this, filter, "sweetness");
                    (0, _commonUtils.setFilter)(this, filter, "color");
                    (0, _commonUtils.setFilter)(this, filter, "caracteristic");
                    (0, _commonUtils.setFilter)(this, filter, "orderByPrice");

                    //reset sort filters
                    resetFilter(this);
                    (0, _commonUtils.setFilter)(this, filter, "orderByYear");
                    (0, _commonUtils.setFilter)(this, filter, "orderByCountry");
                    (0, _commonUtils.setFilter)(this, filter, "orderByRegion");
                    (0, _commonUtils.setFilter)(this, filter, "orderByWinery");
                    (0, _commonUtils.setFilter)(this, filter, "orderByPrice");
                    (0, _commonUtils.setFilter)(this, filter, "orderByWineryPrice");
                    (0, _commonUtils.setFilter)(this, filter, "orderByPreOrderPrice");
                    (0, _commonUtils.setFilter)(this, filter, "orderByPriceVariation");
                    (0, _commonUtils.setFilter)(this, filter, "orderByPreOrderPriceVariation");
                }
            }
        }
    });


    function resetFilter(that) {
        that.set("orderByYear", null);
        that.set("orderByCountry", null);
        that.set("orderByRegion", null);
        that.set("orderByWinery", null);
        that.set("orderByPrice", null);
        that.set("orderByWineryPrice", null);
        that.set("orderByPreOrderPrice", null);
        that.set("orderByPriceVariation", null);
        that.set("orderByPreOrderPriceVariation", null);
    }
});