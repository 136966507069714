define('awesome2/routes/countries/index', ['exports', 'awesome2/routes/base', 'awesome2/utils/business-utils'], function (exports, _base, _businessUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({

        titleToken: ['nav.countries'],
        countryService: Ember.inject.service("country"),
        queryParams: {
            grape: {
                refreshModel: true,
                replace: true
            },
            searchFreeText: {
                replace: true
            }
        },

        allCountries: null,

        model(params) {
            return this.get('countryService').findAllWithInfo(params).then(function (data) {
                (0, _businessUtils.toDisplay)(data.CountryDescriptionWithInfoOut, params.searchFreeText);
                (0, _businessUtils.setDisplayCount)(data, data.CountryDescriptionWithInfoOut);
                return data;
            }).catch(function (error) {
                console.log("error = " + error);
            });
        }

    });
});