define('awesome2/components/layout/card/layout-card-abstract', ['exports', 'awesome2/mixins/component-base-mixin', 'ember-in-viewport'], function (exports, _componentBaseMixin, _emberInViewport) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_componentBaseMixin.default, _emberInViewport.default, {

        inViewport: false,
        isInViewport: Ember.computed('inViewport', function () {
            return this.get('inViewport');
        }),
        didEnterViewport() {
            this.set("inViewport", true);
        }
    } /*
      didInsertElement() {
          this.set("inViewport", true);
          console.log("didInsertElement ");
      },
      */

    );
});