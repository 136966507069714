define('awesome2/components/card/event/event-card-abstract', ['exports', 'awesome2/utils/business-utils', 'awesome2/config/environment'], function (exports, _businessUtils, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        autonomous: null,
        event: null,
        eventWebPath: null,
        environment: _environment.default.ENVIRONMENT,

        eventService: Ember.inject.service("event"),

        didInsertElement() {
            this._super(...arguments);
            if (this.get('autonomous') && this.get('eventWebPath')) {
                this.get('eventService').findByWebpath(this.get("eventWebPath")).then(data => {
                    this.set('data', (0, _businessUtils.prepareEvent)(data.EventInfoOut[0]));
                });
            }
        }

    });
});