define('awesome2/components/card/product/product-card-item', ['exports', 'lodash', 'awesome2/mixins/component-base-mixin', 'ember-local-storage', 'awesome2/utils/purchase-utils'], function (exports, _lodash, _componentBaseMixin, _emberLocalStorage, _purchaseUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_componentBaseMixin.default, {
        //TODO remove duplicate with vintage-card-item
        tagName: '',
        product: null,
        context: null,
        perspective: null,
        cart: (0, _emberLocalStorage.storageFor)("cart"),
        wineryEventCustomerOrder: (0, _emberLocalStorage.storageFor)("customer-order"),

        customerQuantity: null,
        wineryChosenPrice: null,

        customerRow: Ember.computed('wineryEventCustomerOrder.[]', 'product', function () {
            let co = this.get("wineryEventCustomerOrder.content");
            let product = this.get("product");
            return getCustomerOrder(co, product);
        }),

        customerPriceDisplay: Ember.computed('customerQuantity', 'wineryChosenPrice', function () {
            let quantity = this.get("customerQuantity");
            let eventPrice = this.get("wineryChosenPrice");
            return (quantity * eventPrice).toFixed(2);
        }),

        priceChange: Ember.observer('customerQuantity', 'wineryChosenPrice', function () {
            let quantity = this.get("customerQuantity");
            let eventPrice = this.get("wineryChosenPrice");
            let product = this.get("product");
            let co = this.get("wineryEventCustomerOrder");
            createOrUpdateCustomerOrder(co, quantity, eventPrice, product);
        }),

        displayPrice: Ember.computed('product', 'perspective', 'context', function () {
            //TODO unit tests !!!
            let perspective = this.get("perspective"); //event, event-winery, winery (for display except price ...normally :))
            let context = this.get("context"); //home/winery, presale, event (for price)
            let product = this.get("product");
            if (context === "presale") {
                return product.isPresale ? product.eventPreOrderPrice : product.eventPrice;
            }
            if (perspective === "event" || perspective === "event-winery") {
                return product.isPresale ? product.eventPreOrderPrice : product.eventPrice;
            } else {
                return product.homePrice;
            }
        }),

        numberInShoppingCart: Ember.computed('cart.[]', function () {
            //TODO user idComposite
            let cartContent = this.get("cart.content");
            let perspective = this.get("perspective");
            let productItemPriceId = this.get("product.productItemPriceId"); //TODO change by productItemPriceId
            let eventId = this.get("product.eventId");
            let priceType = perspective == "event" || perspective == "event-winery" ? "event-presale" : "winery";
            if (eventId) {
                //TODO use product.nbOfDayPresaleBeforeEvent to determine if it is "event" or "event-presale"
                priceType = "event-presale";
            }
            let idComposite = (0, _purchaseUtils.getCompositeId)(productItemPriceId, priceType, eventId);

            let itemQuantity = _lodash.default.filter(cartContent, { 'idComposite': idComposite });
            if (itemQuantity.length == 0) {
                return 0;
            } else {
                return itemQuantity[0] | itemQuantity[0].quantity;
            }
        }),

        isInShoppingCart: Ember.computed('numberInShoppingCart', function () {
            let numberInShoppingCart = this.get("numberInShoppingCart");
            return numberInShoppingCart > 0;
        }),

        actions: {
            //TODO MODAL-WITH-OBJECT to refactor !! here add event id
            setProductItemPriceId(productItemId, productId, wineryId, productWebPath, wineryWebPath, priceType, eventId, displayPrice, currencyName) {
                this.sendAction("forwardToModal", productItemId, productId, wineryId, productWebPath, wineryWebPath, priceType, eventId, displayPrice, currencyName);
            }
        },
        didInsertElement() {
            this._super(...arguments);
            //init wineryChosenPrice and customerQuantity
            let co = this.get("wineryEventCustomerOrder.content");
            let product = this.get("product");
            let col = getCustomerOrder(co, product);

            this.set("customerQuantity", col.quantity);
            this.set("wineryChosenPrice", col.wineryChosenPrice);
        }
    });


    function createOrUpdateCustomerOrder(co, quantity, eventPrice, product) {
        let total = (quantity * eventPrice).toFixed(2);
        let id = product.productItemPriceId;
        let objId = co.findBy('id', id);
        co.removeObject(objId);

        let obj = {
            id: id,
            product: product,
            wineryChosenPrice: eventPrice,
            quantity: quantity,
            total: total
        };
        co.addObject(obj);
    }

    function getCustomerOrder(co, product) {
        let id = product.productItemPriceId;
        let objId = co.findBy('id', id);
        if (objId == undefined) {
            let id = product.productItemPriceId;
            return {
                id: id,
                product: product,
                wineryChosenPrice: product.eventPrice,
                quantity: 0,
                total: 0
            };
        }
        return objId;
    }
});