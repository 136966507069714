define('awesome2/adapters/developer', ['exports', 'awesome2/utils/common-utils', 'awesome2/adapters/base', 'awesome2/config/environment'], function (exports, _commonUtils, _base, _environment) {
   'use strict';

   Object.defineProperty(exports, "__esModule", {
      value: true
   });

   const { host, namespace } = _environment.default.DS;
   const baseUrl = host + "/" + namespace;

   exports.default = _base.default.extend({
      findAll: function () {
         return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/SystemInfoIn');
      }
   });
});