define('awesome2/storages/countries', ['exports', 'ember-local-storage/local/object', 'awesome2/adapters/country'], function (exports, _object, _country) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    // app/storages/countries.js
    const Storage = _object.default.extend();

    //TODO check if service is injected in Storage

    // Uncomment if you would like to set initialState
    Storage.reopenClass({

        initialState() {

            var adapter = _country.default.create();
            var countryPromise = adapter.findAll().then(data => {
                return data.CountryDescriptionOut;
            });
            var promises = {
                country: countryPromise
            };

            return Ember.RSVP.hash(promises).then(function (data) {
                return data.country;
            });
        }
    });

    exports.default = Storage;
});