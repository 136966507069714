define('awesome2/models/login', ['exports', 'ember-data', 'ember-model'], function (exports, _emberData, _emberModel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    identifier: (0, _emberModel.attr)('string'),
    password: (0, _emberModel.attr)('string')
  });
});