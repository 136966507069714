define('awesome2/adapters/country', ['exports', 'ember-data', 'fetch', 'awesome2/utils/common-utils', 'awesome2/adapters/base', 'awesome2/config/environment', 'npm:append-query'], function (exports, _emberData, _fetch, _commonUtils, _base, _environment, _npmAppendQuery) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const { host, namespace } = _environment.default.DS;
    const baseUrl = host + "/" + namespace;

    exports.default = _base.default.extend({

        availableCountries: function () {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/CountryListIn');
        },

        findAll: function () {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/CountryDescriptionIn');
        },

        find: function (country_key) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/CountryDescriptionIn?webPath=' + country_key);
        },

        findWithInfo: function (entityId, entityWebPath, displayOrder, filters) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/CountryDescriptionWithInfoIn' + filter(entityId, entityWebPath, displayOrder, filters));
        },

        findStateByCountryWebPath: function (countryWebPath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/StateWithInfoIn' + filter('', '', '', { countryWebPath: countryWebPath }));
        }
    });


    function filter(entityId, entityWebPath, displayOrder, filters) {
        if (filters) {
            return (0, _npmAppendQuery.default)("", {
                "entityId": entityId,
                "webPath": entityWebPath,
                "displayOrder": displayOrder,
                "cepageWebPath": filters.grape,
                "countryWebPath": filter.countryWebPath
            }, { removeNull: true });
        } else {
            return (0, _npmAppendQuery.default)("", {
                "entityId": entityId,
                "webPath": entityWebPath,
                "displayOrder": displayOrder
            }, { removeNull: true });
        }
    }

    /*
    function updateQueryStringParameter(uri, key, value) {
        uri = uri?uri:'';
        if (value) {
            var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
            var separator = uri.indexOf('?') !== -1 ? "&" : "?";
            if (uri.match(re)) {
                return uri.replace(re, '$1' + key + "=" + value + '$2');
            }
            else {
                return uri + separator + key + "=" + value;
            }
        }
      }
    */
});