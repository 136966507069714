define('awesome2/routes/accounts/login', ['exports', 'awesome2/routes/base', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _base, _unauthenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend(_unauthenticatedRouteMixin.default, {
        queryParams: {
            // criteria for winery
            redirectPath: {
                refreshModel: true,
                replace: false
            }
        },
        routeHistory: Ember.inject.service(),

        model(params) {
            const currentRouteName = this.get('routeHistory.current');
            return { currentRouteName: currentRouteName, redirectPath: params.redirectPath };
        }
    });
});