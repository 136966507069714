define('awesome2/components/snippet/badge-snippet', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        color: '',
        textColor: null,
        colorClass: '',
        //TODO refactor with ember 3.4 fonctionality
        myColor: Ember.computed('color', 'textColor', function () {
            let color = this.get('color');
            let textColor = this.get('textColor');
            if (textColor) {
                //return Ember.String.htmlSafe("background-color : #"+this.get('color') + "; color : #"+this.get('textColor'));
                return Ember.String.htmlSafe(`background-color : #${color}; color : #${textColor}`);
            }
            return Ember.String.htmlSafe(`background-color : #${color}`);
        })

    });
});