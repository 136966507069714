define('awesome2/routes/purchase/index', ['exports', 'awesome2/models/customer-purchase', 'awesome2/adapters/vintage', 'awesome2/adapters/product', 'awesome2/adapters/user', 'awesome2/routes/base', 'ember-local-storage', 'lodash', 'awesome2/utils/purchase-utils', 'ember-route-history/mixins/routes/route-history'], function (exports, _customerPurchase, _vintage, _product, _user, _base, _emberLocalStorage, _lodash, _purchaseUtils, _routeHistory) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend(_routeHistory.default, {

        wineryService: Ember.inject.service("winery"),
        productService: Ember.inject.service("product"),
        orderService: Ember.inject.service("order"),
        userService: Ember.inject.service("user"),
        cart: (0, _emberLocalStorage.storageFor)("cart"),
        user: (0, _emberLocalStorage.storageFor)("user"),
        model(params) {
            return getInfo(this);
        },
        setupController(controller, models) {
            this._super(...arguments);
        },
        afterModel(model, transition) {
            this.set("titleToken", ['field.purchase-order']);
        }
    });


    function getInfo(that) {
        let promesses = [];
        let model = that.store.createRecord("customer-purchase");
        //userinfo from backend
        let userStorage = that.get("user");
        if (userStorage && userStorage.get("login")) {
            let userLogin = userStorage.get("login");
            let userPromise = that.get('userService').fetch(userLogin).then(data => {
                return data.UserInfoOut[0];
            });
            //promesses.push(userPromise); 
        }
        //https://discuss.emberjs.com/t/how-to-make-this-code-a-promise/13210/4

        let items = that.get("cart.content");
        let productAdapter = _product.default.create();
        _lodash.default.forEach(items, d => {
            let productLine = that.store.createRecord("product-line");
            if (d.eventId) {
                let vintagePromise = productAdapter.findProductItemPriceByEventIdAndProductItemPriceId(d.eventId, d.id).then(function (data) {
                    let pi = data.EventWinePriceItemOut[0];
                    return (0, _purchaseUtils.convertEventWinePriceItemToProductLine)(pi, d, productLine);
                });
                promesses.push(vintagePromise);
            } else {
                let vintagePromise = productAdapter.findProductItemPriceByWineryIdAndProductItemPriceId(d.wineryId, d.id).then(function (data) {
                    let pi = data.WineryWinePriceItemOut[0];
                    return (0, _purchaseUtils.convertEventWinePriceItemToProductLine)(pi, d, productLine);
                    //return convertWineryWinePriceItemToProductLine (pi, d, productLine);
                });
                promesses.push(vintagePromise);
            }
        });

        return Ember.RSVP.hash(promesses).then(function (data) {
            model.productLines = [];
            _lodash.default.forEach(data, d => {
                model.productLines.push(d);
            });
            model.wineryProductLines = (0, _purchaseUtils.convertProductLinesToWineryProductLines)(model.productLines, that);
            return model;
        });
    }
});