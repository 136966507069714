define('awesome2/routes/base', ['exports', 'awesome2/utils/common-utils', 'awesome2/mixins/reset-scroll-position', 'awesome2/config/environment'], function (exports, _commonUtils, _resetScrollPosition, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.setMetaDescription = setMetaDescription;
  exports.default = Ember.Route.extend(_resetScrollPosition.default, {

    translationsFetcher: Ember.inject.service(),
    i18n: Ember.inject.service(),
    titleToken: null,
    pageDescription: 'The wineries and wine events platform',
    authenticationRoute: "/accounts/login",
    //TODO remove env use environment
    env: _environment.default.environment,
    environment: _environment.default.environment,

    headTags: Ember.computed('pageDescription', 'headTagsBase', function () {
      let pageDescription = this.get("pageDescription");
      let headTags = this.get("headTagsBase");
      let descriptionNode = {
        type: 'meta',
        tagId: 'meta-description-tag',
        attrs: {
          name: 'description',
          content: pageDescription
        }
      };
      headTags.push(descriptionNode);
      return headTags;
    }),
    headTagsBase: [{
      type: 'meta',
      tagId: 'meta-og-name',
      attrs: {
        property: 'og:name',
        content: 'The winery project'
      }
    },
    /* TODO override like title
    {
      type: 'meta',
      tagId: 'meta-description-tag',
      attrs: {
        name: 'description',
        content: 'The wineries and wine events platform'
      }
    },
    */

    {
      type: 'link',
      tagId: 'canonical-link',
      attrs: {
        rel: 'canonical',
        content: 'https://wineryproject.com/'
      }
      // twitter

    }, {
      type: 'meta',
      tagId: 'twitter-creator',
      attrs: {
        name: 'twitter:creator',
        content: '@WineryProject'
      }
    }],

    beforeModel: function (params) {

      let i18nLocale = this.get('i18n.locale');
      let userLanguage = (0, _commonUtils.getUserLanguage)();

      if (i18nLocale != userLanguage) {
        //!! i18n and userLanguage are set the first time=> no load
        //TODO make distinct between first time and rest maybe in initialize
        //this.set('i18n.locale', userLanguage);
        //this.get('translationsFetcher').fetch();
      }
    },

    /*TODO add method to add 
    {
    type: 'meta',
    tagId: 'meta-description-tag',
    attrs: {
      name: 'description',
      content: 'TODO Change'
    }
    },
    */
    metaDescription: function setMetaDescription(description) {
      this.get("headTags");
    }

  });
  function setMetaDescription(description) {}
});