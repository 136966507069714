define('awesome2/routes/grapes/grape', ['exports', 'awesome2/routes/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        grapeService: Ember.inject.service("grape"),
        model(params) {
            return this.get('grapeService').findOverview(params.grape_key);
        },
        afterModel(model, transition) {
            this.set("titleToken", ['field.country', model.grape.name]);
        }
    });
});