define('awesome2/helpers/replace-with', ['exports', 'ember-route-helpers/helpers/replace-with'], function (exports, _replaceWith) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _replaceWith.default;
    }
  });
  Object.defineProperty(exports, 'replaceWith', {
    enumerable: true,
    get: function () {
      return _replaceWith.replaceWith;
    }
  });
});