define('awesome2/services/winery', ['exports', 'awesome2/adapters/product', 'awesome2/adapters/event', 'awesome2/adapters/winery', 'awesome2/utils/business-utils', 'awesome2/utils/product-utils', 'lodash'], function (exports, _product, _event, _winery, _businessUtils, _productUtils, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        infoService: Ember.inject.service("info"),
        wineryAbstract(entityId) {
            var adapter = _winery.default.create();
            return adapter.find(entityId);
        },
        findByWebpath(webPath) {
            var adapter = _winery.default.create();
            return adapter.findByWebpath(webPath);
        },
        findAll(filters) {
            var adapter = _winery.default.create();
            return adapter.findAll(filters);
        },
        wineryDetailsAndProducts(params) {
            //TODO merge with findByWineryWebPath(wineryWebPath, params) of product service

            var adapter = _winery.default.create();
            var wineryPromise = adapter.findByWebpath(params.webpath) //winery_id webpath
            .then(function (data) {
                return data.WineryPublicOut[0];
            });
            var wineryOpeningHours = adapter.findOpeningHoursByWebpath(params.webpath) //winery_id webpath
            .then(function (data) {
                return data.WineryTimetableInfoOut;
            });

            var wineryShippingCostPromise = adapter.findShippingCostByWineryWebpath(params.webpath) //winery_id webpath
            .then(function (data) {
                return data.WineryShippingCostOut;
            });

            var eventAdapter = _event.default.create();
            var eventPromise = eventAdapter.findByWineryWebpath(params.webpath).then(function (data) {
                return data.ParticipantsForEventOut;
            });

            var productAdapter = _product.default.create();
            var productPromise = productAdapter.findByWineryWebPath(params.webpath).then(function (data) {
                return data.WineryProductItemBadgeOut;
            });

            var imagesPromise = this.get('infoService').findImagesInfo("winery", params.webpath).then(data => {
                //TODO replace by webpath
                return data.ImageInfoOut;
            });

            var productItemPricePromise = productAdapter.findProductItemPriceByWineryWebPath(params.webpath).then(function (data) {
                return data.WineryWinePriceItemOut;
            });
            var eventPresalePromise = productAdapter.findProductItemPriceByWineryWebPathUnderEventPresale(params.webpath).then(function (data) {
                return (0, _productUtils.groupByEvent)(data.EventWinePriceItemOut);
            });

            //TODO
            /*
            Gmap load  wineries around
            on controller add listener on change
            on click other => move to other winery webpath +-
             gmap on region and lieu-dit
            plus delimitatiton
            */

            var promises = {
                winery: wineryPromise,
                products: productPromise,
                productItemPrices: productItemPricePromise,
                eventPresalePrices: eventPresalePromise,
                images: imagesPromise,
                openingHours: wineryOpeningHours,
                events: eventPromise,
                shippingCosts: wineryShippingCostPromise

            };

            return Ember.RSVP.hash(promises).then(function (data) {
                let products = data.products;
                let winery = data.winery;
                //let openingHours = data.openingHours;
                if (winery) {
                    //TODO additional reduce price range at winery level, cepage distinct at winery level, pictures as array
                    data.winery.priceMin = _lodash.default.min(_lodash.default.map(products, 'priceMin'));
                    data.winery.priceMax = _lodash.default.min(_lodash.default.map(products, 'priceMax'));
                    //TODO group in a function common code with index.js
                    (0, _businessUtils.prepareWinery)(data.winery);

                    (0, _productUtils.prepareProducts)(data, params);

                    (0, _businessUtils.prepareImagesInfo)(data.images);

                    (0, _productUtils.prepareWineryProducts)(data);
                    //prepareWineryProductsUnderEventPresale(data);
                    //prepareEvents(data.events);
                }
                return data;
            });
        }

    });
});