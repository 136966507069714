define('awesome2/routes/events/request', ['exports', 'awesome2/routes/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        myParams: [],
        setupController(controller, model) {
            // Call _super for default behavior
            this._super(controller, model);
            // Implement your custom setup after
            controller.set('myParams', this.get('myParams'));
        }
    });
});