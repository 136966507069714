define('awesome2/services/stats', ['exports', 'awesome2/adapters/stats', 'lodash'], function (exports, _stats, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        eventsPerYearStat(params) {
            var adapter = _stats.default.create();
            return adapter.eventsPerYearStat(params);
        },

        eventsPerWeekStat(params) {
            var adapter = _stats.default.create();
            return adapter.eventsPerWeekStat(params);
        },

        wineriesPerEventPerWeekStat(params) {
            var adapter = _stats.default.create();
            return adapter.wineriesPerEventPerWeekStat(params);
        },

        wineRegionsPerEventPerWeekStat(params) {
            var adapter = _stats.default.create();
            return adapter.wineRegionsPerEventPerWeekStat(params);
        },

        wineCountriesPerEventPerWeekStat(params) {
            var adapter = _stats.default.create();
            return adapter.wineCountriesPerEventPerWeekStat(params);
        },

        statsInfo(params) {
            var adapter = _stats.default.create();
            var eventsPerYearStatPromise = adapter.eventsPerYearStat({}).then(d => {
                return d.EventsPerYearStatOut;
            });
            if (params.grades) {
                params.gradeWebPaths = params.grades;
                params.linkGrades = true;
            }
            var eventsExhibitorsStatPromise = adapter.eventsExhibitorsStat(params).then(d => {
                return d.EventsExhibitorsStatOut;
            });
            var statFiltersPromise = adapter.statFilters(params).then(d => {
                return d.StatFiltersOut;
            });
            var promises = {
                EventsPerYearStatOut: eventsPerYearStatPromise,
                eventsExhibitorsStatOut: eventsExhibitorsStatPromise,
                statFiltersOut: statFiltersPromise
            };
            return Ember.RSVP.hash(promises);
        },

        statCepageFilters: function (params) {
            var adapter = _stats.default.create();
            return adapter.statCepageFilters(params);
        },

        eventsExhibitorsStat: function (params) {
            var adapter = _stats.default.create();
            return adapter.eventsExhibitorsStat(params);
        },

        statsPerYear: function (params) {
            //TODO check year present
            var adapter = _stats.default.create();
            var eventsPerWeekStatPromise = adapter.eventsPerWeekStat(params.webpath) //winery_id webpath
            .then(function (data) {
                return data.EventsPerWeekStatOut;
            });
            var wineriesPerEventPerWeekStatPromise = adapter.wineriesPerEventPerWeekStat(params.webpath) //winery_id webpath
            .then(function (data) {
                return data.WineriesPerEventPerWeekStatOut;
            });
            var wineRegionsPerEventPerWeekStatPromise = adapter.eventsPerWeekStat(params.webpath) //winery_id webpath
            .then(function (data) {
                return data.EventsPerWeekStatOut;
            });
            var eventsPerWeekStatPromise = adapter.eventsPerWeekStat(params.webpath) //winery_id webpath
            .then(function (data) {
                return data.EventsPerWeekStatOut;
            });
        }
    });
});