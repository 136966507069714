define('awesome2/models/search', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    filter: (0, _attr.default)('string'),
    freeText: (0, _attr.default)('string'),
    language: (0, _attr.default)('string'),
    longitude: (0, _attr.default)('long'),
    latitude: (0, _attr.default)('long')

  });
});