define('awesome2/services/event', ['exports', 'lodash', 'moment', 'awesome2/adapters/event', 'awesome2/adapters/winery', 'awesome2/adapters/product', 'awesome2/adapters/partner', 'awesome2/utils/business-utils', 'awesome2/utils/product-utils', 'ember-local-storage', 'awesome2/utils/winery-utils'], function (exports, _lodash, _moment, _event, _winery, _product, _partner, _businessUtils, _productUtils, _emberLocalStorage, _wineryUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        user: (0, _emberLocalStorage.storageFor)("user"),
        session: Ember.inject.service(),
        index(params) {
            var adapter = _event.default.create();
            var eventAllPromise = adapter.findAll(params).then(data => {
                _lodash.default.forEach(data.EventInfoOut, function (item) {
                    (0, _businessUtils.prepareEvent)(item);
                });
                (0, _businessUtils.toDisplayEvent)(data.EventInfoOut, params.searchFreeText);
                (0, _businessUtils.setDisplayEvents)(data, data.EventInfoOut);
                (0, _businessUtils.setDisplayCount)(data, data.EventInfoOut);
                return data;
            });
            var eventSummaryPromise = adapter.eventSummary(params).then(data => {
                return data.EventSummaryOut;
            });
            var promises = {
                events: eventAllPromise,
                eventSummary: eventSummaryPromise
            };
            return Ember.RSVP.hash(promises).then(data => {
                return data;
            });
        },
        findByWebpath(webPath) {
            var adapter = _event.default.create();
            return adapter.findByWebpath(webPath);
        },
        findWineryEventInfo(params) {
            //TODO check in the user info if it is a owner of the winerys
            //if user is connected hasWineryEventOwnership
            let eventWebPath = params.event_webpath;
            params.eventWebPath = eventWebPath;
            let wineryWebPath = params.winery_webpath;
            var adapter = _event.default.create();
            //by default no winery event ownership
            // var eventAccessPromise = new Promise(function(resolve) {
            //     resolve(false);
            // });

            var eventPromise = adapter.findByWebpath(eventWebPath).then(data => {
                return data.EventInfoOut[0];
            });
            var ewpPromise = adapter.findByEventAndWineryWebpath(eventWebPath, wineryWebPath).then(function (data) {
                return data.ParticipantsForEventOut;
            });
            var wineryAdapter = _winery.default.create();
            var wineryPromise = wineryAdapter.findByWebpath(wineryWebPath).then(function (data) {
                return data.WineryPublicOut[0];
            });
            //TODO adapt with product_item_price    
            var productAdapter = _product.default.create();
            var productPromise = productAdapter.findProductItemPriceByEventWebPathAndWineryWebPath(eventWebPath, wineryWebPath).then(function (data) {
                return data.EventWinePriceItemOut;
            });
            var promises = {
                event: eventPromise,
                wineryParticipation: ewpPromise,
                winery: wineryPromise,
                products: productPromise
            };
            if (this.get('session.isAuthenticated')) {
                let userStorage = this.get("user");
                let userLogin = "";
                if (userStorage && userStorage.get("login")) {
                    userLogin = userStorage.get("login");
                }
                var eventAccessPromise = adapter.hasWineryOwnership(wineryWebPath).then(data => {
                    if (data != undefined && data.HasWineryOwnershipOut != undefined) {
                        return data.HasWineryOwnershipOut[0].hasWineryOwnership;
                    } else {
                        return 0; //todo replace by false once MP generation is boolean
                    }
                });
                promises.hasWineryOwnership = eventAccessPromise;
            }
            return Ember.RSVP.hash(promises).then(data => {
                (0, _businessUtils.prepareEvent)(data.event);
                (0, _businessUtils.prepareWinery)(data.winery, params);
                (0, _productUtils.prepareEventProducts)(data, params);
                if (params.forOrder) {
                    prepareOrderInfo(data);
                }
                return data;
            });
        },
        eventDetailsFull(params) {
            let webPath = params.event_webpath;
            params.eventWebPath = webPath;
            var adapter = _event.default.create();
            var eventPromise = adapter.findByWebpath(webPath).then(data => {
                return data.EventInfoOut[0];
            });
            var wineryAdapter = _winery.default.create();
            var wineryPromise = wineryAdapter.findByEventWebPath(webPath).then(function (data) {
                return data.WineryPublicOut;
            });
            //TODO adapt with product_item_price    
            var productAdapter = _product.default.create();
            var productPromise = productAdapter.findProductItemPriceByEventWebPath(webPath).then(function (data) {
                return data.EventWinePriceItemOut;
            });
            var partnerAdapter = _partner.default.create();
            var partnerPromise = partnerAdapter.findByEventWebPath(webPath).then(function (data) {
                return data.PartnersForEventOut;
            });
            var promises = {
                event: eventPromise,
                wineries: wineryPromise,
                products: productPromise,
                partners: partnerPromise
            };
            return Ember.RSVP.hash(promises).then(data => {
                if (data.event) {
                    (0, _businessUtils.prepareEvent)(data.event);
                    (0, _businessUtils.filterAndPrepareWineries)(data, params);
                    (0, _productUtils.prepareEventProducts)(data, params);
                    data.cal = getEventCalendar(data.event);
                }
                return data;
            });
        }

    });


    function prepareOrderInfo(wineryEventInfo) {
        wineryEventInfo.customer = {
            name: null,
            email: null,
            areTermsAndConditionsAccepted: false
        };
        wineryEventInfo.bill = {
            reference: null

        };
        wineryEventInfo.seller = {
            vatNumber: wineryEventInfo.winery.contactVatNumber,
            email: wineryEventInfo.winery.purchaseOrderEmail,
            name: wineryEventInfo.winery.domain,
            address: (0, _wineryUtils.address)(wineryEventInfo.winery)
        };
    }
    function getEventCalendar(event) {
        var openingHours = event.openingHours;
        if (openingHours) {
            let from = _lodash.default.min(_lodash.default.map(openingHours, function (e) {
                return new Date(e.from);
            }));
            let to = _lodash.default.max(_lodash.default.map(openingHours, function (e) {
                return new Date(e.to);
            }));
            return {
                start: from,
                end: to,
                title: event.eventName + ' - ' + event.year,
                description: event.description,
                location: addressEvent(event)
            };
        }
        return {
            start: (0, _moment.default)(event.fromDate, 'YYYY-MM-DD'),
            end: (0, _moment.default)(event.toDate, 'YYYY-MM-DD'),
            isSameMonth: (0, _moment.default)(event.fromDate).isSame(event.toDate, 'month'),
            title: event.eventName + ' - ' + event.year,
            description: event.description,
            location: addressEvent(event)
        };
    }
    // set in address helper
    function addressEvent(event) {
        return event.venueStreet1 + ' ' + event.venueStreet2 + ' - ' + event.venuePostCode + ' ' + event.venueCity + ' - ' + event.venueCountryName;
    }
});