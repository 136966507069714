define('awesome2/models/winery-saleplace-purchase', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        reference: attr('string'),

        saleType: attr('string'),
        shippingCost: attr('string'),

        totalPriceArray: Ember.computed.mapBy('productLines', 'totalPrice'),
        totalPrice: Ember.computed.sum('totalPriceArray'),
        totalPriceDisplay: computed('totalPrice', function () {
            return this.get("totalPrice").toFixed(2);
        }),
        //list of product-lines
        productLines: _emberData.default.hasMany('product-line')
    });
});