define('awesome2/components/js-pdf', ['exports', 'ember-js-pdf/components/js-pdf'], function (exports, _jsPdf) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _jsPdf.default;
    }
  });
});