define('awesome2/routes/sales/index', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        //index
        titleToken: ['nav.sales'],
        productService: Ember.inject.service("product"),
        model(params) {
            return this.get('productService').findSales(params).catch(function (error) {
                console.log("error = " + error);
            });
        }
    });
});