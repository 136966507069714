define('awesome2/models/product-line', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({

        quantity: (0, _attr.default)('int'),

        //productItem description
        idComposite: (0, _attr.default)('string'),
        productItemPriceId: (0, _attr.default)('int'),
        product: (0, _attr.default)('string'),
        productYear: (0, _attr.default)('int'),
        productFormatId: (0, _attr.default)('int'),
        productFormatWebPath: (0, _attr.default)('string'),
        productFormat: (0, _attr.default)('string'),
        productFormatQuantity: (0, _attr.default)('int'),
        productPrice: (0, _attr.default)('double'),
        productWebPath: (0, _attr.default)('string'),
        wineryWebPath: (0, _attr.default)('string'),

        appellationClassificationAcronym: (0, _attr.default)('string'),
        appellationClassificationName: (0, _attr.default)('string'),
        appellationName: (0, _attr.default)('string'),
        appellationWebPath: (0, _attr.default)('string'),
        // new field
        hasProductLogo: (0, _attr.default)('boolean'),
        hasWineryLogo: (0, _attr.default)('boolean'),
        wineryId: (0, _attr.default)('int'),
        winery: (0, _attr.default)('string'),
        colorName: (0, _attr.default)('string'),
        colorRgb: (0, _attr.default)('string'),
        robeName: (0, _attr.default)('string'),
        robeRgb: (0, _attr.default)('string'),

        eventId: (0, _attr.default)('int'),
        eventName: (0, _attr.default)('string'),
        eventWebPath: (0, _attr.default)('string'),
        priceType: (0, _attr.default)('string'),

        totalPrice: Ember.computed('quantity', 'productPrice', function () {
            let q = this.get("quantity");
            let p = this.get("productPrice");
            let m = q * p;
            return m;
        }),

        totalPriceDisplay: Ember.computed('totalPrice', function () {
            let p = this.get("totalPrice");
            return p.toFixed(2);
        }),
        //totalPrice: attr('double'),

        productPriceValidUntil: (0, _attr.default)('date')

    });
});