define('awesome2/controllers/events/wineries/order', ['exports', 'ember-local-storage', 'lodash', 'awesome2/utils/winery-utils', 'awesome2/utils/notification-utils'], function (exports, _emberLocalStorage, _lodash, _wineryUtils, _notificationUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        wineryEventCustomerOrder: (0, _emberLocalStorage.storageFor)("customer-order"),

        model: null,

        //bill
        isValidReference: Ember.computed.gte('model.bill.reference.length', 2),

        //seller
        isValidWineryName: Ember.computed.gte('model.seller.name.length', 5),
        isValidWineryVat: Ember.computed.gte('model.seller.vatNumber.length', 5),
        isValidWineryEmail: Ember.computed.match('model.seller.email', /^.+@.+\..+$/),

        //customer
        isValidClientName: Ember.computed.gte('model.customer.name.length', 2),
        isValidClientEmail: Ember.computed.match('model.customer.email', /^.+@.+\..+$/),
        areTermsAndConditionsAccepted: Ember.computed.equal('model.customer.areTermsAndConditionsAccepted', true),

        isDisabled: Ember.computed('areTermsAndConditionsAccepted', 'isValidReference', 'isValidWineryVat', 'isValidWineryEmail', 'isValidClientName', 'isValidClientEmail', function () {
            return !(this.get('isValidReference') && this.get('isValidWineryVat') && this.get('isValidWineryEmail') && this.get('isValidClientName') && this.get('isValidClientEmail') && this.get("areTermsAndConditionsAccepted"));
        }),
        wineryAddress: Ember.computed('model', function () {
            let model = this.get("model");
            let winery = model.winery;
            return (0, _wineryUtils.address)(winery);
        }),
        totalPriceDisplay: Ember.computed('totalPrice', function () {
            return this.get("totalPrice").toFixed(2);
        }),

        totalPrice: Ember.computed('wineryEventCustomerOrder.[]', function () {
            let items = this.get("wineryEventCustomerOrder.content");
            return _lodash.default.sumBy(items, i => parseFloat(i.total));
        }),

        actions: {
            sendInvoice() {
                debugger;
                (0, _notificationUtils.notifySuccess)(this, "notification.invoice-sent");
            },
            printInvoice() {
                (0, _notificationUtils.notifySuccess)(this, "notification.invoice-pdf");
            }
        }

        // init() {
        //     this._super(...arguments);
        //     debugger
        //     this.set("wineryEmail", this.get("model.winery.purchaseOrderEmail"));
        //     this.set("wineryVatNumber", this.get("model.winery.contactVatNumber"));
        //     this.set("wineryName", this.get("model.winery.name")); 
        // },


    });
});