define('awesome2/components/search/inner-search-pager', ['exports', 'awesome2/utils/common-utils'], function (exports, _commonUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        country: null,
        grape: null,
        countryFlag: Ember.computed('country', function () {
            return (0, _commonUtils.getFlagIcon)(this.get('country'));
        }),
        grapeColor: Ember.computed('grape', function () {
            return (0, _commonUtils.getGrapeColor)(this.get('grape'));
        })
    });
});