define('awesome2/routes/grapes/index', ['exports', 'awesome2/routes/base', 'awesome2/adapters/grape', 'awesome2/utils/business-utils'], function (exports, _base, _grape, _businessUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({

        titleToken: ['nav.grapes'],

        queryParams: {
            country: {
                refreshModel: true,
                replace: true
            },
            region: {
                refreshModel: true,
                replace: true
            },
            place: {
                refreshModel: true,
                replace: true
            },
            appellation: {
                refreshModel: true,
                replace: true
            },
            color: {
                refreshModel: true,
                replace: true
            },
            searchFreeText: {
                replace: true
            }
        },

        model(params) {
            var adapter = _grape.default.create();
            return adapter.findAll(params).then(function (data) {
                (0, _businessUtils.toDisplay)(data.CepageDescriptionOut, params.searchFreeText);
                (0, _businessUtils.setDisplayCount)(data, data.CepageDescriptionOut);
                return data;
            }).catch(function (error) {
                console.log("error = " + error);
            });
        },
        setupController(controller, models) {
            this._super(...arguments);
        }
    });
});