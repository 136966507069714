define('awesome2/routes/feedback', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model() {
            return this.store.createRecord('feedback');
        },
        setupController: function (controller, model) {
            this._super(controller, model);
        },
        actions: {}
    });
});