define('awesome2/components/test-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    actions: {
      doSave(d, meta) {
        //d.reject(); 
        d.resolve();
      },
      doAfterClose() {
        // 
      },
      doClose(d) {
        if (confirm('Please confirm...')) {
          d.resolve();
        } else {
          d.reject();
        }
      }
    }
  });
});