define('awesome2/controllers/code', ['exports', 'awesome2/controllers/base', 'awesome2/config/environment'], function (exports, _base, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const { host, artifactNamespace } = _environment.default.DS;
    const artifactBaseUrl = host + "/" + artifactNamespace;

    exports.default = _base.default.extend({

        queryParams: ['origin', 'entity', 'entityValue', 'language'],
        origin: null,
        entity: null,
        entityValue: null,
        language: null,

        pdfUrl: Ember.computed('origin', function () {
            let origin = this.get("origin");
            return artifactBaseUrl + `/${origin}.pdf`;
        }),

        pdfHtmlCode: Ember.computed('origin', 'entity', 'entityValue', function () {
            let url = this.get("pdfUrl");
            let entity = this.get("entity") || '';
            let entityValue = this.get("entityValue") || '';
            return Ember.String.htmlSafe(`<button type='button'><a href='${url}'>Download ${entity} ${entityValue} Pdf</a></button>`);
        }),

        pngUrl: Ember.computed('origin', function () {
            let origin = this.get("origin");
            return artifactBaseUrl + `/${origin}`;
        }),

        pngHtmlCode: Ember.computed('pngUrl', function () {
            let pngUrl = this.get("pngUrl");
            return Ember.String.htmlSafe(`<img src='${pngUrl}'/>`);
        })

    });
});