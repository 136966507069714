define('awesome2/routes/countries/regions/places/place', ['exports', 'awesome2/routes/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        placeService: Ember.inject.service("place"),
        model(params) {
            return this.get('placeService').findOverview(params.place_key);
        },
        afterModel(model, transition) {
            this.set("titleToken", ['field.place', model.place.name]);
        }
    });
});