define('awesome2/helpers/color-background', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.colorBackground = colorBackground;
  function colorBackground(params /*, hash*/) {
    let [colorNameBase] = params;
    if (colorNameBase && colorNameBase.toLowerCase() == 'white') {
      return "000000";
    } else {
      return "ffffff";
    }
  }

  exports.default = Ember.Helper.helper(colorBackground);
});