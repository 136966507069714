define('awesome2/components/order/purchase-winery-order', ['exports', 'awesome2/mixins/component-base-mixin'], function (exports, _componentBaseMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_componentBaseMixin.default, {
        tagName: '',
        actions: {
            removeWineryProductItemId(productItemPriceId, priceType, eventId) {
                this.sendAction("remove", productItemPriceId, priceType, eventId);
            }
        }
    });
});