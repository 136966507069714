define('awesome2/models/image', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        email: (0, _attr.default)('string'),
        authorLastName: (0, _attr.default)('string'),
        authorFirstName: (0, _attr.default)('string'),
        authorAlias: (0, _attr.default)('string'),
        authorRightsUrl: (0, _attr.default)('string'),
        originUrl: (0, _attr.default)('string'),
        originSiteUrl: (0, _attr.default)('string'),
        tags: (0, _attr.default)('string'),
        category: (0, _attr.default)('string'),
        description: (0, _attr.default)('string'),
        languageCode: (0, _attr.default)('languageCode'),
        ip: (0, _attr.default)('ip'),
        acceptTermsAndConditions: (0, _attr.default)('acceptTermsAndConditions')
    });
});