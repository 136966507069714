define('awesome2/services/region', ['exports', 'awesome2/adapters/region', 'awesome2/adapters/appellation', 'awesome2/adapters/winery', 'awesome2/adapters/place', 'awesome2/utils/business-utils', 'awesome2/utils/winery-utils', 'lodash'], function (exports, _region, _appellation, _winery, _place, _businessUtils, _wineryUtils, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        availableRegionsForCountry(countryId) {
            var adapter = _region.default.create();
            return adapter.availableRegionsForCountry(countryId);
        },
        findByCountryWebpath(countryWebpath) {
            var adapter = _region.default.create();
            return adapter.availableRegionsForCountry(countryWebpath);
        },
        find(webPath) {
            var adapter = _region.default.create();
            return adapter.find(webPath); //TODO replace by find
        },
        // overview
        findOverview(webPath) {
            var adapter = _region.default.create();
            // add promise for child
            var regionPromise = adapter.find(webPath).then(function (data) {
                return (0, _wineryUtils.prepareRegion)(data.RegionBadgeOut[0]);
            });

            var wineryAdapter = _winery.default.create();
            var wineryPromise = wineryAdapter.findByRegionWebPath(webPath).then(data => {
                return (0, _businessUtils.prepareWineries)(data.WineryPublicOut);
            });
            var appellationAdapter = _appellation.default.create();
            var appellationPromise = appellationAdapter.findByRegionWebPath(webPath).then(function (data) {
                _lodash.default.each(data.AppelationBadgeOut, function (item) {
                    (0, _businessUtils.convertComposition)(item);
                });
                return data.AppelationBadgeOut;
            });
            var placeAdapter = _place.default.create();
            var placePromise = placeAdapter.findByRegionWebPath(webPath).then(function (data) {
                return data.LieuDitBadgeOut;
            });
            var promises = {
                region: regionPromise,
                appellations: appellationPromise,
                wineries: wineryPromise,
                places: placePromise
            };
            return Ember.RSVP.hash(promises).then(function (data) {
                return data;
            });
        }
    });
});