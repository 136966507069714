define('awesome2/controllers/application', ['exports', 'awesome2/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const { keys } = Object;

    exports.default = Ember.Controller.extend({
        queryParams: ['anc'],
        anc: 'first',
        ribbonLabel: _environment.default.ENVIRONMENT_RIBBON,

        cookies: Ember.inject.service(),

        allCookies: Ember.computed(function () {
            let cookieService = this.get('cookies');
            cookieService.write('now', new Date().getTime());

            let cookies = cookieService.read();
            return keys(cookies).reduce((acc, key) => {
                let value = cookies[key];
                acc.push({ name: key, value });

                return acc;
            }, []);
        })
    });
});