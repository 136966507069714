define('awesome2/controllers/wineries/winery', ['exports', 'awesome2/utils/common-utils'], function (exports, _commonUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        queryParams: ['caracteristic', 'grape', 'color', 'sweetness', 'bubbleness', 'order'],
        caracteristic: null,
        grape: null,
        color: null,
        sweetness: null,
        bubbleness: null,
        order: null,

        actions: {
            setWineryProductFilter(filter) {
                // TODO add grape to the already present ones, (if all selected remove)
                if (filter) {
                    (0, _commonUtils.setFilter)(this, filter, "bubbleness");
                    (0, _commonUtils.setFilter)(this, filter, "sweetness");
                    (0, _commonUtils.setFilter)(this, filter, "grape");
                    (0, _commonUtils.setFilter)(this, filter, "color");
                    (0, _commonUtils.setFilter)(this, filter, "order");
                }
            }
        }
    });
});