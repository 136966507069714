define('awesome2/components/snippet/nav/wp-nav', ['exports', 'awesome2/templates/components/snippet/tab/wp-tab'], function (exports, _wpTab) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _wpTab.default,

    tagName: 'ul',
    classNames: ['nav'],

    classNameBindings: ['typeClass', 'justified:nav-justified', 'stacked:nav-stacked'],
    //classNameBindings: ['stacked:nav-stacked'],

    typeClass: Ember.computed('type', function () {
      let type = this.get('type');
      if (Ember.isPresent(type)) {
        return `nav-${type}`;
      }
    }),

    /**
     * Special type of nav, either "pills" or "tabs"
     *
     * @property type
     * @type String
     * @default null
     * @public
     */
    type: null,

    /**
     * Make the nav justified, see [bootstrap docs](http://getbootstrap.com/components/#nav-justified)
     *
     * @property justified
     * @type boolean
     * @default false
     * @public
     */
    justified: false,

    /**
     * Make the nav pills stacked, see [bootstrap docs](http://getbootstrap.com/components/#nav-pills)
     *
     * @property stacked
     * @type boolean
     * @default false
     * @public
     */
    stacked: false,

    /**
     * @property itemComponent
     * @type {String}
     * @private
     */
    //itemComponent: 'bs-nav/item',
    itemComponent: 'snippet/nav/wp-nav/item',

    /**
     * @property linkToComponent
     * @type {String}
     * @private
     */
    //linkToComponent: 'bs-nav/link-to',
    linkToComponent: 'snippet/nav/wp-nav/link-to',

    /**
     * @property dropdownComponent
     * @type {String}
     * @private
     */
    //dropdownComponent: 'bs-dropdown'
    dropdownComponent: 'snippet/bs/bs-dropdown'
  });
});