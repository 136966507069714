define('awesome2/services/translations-fetcher', ['exports', 'awesome2/config/environment', 'awesome2/utils/common-utils'], function (exports, _environment, _commonUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { host, namespace } = _environment.default.DS;
  const PATH_ROOT = host + '/' + namespace + '/data/locales/';

  exports.default = Ember.Service.extend({
    i18n: Ember.inject.service(),
    ajax: Ember.inject.service(),

    fetch() {
      let loc = (0, _commonUtils.getUserI18n)();
      return fetchLocal(this, loc);
    },

    /*
      _addTranslationsFull(json) {
        const i18n = this.get('i18n');
        Object.keys(json).forEach((locale) => {
          i18n.addTranslations(locale, json[locale]);
        });
      },
     */

    _addTranslations(json, loc) {
      const i18n = this.get('i18n');
      i18n.addTranslations(loc, json);
      this.set('i18n.locale', loc);
    }

  });


  function fetchLocal(_this, loc) {
    let path = PATH_ROOT + loc + "/module";
    return _this.get('ajax').request(path).then(data => {
      _this._addTranslations(data, loc);
    });
  }
});