define('awesome2/routes/events/wineries/order', ['exports', 'awesome2/routes/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        eventService: Ember.inject.service("event"),
        //TODO check how to keep the same model between pages
        model(params) {
            params.forOrder = true;
            let wineryEventInfo = this.get('eventService').findWineryEventInfo(params);
            // wineryEventInfo.customer = {
            //     name : null,
            //     email : null,
            //     areTermsAndConditionsAccepted : false
            // };
            // wineryEventInfo.bill = {
            //     reference: null,

            // };
            // wineryEventInfo.seller = {
            //     vatNumber : wineryEventInfo.winery.vatNumber,
            //     email : model.winery.purchaseOrderEmail,
            //     name : model.winery.name,
            // };
            // debugger
            return wineryEventInfo;
        },
        afterModel(model, transition) {
            let title = model.winery.domain;
            this.set("titleToken", ['field.winery', title, 'field.order']);
        },
        setupController: function (controller, model) {
            this._super(controller, model);
        }
    });
});