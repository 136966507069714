define('awesome2/components/card/appellation/appellation-card-abstract-item', ['exports', 'lodash', 'awesome2/utils/business-utils'], function (exports, _lodash, _businessUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        //TODO use typescript + generic to remove redundancy with region and other 'route entities' on autonomous mode
        autonomous: null,
        data: null,
        appellationWebPath: null,

        appellationService: Ember.inject.service("appellation"),
        didInsertElement() {
            this._super(...arguments);
            if (this.get('autonomous') && this.get('appellationWebPath')) {
                this.get('appellationService').find(this.get("appellationWebPath")).then(data => {
                    //TODO replace by webpath
                    let item = data.AppelationBadgeOut[0];
                    (0, _businessUtils.convertComposition)(item);
                    this.set('data', item);
                });
            }
        }

    });
});