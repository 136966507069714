define('awesome2/components/snippet/product-color-badge-snippet', ['exports', 'awesome2/components/snippet/badge-snippet'], function (exports, _badgeSnippet) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        tagName: ''

    });
});