define('awesome2/services/info', ['exports', 'awesome2/adapters/info', 'lodash'], function (exports, _info, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });


    const { Service, inject } = Ember;

    exports.default = Service.extend({

        findImageInfo(entityId, entityWebPath, displayOrder) {
            var adapter = _info.default.create();
            return adapter.findImageInfo(entityId, entityWebPath, displayOrder);
        },

        findImagesInfo(whereEntity, whereEntityWebPath) {
            var adapter = _info.default.create();
            return adapter.findImagesInfo(whereEntity, whereEntityWebPath);
        },

        findCountryImagesInfo(whereEntityWebPath) {
            var adapter = _info.default.create();
            var countryImagePromise = adapter.findImagesInfo("country", whereEntityWebPath);
            var countryImageRegionsPromise = adapter.findImagesInfo("country_regions", whereEntityWebPath);
            var promises = {
                country: countryImagePromise,
                countryRegions: countryImageRegionsPromise
            };
            return Ember.RSVP.hash(promises).then(function (data) {
                return data;
            });
        }

    });
});