define('awesome2/adapters/product', ['exports', 'ember-data', 'awesome2/utils/common-utils', 'awesome2/adapters/base', 'npm:append-query', 'awesome2/config/environment'], function (exports, _emberData, _commonUtils, _base, _npmAppendQuery, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const { host, namespace } = _environment.default.DS;
    const baseUrl = host + "/" + namespace;

    exports.default = _base.default.extend({

        //deprecated
        find: function (webpath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryProductItemBadgeIn?webPath=' + webpath);
        },
        findById: function (id) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryProductItemBadgeIn?productId=' + id);
        },
        /*
        findByProductItemId: function(productItemId){
            return fetchData(baseUrl+'/data/sdd/WineryProductItemBadgeIn?productItemId='+productItemId);
        },
        */
        findByWebPath: function (webpath, wineryWebPath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryProductItemBadgeIn?webPath=' + webpath + "&wineryWebPath=" + wineryWebPath);
        },
        findByWineryWebPath: function (wineryWebPath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryProductItemBadgeIn?wineryWebPath=' + wineryWebPath);
        },
        findByEventWebPath: function (eventWebPath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryProductItemBadgeIn?eventWebPath=' + eventWebPath);
        },
        findConservationMethodByProductWebPath: function (webPath) {
            return findConservationMethodByProductWebPath({ webPath: webPath });
        },
        findProductItemPriceByEventWebPath: function (eventWebPath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/EventWinePriceItemIn?scope=underEventPrice&eventWebPath=' + eventWebPath);
        },
        //For  list of product event-winery oriented
        findProductItemPriceByEventIdAndWineryId: function (eventId, wineryId) {
            //scope=underEventPrice since used in PO to rename
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/EventWinePriceItemIn?eventId=' + eventId + '&wineryId=' + wineryId);
        },
        findProductItemPriceByEventWebPathAndWineryWebPath: function (eventWebPath, wineryWebPath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/EventWinePriceItemIn?scope=underEventPrice&eventWebPath=' + eventWebPath + '&wineryWebPath=' + wineryWebPath);
        },
        findProductItemPriceByEventIdAndProductItemPriceId: function (eventId, productItemPriceId) {
            //scope=underEventPrice since used in PO to rename
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/EventWinePriceItemIn?eventId=' + eventId + '&productItemPriceId=' + productItemPriceId);
        },
        findProductItemPriceByWineryWebPathUnderEventPresale: function (webPath) {
            //! EventWinePriceItemIn not WineryWinePriceItemIn
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/EventWinePriceItemIn?scope=eventPresalePrice&wineryWebPath=' + webPath);
        },
        //For  list of product winery oriented
        findProductItemPriceByWineryId: function (wineryId) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryWinePriceItemIn?wineryId=' + wineryId);
        },
        findProductItemPriceByWineryWebPath: function (webPath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryWinePriceItemIn?wineryWebPath=' + webPath);
        },

        findProductItemPriceByWineryIdAndProductItemPriceId: function (wineryId, productItemPriceId) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryWinePriceItemIn?wineryId=' + wineryId + '&productItemPriceId=' + productItemPriceId);
        },

        winerySales: function () {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryWinePriceItemIn?validPriceDate=true');
        },

        eventPresales: function () {
            //scope=underEventPrice since used in PO to rename
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/EventWinePriceItemIn?scope=eventPresalePrice');
        }

    });


    function findConservationMethodByProductWebPath(filters) {
        return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryProductConservationBadgeIn' + formatFilter(filters)); //TODO format filters
    }

    function formatFilter(filters) {
        return (0, _npmAppendQuery.default)("", {
            "webPath": filters.webPath
        }, { removeNull: true });
    }
});