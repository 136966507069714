define("awesome2/helpers/grape-color", ["exports", "awesome2/utils/common-utils"], function (exports, _commonUtils) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.grapeColor = grapeColor;
  function grapeColor(params /*, hash*/) {
    let [grape] = params;
    return (0, _commonUtils.getGrapeColor)(grape);
  }

  exports.default = Ember.Helper.helper(grapeColor);
});