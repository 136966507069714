define('awesome2/controllers/regions/region', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        anchorQueryParam: 'custom',
        queryParams: ['custom'],
        custom: 'places',
        actions: {
            shiftTab(event) {
                /*
                 $("#category").val(event.srcElement.innerText);
                 this.get("model").set("category",event.srcElement.innerText);
                 */
            }
        }
    });
});