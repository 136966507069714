define('awesome2/components/card/event/event-card-overview', ['exports', 'awesome2/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        environment: _environment.default.ENVIRONMENT,
        requestEventInvitationModal: false,
        requestEventFeePresaleModal: false,

        actions: {
            forwardToEventCardOverview(filter) {
                this.sendAction("forwardFromEventCardOverview", filter);
            },
            requestEventInvitation() {
                this.set('requestEventInvitationModal', true);
            },
            requestEventFeePresale() {
                this.set('requestEventFeePresaleModal', true);
            },
            addToCal() {
                console.log('addToCal!!');
            }

        }
    });
});