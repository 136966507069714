define('awesome2/services/suggestion', ['exports', 'awesome2/adapters/search'], function (exports, _search) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        homePageSuggestions: function (languageCode, longitude, latitude) {
            var adapter = _search.default.create();
            return adapter.homePageSuggestions(languageCode, longitude, latitude);
        }

    });
});