define('awesome2/helpers/i18n-ref', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.i18nRef = i18nRef;
  function i18nRef(params /*, hash*/) {
    let [i18nRootLabel, v] = params;
    if (v && (typeof v === 'string' || v instanceof String)) {
      return i18nRootLabel + v.toLowerCase();
    } else {
      return i18nRootLabel + v;
    }
  }

  exports.default = Ember.Helper.helper(i18nRef);
});