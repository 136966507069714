define('awesome2/components/form/input-language', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        languageService: Ember.inject.service("language"),
        availableLanguages: null,
        selectedLanguage: null,

        actions: {
            select(language) {
                this.set('selectedLanguage', language);
                this.attrs.onSelectLanguage(language);
            }
        },
        didInsertElement() {
            this._super(...arguments);
            var regionId = this.get("regionId");
            this.get('languageService').availableLanguages().then(data => {
                this.set('availableLanguages', data.TranslationLanguageOut);
            });
        }

    });
});