define('awesome2/routes/appellations/index', ['exports', 'awesome2/routes/base', 'lodash', 'awesome2/utils/business-utils'], function (exports, _base, _lodash, _businessUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        //TODO in ts generic

        titleToken: ['field.appellations'],
        queryParams: {
            country: {
                refreshModel: true,
                replace: true
            },
            region: {
                refreshModel: true,
                replace: true
            },
            place: {
                refreshModel: true,
                replace: true
            },
            grape: {
                refreshModel: true,
                replace: true
            },
            color: {
                refreshModel: true,
                replace: true
            },
            searchFreeText: {
                replace: true
            }
        },

        filter: null,
        appellationService: Ember.inject.service("appellation"),
        beforeModel(transition) {
            let queryParams = transition.queryParams;
        },

        model(params) {
            return this.get('appellationService').findAll(params).then(function (data) {
                _lodash.default.each(data.AppelationBadgeOut, function (item) {
                    (0, _businessUtils.convertComposition)(item);
                });
                (0, _businessUtils.toDisplay)(data.AppelationBadgeOut, params.searchFreeText);
                (0, _businessUtils.setDisplayCount)(data, data.AppelationBadgeOut);
                return data;
            }).catch(function (error) {
                console.log("error = " + error);
                //reject(error);
            });
        },
        setupController(controller, models) {
            this._super(...arguments);
        },
        actions: {
            willTransition: function (transition) {
                //console.log('trying to transition');
            }
        }
    });
});