define('awesome2/models/purchase-batch', ['exports', 'ember-data'], function (exports, _emberData) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({
        //put common aspects of purchase needed at different level
        //ex: total
    });
});