define('awesome2/helpers/cond', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.cond = cond;
    function cond(params /*, hash*/) {
        let [v1, operator, v2] = params;
        if (v2 == undefined) return params;
        switch (operator) {
            case '==':
                return v1 == v2;
            case '===':
                return v1 === v2;
            case '<':
                return v1 < v2;
            case '<=':
                return v1 <= v2;
            case '>':
                return v1 > v2;
            case '>=':
                return v1 >= v2;
            case '&&':
                return v1 && v2;
            case '||':
                return v1 || v2;
            case '!=':
                return v1 != v2;
            case '%':
                return v1 % v2 === 0;
            case 'in':
                return v2.split(",").indexOf(v1) >= 0;
            case 'not_in':
                return v2.split(",").indexOf(v1) < 0;
            default:
                return params;
        }
    }

    exports.default = Ember.Helper.helper(cond);
});