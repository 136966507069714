define("awesome2/helpers/pill-title", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.pillTitle = pillTitle;
  function pillTitle(params /*, hash*/) {
    let [i18n, number] = params;
    let ret = number > 0 ? i18n + " <span class='label label-success'>" + number + "</span>" : i18n;
    return Ember.String.htmlSafe("<h4>" + ret + "</h4>");
  }

  exports.default = Ember.Helper.helper(pillTitle);
});