define('awesome2/routes/countries/regions/region', ['exports', 'awesome2/routes/base'], function (exports, _base) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        regionService: Ember.inject.service("region"),
        model(params) {
            return this.get('regionService').findOverview(params.region_key).then(data => {
                //TODO replace by webpath
                return data;
            });
        },
        afterModel(model, transition) {
            this.set("titleToken", ['field.region', model.region.name]);
        }
    });
});