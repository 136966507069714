define('awesome2/models/winery-purchase', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({

        winery: (0, _attr.default)('string'),
        wineryId: (0, _attr.default)('int'),
        wineryWebPath: (0, _attr.default)('string'),
        hasWineryLogo: (0, _attr.default)('boolean'),

        totalPriceArray: Ember.computed.mapBy('productLines', 'totalPrice'),
        totalPrice: Ember.computed.sum('totalPriceArray'),
        totalPriceDisplay: Ember.computed('totalPrice', function () {
            return this.get("totalPrice").toFixed(2);
        }),
        //list of product-lines
        //TODO remove productLines and use winerySalePlaces
        productLines: _emberData.default.hasMany('product-line'),
        winerySalePlaces: _emberData.default.hasMany('winery-saleplace-purchase')

    });
});