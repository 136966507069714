define('awesome2/services/place', ['exports', 'awesome2/adapters/place', 'awesome2/adapters/appellation', 'awesome2/adapters/winery', 'awesome2/utils/business-utils', 'lodash'], function (exports, _place, _appellation, _winery, _businessUtils, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        find(webPath) {
            var adapter = _place.default.create();
            return adapter.find(webPath);
        },
        availableLieuDitsForRegion(regionId) {
            var adapter = _place.default.create();
            return adapter.availableLieuDitsForRegion(regionId);
        },
        findOverview(webPath) {
            var adapter = _place.default.create();
            var placePromise = adapter.find(webPath).then(data => {
                return data.LieuDitBadgeOut[0];
            });
            var wineryAdapter = _winery.default.create();
            var wineryPromise = wineryAdapter.findByPlaceWebPath(webPath).then(data => {
                return (0, _businessUtils.prepareWineries)(data.WineryPublicOut);
            });
            var appellationAdapter = _appellation.default.create();
            var appellationPromise = appellationAdapter.findByPlaceWebPath(webPath).then(function (data) {
                //duplicate with route/appellation/index.js
                _lodash.default.each(data.AppelationBadgeOut, function (item) {
                    (0, _businessUtils.convertComposition)(item);
                });

                return data.AppelationBadgeOut;
            });
            var promises = {
                place: placePromise,
                appellations: appellationPromise,
                wineries: wineryPromise
            };
            return Ember.RSVP.hash(promises).then(function (data) {
                return data;
            });
        }
    });
});