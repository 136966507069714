define('awesome2/components/snippet/social-network-snippet', ['exports', 'awesome2/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({

        environment: _environment.default.ENVIRONMENT,
        tagName: '',
        addCode: false,
        addPdf: false,
        isCodeEnabled: Ember.computed('addCode', function () {
            return this.get('addCode');
        }),
        isPdfEnabled: Ember.computed('addPdf', function () {
            return this.get('addPdf');
        })
    });
});