define('awesome2/components/info/description-info', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: ''
        //is automonous => load from entity, entity WebPath, language 
    });
});