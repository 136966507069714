define('awesome2/components/snippet/captcha-snippet', ['exports', 'awesome2/utils/captcha-utils', 'awesome2/utils/common-utils', 'awesome2/mixins/component-base-mixin', 'awesome2/config/environment', 'ember-local-storage'], function (exports, _captchaUtils, _commonUtils, _componentBaseMixin, _environment, _emberLocalStorage) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_componentBaseMixin.default, {

        locale: (0, _commonUtils.getUserI18n)(),
        userEnteredCaptchaCode: null,
        email: null,
        firstName: null,
        lastName: null,

        stylename: "basicCaptcha",
        environment: _environment.default.ENVIRONMENT,

        isValidEmail: Ember.computed.match('email', /^.+@.+\..+$/),
        isCaptchaLengthOk: Ember.computed.gte('userEnteredCaptchaCode.length', 3),

        isDisabled: Ember.computed('isValidEmail', 'isCaptchaLengthOk', function () {
            return !(this.get("isValidEmail") && this.get("isCaptchaLengthOk"));
        }),

        didInsertElement() {
            this._super(...arguments);
            (0, _captchaUtils.displayCaptchaHtml)(this);
        },

        session: Ember.inject.service(),
        user: (0, _emberLocalStorage.storageFor)("user"),

        actions: {
            captchaReset() {
                (0, _captchaUtils.displayCaptchaHtml)(this);
                this.set('userEnteredCaptchaCode', null);
            },

            captchaAuthenticate() {

                var captchaId = document.getElementById('BDC_VCID_' + this.get("stylename")).getAttribute("value"); //captcha.getCaptchaId();

                var data = {
                    userEnteredCaptchaCode: this.get('userEnteredCaptchaCode'),
                    email: this.get('email'),
                    firstName: this.get('firstName'),
                    lastName: this.get('lastName'),
                    captchaId: captchaId
                };

                var authenticator = 'authenticator:captcha2';
                this.get('session').authenticate(authenticator, data).then(() => {
                    let userLogin = this.get("user");
                    userLogin.set("login", data.email);
                    userLogin.set("gender", "N/A");
                    userLogin.set("firstName", data.firstName);
                    userLogin.set("lastName", data.lastName);
                }).catch(reason => {
                    this.set('errorMessage', reason.error || reason);
                    this.set('userEnteredCaptchaCode', null);
                    (0, _captchaUtils.displayCaptchaHtml)(this);
                });
            }
        }

    });
});