define('awesome2/components/button-base', ['exports', 'ember-add-calendar-button/components/button-base'], function (exports, _buttonBase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _buttonBase.default;
    }
  });
});