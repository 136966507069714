define('awesome2/services/country', ['exports', 'awesome2/adapters/country', 'awesome2/adapters/appellation', 'awesome2/adapters/region', 'awesome2/utils/business-utils', 'awesome2/utils/common-utils', 'awesome2/utils/winery-utils', 'lodash'], function (exports, _country, _appellation, _region, _businessUtils, _commonUtils, _wineryUtils, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        availableCountries() {
            var adapter = _country.default.create();
            return adapter.availableCountries();
        },
        findAll() {
            var adapter = _country.default.create();
            return adapter.findAll();
        },
        find(webpath) {
            var adapter = _country.default.create();
            return adapter.find(webpath);
        },
        findWithInfo(entityId, entityWebPath, displayOrder) {
            var adapter = _country.default.create();
            return adapter.findWithInfo(entityId, entityWebPath, displayOrder, null);
        },
        findAllWithInfo(filters) {
            var adapter = _country.default.create();
            return adapter.findWithInfo(null, null, 1, filters);
        },

        findOverview(webpath) {
            var adapter = _country.default.create();
            var countryPromise = adapter.findWithInfo(null, webpath, 1) //country webpath
            .then(function (data) {
                return data.CountryDescriptionWithInfoOut[0];
            });

            //var regionService = RegionService.create();
            var regionAdapter = _region.default.create();
            var regionPromise = regionAdapter.findByCountryWebpath(webpath).then(function (data) {
                _lodash.default.each(data.RegionBadgeOut, function (region) {
                    (0, _wineryUtils.prepareRegion)(region);
                });
                return data.RegionBadgeOut;
            });
            var appellationAdapter = _appellation.default.create();
            var appellationPromise = appellationAdapter.findByCountryWebPath(webpath).then(function (data) {
                //duplicate with route/appellation/index.js
                _lodash.default.each(data.AppelationBadgeOut, function (item) {
                    (0, _businessUtils.convertComposition)(item);
                });

                return data.AppelationBadgeOut;
            });
            var promises = {
                country: countryPromise,
                regions: regionPromise,
                appellations: appellationPromise
            };

            return Ember.RSVP.hash(promises).then(function (data) {
                //TODO duplicate code with country service
                _lodash.default.each(data.regions, function (item) {
                    //TODO group in a function common code with show.js
                    item.cepages = item.cepageNames ? item.cepageNames.split(',') : []; //TODO replace by cepageNames
                });
                return data;
            });
        },

        store() {
            (0, _commonUtils.getCountriesInfo)();
        }

    });
});