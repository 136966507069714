define('awesome2/helpers/image-url', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.imageUrl = imageUrl;
  function imageUrl(params /*, hash*/) {
    let [entity, id, webPath, pictureMediumSymlink] = params;
    const config = Ember.getOwner(this).resolveRegistration('config:environment');
    //TODO change with env url
    return 'https://winerylabs.com/images/' + entity + '/' + id + '_' + webPath + '/' + pictureMediumSymlink;
  }

  exports.default = Ember.Helper.helper(imageUrl);
});