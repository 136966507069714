define('awesome2/mixins/component-in-viewport', ['exports', 'awesome2/mixins/component-base-mixin', 'ember-in-viewport'], function (exports, _componentBaseMixin, _emberInViewport) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_componentBaseMixin.default, _emberInViewport.default, {
        //TODO duplicate code with image-author-link
        inViewport: false,
        inViewportRendered: false,

        isInViewport: Ember.computed('inViewport', function () {
            return this.get('inViewport');
        }),

        didEnterViewport() {
            this.set("inViewport", true);
            //let webpath = this.get("data.webPath");
            //console.log("enter view port");
        }

    });
});