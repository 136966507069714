define('awesome2/utils/business-utils', ['exports', 'lodash', 'awesome2/utils/string-utils', 'npm:append-query', 'awesome2/utils/winery-utils'], function (exports, _lodash, _stringUtils, _npmAppendQuery, _wineryUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.convertComposition = convertComposition;
    exports.convertGrapes = convertGrapes;
    exports.convertAppellations = convertAppellations;
    exports.convertProductsDetails = convertProductsDetails;
    exports.getProductFilter = getProductFilter;
    exports.convertSocialNetworkDetails = convertSocialNetworkDetails;
    exports.convertOpeningHours = convertOpeningHours;
    exports.convertWineryParticipation = convertWineryParticipation;
    exports.convertWineriesPerRegion = convertWineriesPerRegion;
    exports.convertWineriesPerCountry = convertWineriesPerCountry;
    exports.convertNameWebpathNumberToJson = convertNameWebpathNumberToJson;
    exports.prepareWinery = prepareWinery;
    exports.prepareWineries = prepareWineries;
    exports.filterAndPrepareWineries = filterAndPrepareWineries;
    exports.prepareImagesInfo = prepareImagesInfo;
    exports.prepareImageInfo = prepareImageInfo;
    exports.prepareEventContact = prepareEventContact;
    exports.prepareEvent = prepareEvent;
    exports.prepareEvents = prepareEvents;
    exports.toDisplay = toDisplay;
    exports.toDisplayDomain = toDisplayDomain;
    exports.toDisplayEvent = toDisplayEvent;
    exports.setDisplayCount = setDisplayCount;
    exports.setDisplayWineries = setDisplayWineries;
    exports.setDisplayEvents = setDisplayEvents;
    exports.toDisplayAll = toDisplayAll;
    exports.formatFilter = formatFilter;
    exports.formatCepageNamesAndPicture = formatCepageNamesAndPicture;
    exports.prepareConservationProcessing = prepareConservationProcessing;
    function convertComposition(appellation) {
        let composition = appellation.composition ? appellation.composition.split(',') : [];
        let compositionArr = [];
        var all100Percent = true;
        _lodash.default.each(composition, function (e) {
            let element = e.split('|');
            let jsonElement = { grade: element[0], webPath: element[1], percent: element[2], operator: element[3] };
            compositionArr.push(jsonElement);
            if (element[2] != 100) {
                all100Percent = false;
            }
        });
        //if all min_optional/max_optional 100 and compositionArr.length >= 2 then no percent and operator
        appellation.anyGrape = all100Percent;
        appellation.composition = composition ? compositionArr : composition;
        appellation.isToDisplay = true;
    }

    function convertGrapes(cepageIdNames) {
        let arr = cepageIdNames ? cepageIdNames.split('|') : [];
        let result = [];
        _lodash.default.each(arr, function (e) {
            let element = e.split(',');
            let jsonElement = { grade: element[1] //, webPath : element [1], percent : element[2], operator : element[3]};
            };result.push(jsonElement);
        });
        return result;
    }

    function convertAppellations(winery) {
        //let appellations = winery.appellationDetails?winery.appellationDetails.split(','):[];
        //TODO CHANGE productsDetails to appellationDetails
        let appellations = winery.productsDetails ? winery.productsDetails.split(',') : [];
        let appellationsArr = [];
        _lodash.default.each(appellations, function (e) {
            let element = e.split('|');
            if (element[0]) {
                let jsonElement = {
                    name: element[0],
                    acronym: element[1],
                    description: element[2],
                    webPath: element[3], //webPath(element[0]),
                    colorRgb: element[4],
                    colorName: element[5]
                };
                appellationsArr.push(jsonElement);
            }
        });
        winery.appellationDetails = appellations ? appellationsArr : appellations;
    }

    function convertLabelsDetails(winery) {
        let labelDetails = winery.labelDetails ? winery.labelDetails.split(',') : [];
        let pdArr = [];
        _lodash.default.each(labelDetails, function (e) {
            let element = e.split('|');
            let jsonElement = {
                id: element[0],
                name: element[1],
                webPath: element[2],
                code: element[3]
            };
            pdArr.push(jsonElement);
        });
        winery.labelDetails = labelDetails ? pdArr : labelDetails;
    }

    function convertProductsDetails(winery) {
        let productsDetails = winery.productsDetails ? winery.productsDetails.split(',') : [];
        let pdArr = [];
        _lodash.default.each(productsDetails, function (e) {
            let element = e.split('|');
            let jsonElement = {
                isAppellation: element[0].trim().length > 0,
                appellationName: element[0],
                appellationAcronym: element[1],
                appellationDescription: element[2],
                appellationWebPath: element[3],
                colorRgb: element[4],
                colorName: element[5],
                nb: element[6]
            };
            pdArr.push(jsonElement);
        });
        winery.productsDetails = productsDetails ? _lodash.default.orderBy(pdArr, ['appellationName', 'nb'], ['desc', 'asc']) : productsDetails;
    }

    function getProductFilter(products, productFilter) {
        let productArr = products ? products : [];
        if (productFilter.byColors) {}
        _lodash.default.each(productsDetails, function (e) {
            let element = e.split('|');
            let jsonElement = {
                isAppellation: element[0].trim().length > 0,
                appellationName: element[0],
                appellationAcronym: element[1],
                appellationDescription: element[2],
                appellationWebPath: element[3],
                colorRgb: element[4],
                colorName: element[5],
                nb: element[6]
            };
            pdArr.push(jsonElement);
        });
        winery.productsDetails = productsDetails ? _lodash.default.orderBy(pdArr, ['appellationName', 'nb'], ['desc', 'asc']) : productsDetails;
    }

    function convertSocialNetworkDetails(data) {
        let socialNetworkDetails = data.socialNetworkDetails ? data.socialNetworkDetails.split(',') : [];
        let arr = [];
        _lodash.default.each(socialNetworkDetails, function (e) {
            let element = e.split('|');
            let jsonElement = {
                link: element[0],
                socialNetworkName: element[1].toLowerCase()
            };
            arr.push(jsonElement);
        });
        data.socialNetworkDetails = socialNetworkDetails ? arr : socialNetworkDetails;
    }

    function convertOpeningHours(data) {
        let openingHours = data.openingHours ? data.openingHours.split(',') : [];
        let arr = [];
        _lodash.default.each(openingHours, function (e) {
            let element = e.split('|');
            let jsonElement = {
                day: element[0],
                from: element[1],
                to: element[2]
            };
            arr.push(jsonElement);
        });
        data.openingHours = openingHours ? arr : openingHours;
    }

    function convertWineryParticipation(data, wineryFilter) {
        let eventWineryParticipation = data.eventWineryParticipation ? data.eventWineryParticipation.split(',') : [];
        let arr = [];
        _lodash.default.each(eventWineryParticipation, function (e) {
            let element = e.split('|');
            //if (element[0] == data.webPath) { eventWebPath from context : todo in query
            let jsonElement = {
                eventWebPath: element[0],
                level: element[1],
                booth: element[2]
            };
            arr.push(jsonElement);
            //}
        });
        data.eventWineryParticipation = eventWineryParticipation ? arr : eventWineryParticipation;
        if (wineryFilter && wineryFilter.eventWebPath && data.eventWineryParticipation) {
            data.eventWineryParticipation = _lodash.default.filter(data.eventWineryParticipation, { 'eventWebPath': wineryFilter.eventWebPath });
        }
    }

    function convertWineriesPerRegion(data) {
        let nbOfWineriesPerRegion = data.nbOfWineriesPerRegion ? data.nbOfWineriesPerRegion.split(',') : [];
        let arr = convertNameWebpathNumberToJson(nbOfWineriesPerRegion);
        data.nbOfWineriesPerRegion = nbOfWineriesPerRegion ? arr : nbOfWineriesPerRegion;
    }

    function convertWineriesPerCountry(data) {
        let nbOfWineriesPerCountry = data.nbOfWineriesPerCountry ? data.nbOfWineriesPerCountry.split(',') : [];
        let arr = convertNameWebpathNumberToJson(nbOfWineriesPerCountry);
        data.nbOfWineriesPerCountry = nbOfWineriesPerCountry ? arr : nbOfWineriesPerCountry;
    }

    function convertNameWebpathNumberToJson(data) {
        let arr = [];
        _lodash.default.each(data, function (e) {
            let element = e.split('|');
            let jsonElement = {
                name: element[0],
                webPath: element[1],
                nb: element[2]
            };
            arr.push(jsonElement);
        });
        return arr;
    }

    function hasPriceRange(winery) {
        return winery.minPrice || winery.maxPrice;
    }

    function prepareWinery(winery, wineryFilter) {
        winery.cepages = winery.cepageNames ? winery.cepageNames.split(',') : [];
        winery.imageArrayIds = winery.pictureIds ? winery.pictureIds.split(',') : [];
        winery.firstPicture = winery.pictureIds ? winery.pictureIds.split(',')[0] : "";
        winery.imageShortcutUrlArray = winery.pictureShortUrls ? winery.pictureShortUrls.split() : [];
        winery.tags = winery.tags ? winery.tags.split(',') : [];
        winery.hasPriceRange = hasPriceRange(winery);
        winery.isBioSinceDate = winery.isBioSinceYear ? new Date(winery.isBioSinceDate, 0, 1) : null;
        winery.certifiedBioDate = winery.certifiedBioYear ? new Date(winery.certifiedBioYear, 0, 1) : null;
        winery.certifiedBioDynamicDate = winery.certifiedBioDynamicYear ? new Date(winery.certifiedBioDynamicDate, 0, 1) : null;
        winery.certifiedBioDynamicDate = winery.certifiedBioDynamicYear ? new Date(winery.certifiedBioDynamicYear, 0, 1) : null;
        convertAppellations(winery);
        convertLabelsDetails(winery);
        convertProductsDetails(winery);
        convertSocialNetworkDetails(winery);
        convertWineryParticipation(winery, wineryFilter);
        return winery;
    }

    function prepareWineries(data, wineryFilter) {
        _lodash.default.each(data, function (winery) {
            winery = prepareWinery(winery, wineryFilter);
        });
        return data;
    }

    function filterAndPrepareWineries(data, wineryFilter) {
        data.wineryAbstract = (0, _wineryUtils.initProductWineryOptions)(data.wineries, wineryFilter);
        data.wineries = (0, _wineryUtils.filterWineries)(data.wineries, wineryFilter);
        return prepareWineries(data.wineries, wineryFilter);
    }

    function prepareImagesInfo(data) {
        _lodash.default.each(data, function (imageInfo) {
            imageInfo = prepareImageInfo(imageInfo);
        });
        return data;
    }

    function prepareImageInfo(imageInfo) {
        imageInfo.id = imageInfo.entityId;
        return imageInfo;
    }

    function prepareEventContact(data) {
        return {
            contact: data.venueName,
            contactStreet1: data.venueStreet1,
            contactStreet2: data.venueStreet2,
            contactPostCode: data.venuePostCode,
            contactCity: data.venueCity,
            contactPhone: data.venueTelephone,
            countryWebPath: data.venueCountryWebPath
        };
    }

    function prepareEvent(event) {
        event.hasVenue = event.venueName ? true : false;
        event.tags = event.tags ? event.tags.split(',') : [];
        convertSocialNetworkDetails(event);
        convertWineriesPerRegion(event);
        convertWineriesPerCountry(event);
        convertOpeningHours(event);
        let venue = prepareEventContact(event);
        event.venue = venue;
        return event;
    }

    function prepareEvents(events) {
        _lodash.default.forEach(events, function (item) {
            //data.EventInfoOut
            prepareEvent(item);
        });
        return events;
    }

    function toDisplay(list, searchFreeText) {
        if (searchFreeText) {
            var lc = _stringUtils.default.replaceAccents(searchFreeText.toLowerCase());
            _lodash.default.forEach(list, function (item) {
                if (_stringUtils.default.replaceAccents(item.name.toLowerCase()).includes(lc)) {
                    Ember.set(item, "toDisplay", true);
                } else {
                    Ember.set(item, "toDisplay", false);
                }
            });
        } else {
            toDisplayAll(list);
        }
    }

    function toDisplayDomain(list, searchFreeText) {
        if (searchFreeText) {
            var lc = _stringUtils.default.replaceAccents(searchFreeText.toLowerCase());
            _lodash.default.forEach(list, function (item) {
                if (_stringUtils.default.replaceAccents(item.domain.toLowerCase()).includes(lc)) {
                    Ember.set(item, "toDisplay", true);
                } else {
                    Ember.set(item, "toDisplay", false);
                }
            });
        } else {
            toDisplayAll(list);
        }
    }
    //TODO merge with toDisplayWinery
    function toDisplayEvent(list, searchFreeText) {
        if (searchFreeText) {
            var lc = _stringUtils.default.replaceAccents(searchFreeText.toLowerCase());
            _lodash.default.forEach(list, function (item) {
                if (_stringUtils.default.replaceAccents(item.eventName.toLowerCase()).includes(lc)) {
                    Ember.set(item, "toDisplay", true);
                } else {
                    Ember.set(item, "toDisplay", false);
                }
            });
        } else {
            toDisplayAll(list);
        }
    }

    function setDisplayCount(obj, list) {
        Ember.set(obj, "nbToDisplay", _lodash.default.filter(list, function (o) {
            return o.toDisplay;
        }).length);
    }

    function setDisplayWineries(obj, list) {
        Ember.set(obj, "wineries", _lodash.default.filter(list, function (o) {
            return o.toDisplay;
        }));
    }

    function setDisplayEvents(obj, list) {
        Ember.set(obj, "events", _lodash.default.filter(list, function (o) {
            return o.toDisplay;
        }));
    }

    function toDisplayAll(list) {
        _lodash.default.forEach(list, function (item) {
            Ember.set(item, "toDisplay", true);
        });
    }

    function formatFilter(filters) {
        if (filters) {
            return (0, _npmAppendQuery.default)("", {
                "countryWebPath": filters.country,
                "regionWebPath": filters.region,
                "cepageWebPath": filters.grape,
                "lieuDitWebPath": filters.place,
                "appellationWebPath": filters.appellationWebPath,
                "color": filters.color,
                "language": filters.language,
                "webpath": filters.webpath,
                "webPath": filters.webPath,
                "limit": filters.limit,
                "offset": filters.offset
            }, { removeNull: true });
        }
        return "";
    }

    function formatCepageNamesAndPicture(item) {
        //TODO group in a function common code with show.js
        item.cepages = item.cepageNames ? item.cepageNames.split(',') : [];
        item.imageArrayIds = item.pictureIds ? item.pictureIds.split(',') : [];
        item.firstPicture = item.pictureIds ? item.pictureIds.split(',')[0] : "";
    }

    function prepareConservationProcessing(data) {
        return {
            durationMax: data.conservationProcessingDurationMax,
            durationMin: data.conservationProcessingDurationMin,
            durationUnit: data.conservationProcessingDurationUnit,
            temperatureMax: data.conservationProcessingTemperatureMax,
            temperatureMin: data.conservationProcessingTemperatureMin,
            typeName: data.conservationProcessionTypeName,
            sequenceOrder: data.sequenceOrder,
            vatMaterial: data.vatMaterial,
            vatName: data.vatName
        };
    }
});