define('awesome2/adapters/color', ['exports', 'ember-data', 'awesome2/utils/common-utils', 'awesome2/adapters/base', 'awesome2/config/environment'], function (exports, _emberData, _commonUtils, _base, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const { host, namespace } = _environment.default.DS;
    const baseUrl = host + "/" + namespace;

    exports.default = _base.default.extend({

        distinctColorPerAppellation: function () {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/DistinctColorPerAppellationIn');
        },

        distinctColorPerGrape: function () {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/DistinctColorPerGrapeIn');
        },

        distinctColorPerProduct: function () {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/DistinctColorPerProductIn');
        },

        findAll: function () {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/ColorInfoIn');
        }

    });
});