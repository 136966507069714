define('awesome2/controllers/purchase/index', ['exports', 'ember-local-storage', 'awesome2/adapters/purchase', 'awesome2/utils/purchase-utils', 'awesome2/utils/common-utils', 'lodash'], function (exports, _emberLocalStorage, _purchase, _purchaseUtils, _commonUtils, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        cart: (0, _emberLocalStorage.storageFor)("cart"),
        user: (0, _emberLocalStorage.storageFor)("user"),

        actions: {
            removeInPurchaseController(productItemPriceId, priceType, eventId) {
                let idComposite = (0, _purchaseUtils.getCompositeId)(productItemPriceId, priceType, eventId);
                let line = this.get("model.productLines").findBy('id', idComposite);
                let wineryId = line.get("wineryId");
                this.get("model.productLines").removeObject(line);
                let wpl = this.get("model.wineryProductLines").findBy('wineryId', wineryId);

                let line2 = wpl.get("productLines").findBy('id', idComposite);
                wpl.get("productLines").removeObject(line2);
                if (wpl.get("productLines").get("length") == 0) {
                    this.get("model.wineryProductLines").removeObject(wpl);
                }
                if (this.get("model.productLines").get("length") == 0) {
                    this.set("model.wineryProductLines", []);
                }
                let obj = this.get('cart').findBy('idComposite', idComposite);
                this.get('cart').removeObject(obj);
            },
            removeAllInPurchaseController() {
                this.get('cart').clear();
                this.set("model.productLines", []);
                this.set("model.wineryProductLines", []);
            },
            checkoutController(id) {
                let cart = this.get("cart");
                let adapter = _purchase.default.create();
                let userStorage = this.get("user");
                let email = "";
                let gender = "";
                let firstName = "";
                let lastName = "";
                if (userStorage && userStorage.get("login")) {
                    email = userStorage.get("login");
                    gender = userStorage.get("gender");
                    firstName = userStorage.get("firstName");
                    lastName = userStorage.get("lastName");
                }
                let userLanguage = (0, _commonUtils.getUserLanguage)();
                adapter.storePurchaseOrder(email, gender, firstName, lastName, userLanguage, JSON.stringify(cart.content)).then(data => {
                    let key = data.StorePurchaseOrderOut[0].key;
                    this.set("cart.poKey", key);
                    this.transitionToRoute('/purchase/checkout');
                });
            },
            //TODO check for cleaning
            processIdentificationController(id) {
                //TODO remove duplicate
                let cart = this.get("cart");
                let adapter = _purchase.default.create();
                let userStorage = this.get("user");
                let email = "";
                let gender = "";
                let firstName = "";
                let lastName = "";
                if (userStorage && userStorage.get("login")) {
                    email = userStorage.get("login");
                    gender = userStorage.get("gender");
                    firstName = userStorage.get("firstName");
                    lastName = userStorage.get("lastName");
                }
                let userLanguage = (0, _commonUtils.getUserLanguage)();
                adapter.storePurchaseOrder(email, gender, firstName, lastName, userLanguage, JSON.stringify(cart.content)).then(data => {
                    let key = data.StorePurchaseOrderOut[0].key;
                    this.set("cart.poKey", key);
                    this.transitionToRoute('/purchase/processIdentification');
                });
            }
        }

    });
});