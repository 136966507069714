define('awesome2/routes/wineries/index', ['exports', 'awesome2/routes/base', 'lodash', 'awesome2/utils/business-utils'], function (exports, _base, _lodash, _businessUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        titleToken: ['nav.wineries'],
        queryParams: {
            country: {
                refreshModel: true,
                replace: true
            },
            region: {
                refreshModel: true,
                replace: true
            },
            place: {
                refreshModel: true,
                replace: true
            },
            grape: {
                refreshModel: true,
                replace: true
            },
            appellation: {
                refreshModel: true,
                replace: true
            },
            searchFreeText: {
                replace: true
            }
        },
        wineryService: Ember.inject.service("winery"),
        model(params) {
            return this.get('wineryService').findAll(params).then(function (data) {
                _lodash.default.each(data.WineryPublicOut, function (item) {
                    (0, _businessUtils.prepareWinery)(item);
                });

                (0, _businessUtils.toDisplayDomain)(data.WineryPublicOut, params.searchFreeText);
                (0, _businessUtils.setDisplayWineries)(data, data.WineryPublicOut);
                (0, _businessUtils.setDisplayCount)(data, data.WineryPublicOut);
                return data;
            }).catch(function (error) {
                console.log("error = " + error);
            });
        },
        setupController(controller, models) {
            this._super(...arguments);
        }
    });
});