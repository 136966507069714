define('awesome2/helpers/mail-to', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.mailTo = mailTo;
  function mailTo(params /*, hash*/) {
    let [emailAddress, label] = params;
    let labelValue = label ? label : emailAddress;
    var link = '<a href="mailto:' + emailAddress + '">' + labelValue + '</a>';
    return Ember.String.htmlSafe(link);
  }

  exports.default = Ember.Helper.helper(mailTo);
});