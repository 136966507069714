define('awesome2/routes/description', ['exports', 'awesome2/routes/base', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _base, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend(_authenticatedRouteMixin.default, {
        authenticationRoute: "/accounts/login",
        model() {
            return this.store.createRecord('description');
        }
    });
});