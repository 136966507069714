define('awesome2/components/snippet/li-filter-tag-snippet', ['exports', 'awesome2/utils/common-utils'], function (exports, _commonUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        styleHighlight: false,
        dataType: null,
        dataValueWebPath: null,
        /*
        tagClass : computed ('styleHighlight', function() {
            return this.get("styleHighlight")?"filter-tag w-font-bold":"filter-tag";
        }),
        badgeClass : computed ('styleHighlight', function() {
            return this.get("styleHighlight")?"label-success":"label-default";
        }),
        backgroundClass : computed ('styleHighlight', function() {
            return this.get("styleHighlight")?"w-background-gray":"";
        }),
        */
        countryFlag: Ember.computed('dataType', 'dataValueWebPath', function () {
            if (this.get("dataType") == 'wineryCountry') {
                return (0, _commonUtils.getFlagIcon)((0, _commonUtils.toWebPath)(this.get('dataValueWebPath')));
            }
        }),
        buttonClass: Ember.computed('styleHighlight', 'dataType', function () {
            let styleHighlight = this.get("styleHighlight");
            let dataType = this.get("dataType");
            let ret = dataType == "wineryCountry" ? "btn btn-warning stat-btn" : "btn btn-primary stat-btn";
            ret = styleHighlight ? ret + " w-bold active" : ret;
            return ret;
        }),
        filterAction: 'addProductFilter',
        actions: {
            forwardActionFilter(event) {
                this.sendAction("forwardActionFilter", event);
            }
        }
    });
});