define('awesome2/adapters/grape', ['exports', 'ember-data', 'awesome2/utils/common-utils', 'awesome2/utils/business-utils', 'awesome2/adapters/base', 'awesome2/config/environment'], function (exports, _emberData, _commonUtils, _businessUtils, _base, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const { host, namespace } = _environment.default.DS;
    const baseUrl = host + "/" + namespace;

    exports.default = _base.default.extend({

        availableGrapesForRegion: function (regionId) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/ProposedCepageForRegionIn?regionId=' + regionId);
        },

        find: function (webPath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/CepageDescriptionIn?webPath=' + webPath);
        },

        findAll: function (filters) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/CepageDescriptionIn' + (0, _businessUtils.formatFilter)(filters));
        },

        findAllWithFilter: function (filter) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/CepageDescriptionIn' + (0, _businessUtils.formatFilter)(filters));
        }

    });
});