define("awesome2/helpers/image-format", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.imageFormat = imageFormat;
  function imageFormat(params /*, hash*/) {
    let [image, format] = params;
    //if format
    if (format) {
      //TODO unit test
      var start = image.substr(0, image.lastIndexOf("."));
      var ext = image.substr(image.lastIndexOf(".") + 1);
      return start + "." + format + "." + ext;
    }
    return image;
  }

  exports.default = Ember.Helper.helper(imageFormat);
});