define('awesome2/components/search/inner-search-country-filter', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        countries: null,

        actions: {
            addFilter(event) {
                var value = event.target.getAttribute("data-value");
                //TUTO-DDAU-bubble-up-component(1-component-child-js)
                this.sendAction("sendCountry", value);
            }
        }
    });
});