define('awesome2/controllers/events/wineries/scan', ['exports', 'awesome2/utils/notification-utils'], function (exports, _notificationUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        areTermsAndConditionsAccepted: false,
        clientName: null,
        clientEmail: null,
        isValidClientName: Ember.computed.gte('clientName.length', 2),
        isValidClientEmail: Ember.computed.match('clientEmail', /^.+@.+\..+$/),

        isDisabled: Ember.computed('areTermsAndConditionsAccepted', 'isValidClientName', 'isValidClientEmail', function () {
            return !(this.get("areTermsAndConditionsAccepted") && this.get('isValidClientName') && this.get('isValidClientEmail'));
        }),

        actions: {
            save() {
                (0, _notificationUtils.notifySuccess)(this, "notification.save-customer-info");
            },
            shareInfo() {
                (0, _notificationUtils.notifySuccess)(this, "notification.share-customer-info");
            }
        }
    });
});