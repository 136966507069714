define('awesome2/services/color', ['exports', 'awesome2/adapters/color', 'awesome2/utils/common-utils'], function (exports, _color, _commonUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        //distinctColorPerAppellation
        distinctColorPerAppellation() {
            var adapter = _color.default.create();
            return adapter.distinctColorPerAppellation();
        },
        distinctColorPerGrape() {
            var adapter = _color.default.create();
            return adapter.distinctColorPerGrape();
        },
        distinctColorPerProduct() {
            var adapter = _color.default.create();
            return adapter.distinctColorPerProduct();
        },
        store() {
            (0, _commonUtils.getColorsInfo)();
        }
    } /*
      getColorInfo() {
          var adapter = ColorAdapter.create(); 
          return adapter.getColorInfo();
      },
      */
    );
});