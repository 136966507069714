define("awesome2/helpers/i18n-default", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.i18nDefault = i18nDefault;
  function i18nDefault(params /*, hash*/) {
    let [i18n, defaultValue] = params;
    if (i18n && !i18n.string && i18n.startsWith("Missing translation")) return defaultValue;else return i18n;
  }

  exports.default = Ember.Helper.helper(i18nDefault);
});