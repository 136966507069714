define('awesome2/services/grape', ['exports', 'awesome2/adapters/grape', 'awesome2/adapters/appellation', 'awesome2/adapters/winery', 'awesome2/adapters/region', 'awesome2/utils/winery-utils', 'awesome2/utils/business-utils', 'awesome2/utils/product-utils', 'awesome2/utils/common-utils', 'lodash'], function (exports, _grape, _appellation, _winery, _region, _wineryUtils, _businessUtils, _productUtils, _commonUtils, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        availableGrapesForRegion(regionId) {
            var adapter = _grape.default.create();
            return adapter.availableGrapesForRegion(regionId);
        },
        find(grapeName) {
            var adapter = _grape.default.create();
            return adapter.find(grapeName);
        },
        findByWebPath(grapeName) {
            var adapter = _grape.default.create();
            return adapter.find(grapeName);
        },
        // overview
        findOverview(webPath) {
            var adapter = _grape.default.create();
            var grapePromise = adapter.find(webPath).then(function (data) {
                return (0, _productUtils.prepareGrape)(data.CepageDescriptionOut[0]);
            });
            var appellationAdapter = _appellation.default.create();
            var appellationPromise = appellationAdapter.findByGrapeWebPath(webPath).then(function (data) {
                _lodash.default.each(data.AppelationBadgeOut, function (item) {
                    (0, _businessUtils.convertComposition)(item);
                });
                return data.AppelationBadgeOut;
            });
            var wineryAdapter = _winery.default.create();
            var wineryPromise = wineryAdapter.findByGrapeWebPath(webPath).then(function (data) {
                _lodash.default.each(data.WineryPublicOut, function (item) {
                    (0, _businessUtils.prepareWinery)(item);
                });
                return data.WineryPublicOut;
            });
            var regionAdapter = _region.default.create();
            var regionPromise = regionAdapter.findByGrapeWebPath(webPath).then(function (data) {
                _lodash.default.each(data.RegionBadgeOut, item => {
                    (0, _wineryUtils.prepareRegion)(item);
                });
                return data.RegionBadgeOut;
            });
            var promises = {
                grape: grapePromise,
                appellations: appellationPromise,
                wineries: wineryPromise,
                regions: regionPromise
            };
            return Ember.RSVP.hash(promises).then(function (data) {
                return data;
            });
        },
        findAll() {
            var adapter = _grape.default.create();
            return adapter.findAll();
        },
        store() {
            (0, _commonUtils.getGrapesInfo)();
        }
    });
});