define('awesome2/services/appellation', ['exports', 'awesome2/adapters/appellation', 'awesome2/adapters/winery', 'awesome2/adapters/place', 'awesome2/utils/business-utils', 'awesome2/utils/product-utils', 'lodash'], function (exports, _appellation, _winery, _place, _businessUtils, _productUtils, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        // abstract all + filter
        findAll(filters) {
            var adapter = _appellation.default.create();
            return adapter.findAll(filters);
        },
        //abstract autonomous
        find(webPath, language) {
            var adapter = _appellation.default.create();
            return adapter.find(webPath, language); //TODO replace by find
        },
        // overview
        findOverview(webPath, language) {
            var adapter = _appellation.default.create();
            var appellationPromise = adapter.find(webPath, language).then(function (data) {
                var item = data.AppelationBadgeOut[0];
                (0, _businessUtils.convertComposition)(item);
                (0, _productUtils.prepareAromas)(item);
                return item;
            });
            var appellationConservationPromise = adapter.findConservationMethodByAppellationWebPath(webPath).then(function (data) {
                return prepareAppelationConservations(data.AppelationConservationBadgeOut);
            });
            var wineryAdapter = _winery.default.create();
            var wineryPromise = wineryAdapter.findByAppellationWebPath(webPath) //winery_id webpath
            .then(function (data) {
                return (0, _businessUtils.prepareWineries)(data.WineryPublicOut);
            });

            //var wineryAdapter = WineryAdapter.create();
            //TODO create adapter based on appellation -> wineries

            var placeAdapter = _place.default.create();
            var placePromise = placeAdapter.findByAppellationWebPath(webPath).then(function (data) {
                return data.LieuDitBadgeOut;
            });

            var promises = {
                appellation: appellationPromise,
                conservationMethod: appellationConservationPromise,
                places: placePromise,
                wineries: wineryPromise
            };
            return Ember.RSVP.hash(promises).then(function (data) {
                return data;
            });
        },
        // abstract child (= all + custom filter by parent web path)
        findByRegionWebPath(regionWebPath) {
            var adapter = _appellation.default.create();
            return adapter.findByRegionWebPath(regionWebPath);
        },
        findByCountryWebPath(countryWebPath) {
            var adapter = _appellation.default.create();
            return adapter.findByCountryWebPath(countryWebPath);
        }
    });


    function prepareAppelationConservations(data) {
        if (data.length > 0) {
            let conservationMethod = {
                countryId: data[0].conservationMethodCountryId,
                description: data[0].conservationMethodDescription,
                durationMax: data[0].conservationMethodDurationMax,
                durationMin: data[0].conservationMethodDurationMin,
                durationUnit: data[0].conservationMethodDurationUnit,
                name: data[0].conservationMethodName,
                conservationProcessings: []
            };
            _lodash.default.each(data, function (item) {
                let conservationProcessing = (0, _businessUtils.prepareConservationProcessing)(item);
                conservationMethod.conservationProcessings.push(conservationProcessing);
            });
            return conservationMethod;
        }
    }
});