define('awesome2/adapters/vintage', ['exports', 'ember-data', 'awesome2/utils/common-utils', 'awesome2/adapters/base', 'awesome2/config/environment'], function (exports, _emberData, _commonUtils, _base, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const { host, namespace } = _environment.default.DS;
    const baseUrl = host + "/" + namespace;

    exports.default = _base.default.extend({

        //TODO REMOVE
        findByWineryWebPathAndProductWebPath: function (wineryWebPath, productWebPath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/ProductanditemlistIn?wineryWebPath=' + wineryWebPath + '&productWebPath=' + productWebPath); //TODO format filters
        },

        //TODO REMOVE replace by product adapter :
        //findProductItemPriceByWineryIdAndProductItemPriceId for home price
        //findProductItemPriceByEventIdAndProductItemPriceId for event price
        findByProductItemId: function (productItemId) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/ProductanditemlistIn?productItemPriceId=' + productItemId);
        }
    });
});