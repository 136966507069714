define('awesome2/components/card/grape/grape-card-abstract', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        autonomous: null,
        grape: null,
        grapeWebPath: null,

        grapeService: Ember.inject.service("grape"),
        didInsertElement() {
            this._super(...arguments);
            if (this.get('autonomous') && this.get('grapeWebPath')) {
                this.get('grapeService').findByWebPath(this.get("grapeWebPath")).then(data => {
                    //TODO replace by webpath
                    this.set('data', data.CepageDescriptionOut[0]);
                });
            }
        }

    });
});