define('awesome2/authenticators/captcha', ['exports', 'ember-simple-auth/authenticators/base', 'awesome2/config/environment', 'ember-local-storage'], function (exports, _base, _environment, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { host, namespace } = _environment.default.DS;
  const baseUrl = host + "/" + namespace;

  const captchaValidationFormUrl = baseUrl + "/contact";

  const { RSVP: { Promise }, $: { ajax }, run } = Ember;

  exports.default = _base.default.extend({
    tokenEndpoint: `${baseUrl}/data/jwt/auth`, //OK
    tokenJwt: (0, _emberLocalStorage.storageFor)("tokenJwt"),
    restore(data) {
      return new Promise((resolve, reject) => {
        if (!Ember.isEmpty(data.token)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },
    authenticate(data) {
      return new Promise((resolve, reject) => {
        fetch(captchaValidationFormUrl, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          mode: 'cors', // no-cors, *cors, same-origin
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          //credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          //redirect: 'follow', // manual, *follow, error
          //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(data) // body data type must match "Content-Type" header
        }).then(function (response) {
          return response.json();
        }).then(function (data) {
          run(() => {
            resolve({
              result: data,
              userInfo: 'todo' //TODO Add userInfo
            });
          });
        });
      });
    },
    invalidate(data) {
      return Promise.resolve(data);
    }
  });
});