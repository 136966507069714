define('awesome2/components/nav-bar', ['exports', 'lodash', 'awesome2/utils/common-utils', 'ember-local-storage', 'awesome2/config/environment', 'awesome2/utils/ember-bootstrap/dom'], function (exports, _lodash, _commonUtils, _emberLocalStorage, _environment, _dom) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const { rootUrl } = _environment.default.USER_MANAGEMENT_URL;

    //import config from '../config/environment';
    //const { host, namespace } = config.DS;

    const userManagementUrl = rootUrl + '/userfrosting';

    exports.default = Ember.Component.extend({

        router: Ember.inject.service(),
        availableTranslation: {},
        i18n: Ember.inject.service(),
        language: Ember.inject.service(),
        authManager: Ember.inject.service('session'),

        cart: (0, _emberLocalStorage.storageFor)("cart"),
        user: (0, _emberLocalStorage.storageFor)("user"),
        nbOfItemsInCart: Ember.computed('cart.[]', function () {
            return this.get("cart.content.length");
        }),
        hasShoppingCart: Ember.computed('nbOfItemsInCart', function () {
            return this.get("nbOfItemsInCart") > 0;
        }),
        isLanguageDdlDisplayed: false,
        isUserDdlDisplayed: false,
        isAdminDdlDisplayed: false,
        isOpen: false,
        isCollapse: Ember.computed('isOpen', function () {
            return !this.get("isOpen");
        }),

        userRegistrationUrl: userManagementUrl + "/account/register",

        userPasswordForgottenUrl: userManagementUrl + "/account/forgot-password",

        didInsertElement() {
            //TUTO-JS interpolation alert(`Name is ${this.get('name')}`);
            this._super(...arguments);
            let loc = (0, _commonUtils.getUserI18n)();

            //TUTO component load data 
            //TUTO REF https://emberigniter.com/render-promise-before-it-resolves/
            this.get('language').availableLanguages().then(data => {
                //TODO add active where loc=data.local in lodash
                //var filters = _.filter(data.TranslationLanguageOut, {'locale' : loc});
                _lodash.default.each(data.TranslationLanguageOut, function (item) {
                    if (item.locale == loc) {
                        item.class = 'active';
                    }
                });

                this.set('availableTranslation', data);
            });
            /**/
            $('.rollupback').on('click', function () {
                $(".navbar-toggle").click();
            });
        },
        //     /**
        //  * @property toggleElement
        //  * @private
        //  */
        //     toggleElement: computed('toggle', function () {
        //         return typeof FastBoot === 'undefined' ? this.get('toggle.element') || null : null;
        //     }),

        //     /**
        //      * Reference to the child toggle (Toggle or Button)
        //      *
        //      * @property toggle
        //      * @private
        //      */
        //     toggle: null,

        //     /**
        //      * Action is called when dropdown is about to be shown
        //      *
        //      * @event onShow
        //      * @param {*} value
        //      * @public
        //      */
        //     onShow(value) { }, // eslint-disable-line no-unused-vars

        //     /**
        //      * Action is called when dropdown is about to be hidden
        //      *
        //      * @event onHide
        //      * @param {*} value
        //      * @public
        //      */
        //     onHide(value) { }, // eslint-disable-line no-unused-vars

        actions: {

            menuClick: function (scope) {
                if ("language" == scope) {
                    resetDdlSafe(this, "isLanguageDdlDisplayed");
                    let isLanguageDdlDisplayed = this.get("isLanguageDdlDisplayed");
                    this.set("isLanguageDdlDisplayed", !isLanguageDdlDisplayed);
                } else if ("user" == scope) {
                    resetDdlSafe(this, "isUserDdlDisplayed");
                    let isUserDdlDisplayed = this.get("isUserDdlDisplayed");
                    this.set("isUserDdlDisplayed", !isUserDdlDisplayed);
                } else if ("admin" == scope) {
                    resetDdlSafe(this, 'isAdminDdlDisplayed');
                    this.set("isAdminDdlDisplayed", !this.get("isAdminDdlDisplayed"));
                }
            },

            invalidateSession() {
                this.get('authManager').invalidate();
                this.get('user').clear();
            },

            toggleDdl: function (scope) {
                if ("language" == scope) {
                    if (this.get('isLanguageDdlDisplayed')) {
                        this.send('closeDdl', scope);
                    } else {
                        this.send('openDdl', scope);
                    }
                } else if ("user" === scope) {
                    if (this.get('isUserDdlDisplayed')) {
                        this.send('closeDdl', scope);
                    } else {
                        this.send('openDdl', scope);
                    }
                }
            },

            openDdl(scope) {
                if ("language" === scope) {
                    this.set('isLanguageDdlDisplayed', true);
                    this.addDdlClickListener();
                } else if ("user" === scope) {
                    this.set('isUserDdlDisplayed', true);
                    this.addDdlClickListener();
                } else if ("admin" === scope) {
                    this.set('isAdminDdlDisplayed', true);
                    this.addDdlClickListener();
                }
            },

            closeDdl(scope) {
                if (scope == undefined) {
                    this.set('isLanguageDdlDisplayed', false);
                    this.set('isUserDdlDisplayed', false);
                    this.set('isAdminDdlDisplayed', false);
                    this.removeDdlClickListener();
                } else if ("language" === scope) {
                    this.set('isLanguageDdlDisplayed', false);
                    this.removeDdlClickListener(); //due to jquery double sending
                } else if ("user" === scope) {
                    this.set('isUserDdlDisplayed', false);
                    this.removeDdlClickListener(); //due to jquery double sending
                } else if ("admin" === scope) {
                    this.set('isAdminDdlDisplayed', false);
                    this.removeDdlClickListener(); //due to jquery double sending
                }
            },

            toggleDropdownMenu() {
                if (this.get('isOpen')) {
                    this.send('closeDropdown');
                } else {
                    this.send('openDropdown');
                }
            },

            openDropdown() {
                this.set('isOpen', true);
                this.addClickListener();
                //this.get('onShow')();
            },

            closeDropdown() {
                this.set('isOpen', false);
                this.removeClickListener(); //due to jquery double sending
                //this.get('onHide')();
            }

        },

        addDdlClickListener() {
            if (!this.clickDdlListener) {
                this.clickDdlListener = Ember.run.bind(this, this.closeDdlOnClickHandler);
                document.addEventListener('click', this.clickDdlListener, true);
            }
        },

        removeDdlClickListener() {
            if (this.clickDdlListener) {
                document.removeEventListener('click', this.clickDdlListener, true);
                this.clickDdlListener = null;
            }
        },

        addClickListener() {
            if (!this.clickListener) {
                this.clickListener = Ember.run.bind(this, this.closeOnClickHandler);
                document.addEventListener('click', this.clickListener, true);
            }
        },

        removeClickListener() {
            if (this.clickListener) {
                document.removeEventListener('click', this.clickListener, true);
                this.clickListener = null;
            }
        },

        willDestroyElement() {
            this._super(...arguments);
            this.removeClickListener();
            this.removeDdlClickListener();
        },

        /**
         * Handler for click events to close the dropdown !! other than button (ex: for click on menu link or screen)
         *
         * @method closeOnClickHandler
         * @param e
         * @protected
         */
        closeOnClickHandler(e) {
            let isOpen = this.get("isOpen");
            let { target } = e;
            //let { toggleElement, menuElement } = this.getProperties('toggleElement', 'menuElement');

            //TODO adapt from the generic solution with when submenu
            // if (!this.get('isDestroyed')
            //     && (toggleElement && !toggleElement.contains(target))
            //     && ((menuElement && !menuElement.contains(target)) || this.get('closeOnMenuClick'))) {
            // }
            let id = target.getAttribute("id");
            let id2 = target.parentElement.getAttribute("id");
            this.set("isOpen", isOpen); //for some obscure reason is it resetted ... by getting target.getAttribute
            if (id != 'dropdown-menu' && id2 != 'dropdown-menu') {
                this.send('closeDropdown');
            }
        },
        closeDdlOnClickHandler(e) {
            //very strange behavior in the value of isLanguageDdlDisplayed between debug and none-debug
            let isLanguageDdlDisplayed = this.get("isLanguageDdlDisplayed");
            let isAdminDdlDisplayed = this.get("isAdminDdlDisplayed");
            let isUserDdlDisplayed = this.get("isUserDdlDisplayed");
            let { target } = e;
            let id = target.getAttribute("id");
            let id2 = target.parentElement.getAttribute("id");
            //for some obscure reason is it resetted ... by getting target.getAttribute
            this.set("isLanguageDdlDisplayed", isLanguageDdlDisplayed);
            this.set("isAdminDdlDisplayed", isAdminDdlDisplayed);
            this.set("isUserDdlDisplayed", isUserDdlDisplayed);
            if (id != 'ddl-language' && id2 != 'ddl-language' && id != 'ddl-user' && id2 != 'ddl-user') {
                this.send('closeDdl');
            }
        }
    });


    // function resetDdl(that) {
    //     resetDdlSafe(that, null);
    // }

    function resetDdlSafe(that, ddl) {
        if ("isLanguageDdlDisplayed" != ddl) {
            that.set("isLanguageDdlDisplayed", false);
        }
        if ("isUserDdlDisplayed" != ddl) {
            that.set("isUserDdlDisplayed", false);
        }
        if ("isAdminDdlDisplayed" != ddl) {
            that.set("isAdminDdlDisplayed", false);
        }
    }
});