define('awesome2/services/language-service', ['exports', 'awesome2/adapters/language'], function (exports, _language) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    availableLanguages() {
      var adapter = _language.default.create();
      // this simulates fetching data across the network
      return new Ember.RSVP.Promise(function (resolve) {
        adapter.availableTranslation();
      });
    }

  });
});