define('awesome2/adapters/winery', ['exports', 'ember-data', 'awesome2/utils/common-utils', 'awesome2/adapters/base', 'awesome2/config/environment', 'awesome2/utils/business-utils'], function (exports, _emberData, _commonUtils, _base, _environment, _businessUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const { host, namespace } = _environment.default.DS;
    const baseUrl = host + "/" + namespace;

    exports.default = _base.default.extend({

        findAll: function (filters) {
            //limit the number of results
            if (filters.limit == undefined) {
                filters.limit = 60;
            }
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryPublicIn' + (0, _businessUtils.formatFilter)(filters));
        },

        find: function (id) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryPublicIn?wineryId=' + id);
        },

        findByWebpath: function (webpath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryPublicIn?webPath=' + webpath);
        },

        findOpeningHoursByWebpath: function (webpath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryTimetableInfoIn?wineryWebPath=' + webpath);
        },

        findShippingCostByWineryWebpath: function (webpath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryShippingCostIn?wineryWebPath=' + webpath);
        },

        findByRegionWebPath: function (regionWebpath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryPublicIn?regionWebPath=' + regionWebpath);
        },

        findByGradeWebPath: function (gradeWebpath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryPublicIn?cepageWebPath=' + gradeWebpath); //TODO in SDD
        },

        findByGrapeWebPath: function (grapeWebpath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryPublicIn?cepageWebPath=' + grapeWebpath); //TODO in SDD
        },

        findByPlaceWebPath: function (placeWebpath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryPublicIn?lieuDitWebPath=' + placeWebpath); //TODO in SDD
        },

        findByAppellationWebPath: function (appellationWebPath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryPublicIn?appellationWebPath=' + appellationWebPath); //TODO in SDD
        },

        findByEventWebPath: function (eventWebPath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryPublicIn?eventWebPath=' + eventWebPath);
        },

        wineryCountrySummary: function (params) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryCountrySummaryIn');
        },

        wineryRegionSummary: function (params) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/WineryRegionSummaryIn');
        }
    });
});