define('awesome2/utils/purchase-utils', ['exports', 'lodash'], function (exports, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.convertProductLinesToWineryProductLines = convertProductLinesToWineryProductLines;
    exports.convertProductLinesToWineryProductLines2 = convertProductLinesToWineryProductLines2;
    exports.addInCart = addInCart;
    exports.getCompositeId = getCompositeId;
    exports.convertEventWinePriceItemToProductLine = convertEventWinePriceItemToProductLine;
    function convertProductLinesToWineryProductLines(data, that) {
        //https://stackoverflow.com/questions/44565520/lodash-group-and-restructure-the-json-object-using-lodash
        let list = _lodash.default.chain(data).groupBy('__wineryWebPath').orderBy(group => group.__wineryWebPath).toPairs().map(function (pair) {
            return _lodash.default.zipObject(['wineryWebPath', 'productLines'], pair);
        }).value();
        let wineryProductLines = [];
        _lodash.default.forEach(list, i => {
            i.productLines = _lodash.default.orderBy(i.productLines, ["creationDate"], ["desc"]);
            let p = i.productLines[0];
            let wp = that.store.createRecord("winery-purchase");
            let date = _lodash.default.min(_lodash.default.get(i.productLines, "creationTime"));
            wp.set("winery", p.__winery);
            wp.set("wineryId", p.__wineryId);
            wp.set("wineryWebPath", p.__wineryWebPath);
            wp.set("hasWineryLogo", p.__hasWineryLogo);
            wp.set("productLines", i.productLines);
            wineryProductLines.push(wp);
        });
        return wineryProductLines;
    }

    function convertProductLinesToWineryProductLines2(data, that) {
        // https://stackoverflow.com/questions/44565520/lodash-group-and-restructure-the-json-object-using-lodash
        let list = _lodash.default.chain(data).groupBy(['__key']) //'productPrice'
        .orderBy(group => group.__key).toPairs().map(function (pair) {
            return _lodash.default.zipObject(['__key', 'productLines'], pair);
        }).value();
        // lodash does not seem to perform well with ember data   
        let wineryProductLines = [];
        _lodash.default.forEach(list, i => {
            i.productLines = _lodash.default.orderBy(i.productLines, ["creationDate"], ["desc"]);
            let p = i.productLines[0];
            let wp = that.store.createRecord("winery-purchase");
            let date = _lodash.default.min(_lodash.default.get(i.productLines, "creationTime"));
            wp.set("winery", p.__winery);
            wp.set("wineryId", p.__wineryId);
            wp.set("wineryWebPath", p.__wineryWebPath);
            wp.set("hasWineryLogo", p.__hasWineryLogo);
            wp.set("productLines", i.productLines);
            wineryProductLines.push(wp);
        });

        /*
            _.forEach(data, i => {
                i.productLines = _.orderBy(i.productLines, ["creationDate"], ["desc"])
                let p = i.productLines[0];
                let wp = that.store.createRecord("winery-purchase");
                let date = _.min(_.get(i.productLines, "creationTime"));
                wp.set("winery",p.__winery);
                wp.set("wineryId",p.__wineryId);
                wp.set("wineryWebPath",p.__wineryWebPath);
                wp.set("hasWineryLogo",p.__hasWineryLogo);
                wp.set("productLines",i.productLines);
                wineryProductLines.push(wp);
        
            });
            */

        return wineryProductLines;
    }

    function addInCart(that, productItemPriceId, quantity, productId, wineryId, productWebPath, wineryWebPath, priceType, eventId, displayPrice, currencyName) {
        let cart = that.get("cart");
        let idComposite = getCompositeId(productItemPriceId, priceType, eventId);
        var obj = cart.findBy('idComposite', idComposite);
        cart.removeObject(obj);
        cart.addObject({
            id: productItemPriceId,
            idComposite: idComposite,
            productItemPriceId: productItemPriceId,
            productId: productId,
            wineryId: wineryId,
            quantity: quantity,
            creationTime: Date.now(),
            productWebPath: productWebPath,
            wineryWebPath: wineryWebPath,
            priceType: priceType,
            eventId: eventId,
            displayPrice: displayPrice,
            currencyName: currencyName
        });
    }

    function getCompositeId(productItemPriceId, priceType, eventId) {
        let key = productItemPriceId + "-" + priceType;
        return eventId ? key + "-" + eventId : key;
    }

    function convertEventWinePriceItemToProductLine(pi, data, productLine) {
        productLine.set("productId", pi.productItemPriceId);
        productLine.set("productItemPriceId", pi.productItemPriceId);
        productLine.set("product", pi.productName);
        productLine.set("quantity", data.quantity);
        productLine.set("productFormat", pi.wineRecipientVolumeInMl);
        productLine.set("productFormatQuantity", pi.quantity);
        productLine.set("productYear", pi.year);

        //TODO pi.price is whether home or event presale or event price
        if (data.priceType) {
            if (data.priceType == "winery") {
                productLine.set("productPrice", pi.homePrice);
            }
            if (data.eventId) {
                if (data.priceType == "event-presale") {
                    productLine.set("productPrice", pi.eventPreOrderPrice);
                }
                if (data.priceType == "event") {
                    productLine.set("productPrice", pi.eventPrice);
                }
            }
        } else {
            productLine.set("productPrice", pi.homePrice);
        }
        let idComposite = getCompositeId(pi.productItemPriceId, data.priceType, pi.eventId);
        productLine.set("idComposite", idComposite);
        productLine.set("id", idComposite);

        productLine.set("priceType", data.priceType);
        productLine.set("colorName", pi.colorName);
        productLine.set("colorRgb", pi.colorRgb);
        productLine.set("eventId", pi.eventId);
        productLine.set("eventName", pi.eventName);
        productLine.set("eventWebPath", pi.eventWebPath);
        productLine.set("appellationClassificationAcronym", pi.appellationClassificationAcronym);
        productLine.set("appellationName", pi.appellationName);

        productLine.set("productWebPath", pi.productWebPath);
        productLine.set("wineryWebPath", pi.wineryWebPath);
        productLine.set("__wwp", pi.wineryWebPath); //keep wwp as an none productLine property so that it is used as plain json property
        productLine.__wineryWebPath = pi.wineryWebPath; //keep wwp as an none productLine property so that it is used as plain json property


        //productLine.__eventId = pi.eventId;
        productLine.set("__eventId", pi.eventId);
        productLine.set("__key", pi.wineryWebPath + "-" + pi.eventId);
        productLine.set("__productPrice", data.priceType);
        //TODO new field
        productLine.set("hasProductLogo", pi.hasProductLogo);
        productLine.set("hasWineryLogo", pi.hasWineryLogo);
        productLine.__hasWineryLogo = pi.hasWineryLogo;
        productLine.set("wineryId", pi.wineryId);
        productLine.__wineryId = pi.wineryId;
        productLine.set("winery", pi.winery);
        productLine.__winery = pi.winery;
        productLine.set("region", pi.region);
        productLine.set("detail", pi.detail);

        return productLine;
    }
});