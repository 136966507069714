define('awesome2/components/card/event/event-card-winery-participation', ['exports', 'awesome2/utils/business-utils', 'awesome2/config/environment', 'lodash'], function (exports, _businessUtils, _environment, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        autonomous: null,
        event: null,
        eventWebPath: null,
        participations: null,
        environment: _environment.default.ENVIRONMENT,
        eventService: Ember.inject.service("event"),

        participation: Ember.computed('participations', 'eventWebPath', function () {
            let participations = this.get("participations");
            let eventWebPath = this.get("eventWebPath");
            return _lodash.default.filter(participations, { eventWebPath: eventWebPath })[0];
        }),

        didInsertElement() {
            this._super(...arguments);
            if (this.get('autonomous') && this.get('eventWebPath')) {
                this.get('eventService').findByWebpath(this.get("eventWebPath")).then(data => {
                    this.set('data', (0, _businessUtils.prepareEvent)(data.EventInfoOut[0]));
                });
            }
        }

    });
});