define("awesome2/components/card/region/region-card-overview", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        //empty for model (filled by route)
        actions: {
            shiftTab(event) {
                var tab = event.currentTarget.getAttribute("data-tab");
                $("#" + tab).closest("li").addClass("active").siblings("li").removeClass("active");
                //$("#places").css("tab-pane fade in active");

                /*
                 $("#category").val(event.srcElement.innerText);
                 this.get("model").set("category",event.srcElement.innerText);
                 */
            }
        }
    });
});