define('awesome2/components/modal-request-plan', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    /*
      show: function() {
        this.$('.modal').modal().on('hidden.bs.modal', function() {
          this.sendAction('close');
        }.bind(this));
      }.on('didInsertElement'),
      */
    actions: {
      doSave(d, meta) {
        //d.reject(); 
        d.resolve();
      },
      doAfterClose() {
        // 
      },
      doClose(d) {
        if (confirm('Please confirm...')) {
          d.resolve();
        } else {
          d.reject();
        }
      }
    }
  });
});