define('awesome2/adapters/info', ['exports', 'ember-data', 'awesome2/utils/common-utils', 'awesome2/adapters/base', 'awesome2/config/environment'], function (exports, _emberData, _commonUtils, _base, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const { host, namespace } = _environment.default.DS;
    const baseUrl = host + "/" + namespace;

    const PATH = baseUrl + '/data/sdd/ImageAuthorIn';
    const PATH_IMAGE_INFO = baseUrl + '/data/sdd/ImageInfoIn';

    exports.default = _base.default.extend({

        findImageInfo(entityId, entityWebPath, displayOrder) {
            return (0, _commonUtils.fetchData)(PATH + formatFilter(entityId, entityWebPath, displayOrder));
        },

        findImagesInfo(whereEntity, whereEntityWebPath) {
            return (0, _commonUtils.fetchData)(PATH_IMAGE_INFO + formatFilterImagesInfo(whereEntity, whereEntityWebPath));
        }

    });


    function formatFilter(entityId, entityWebPath, displayOrder) {
        let ret = entityId ? 'entityId=' + entityId : '';
        ret += entityWebPath ? '&entityWebPath=' + entityWebPath : '';
        ret += displayOrder ? '&displayOrder=' + displayOrder : '';
        return '?' + ret;
    }

    function formatFilterImagesInfo(entity, entityWebPath) {
        //whereentity=whereregion&whereentity_web_path=alsace
        let ret = entity ? 'whereentity=' + entity : '';
        ret += entityWebPath ? '&whereentity_web_path=' + entityWebPath : '';
        return '?' + ret;
    }
});