define("awesome2/components/modal/event-request-entrance", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        modal: false,
        isInvitation: false,
        isPresaleEntranceFee: false,
        actions: {
            submit() {
                console.log("submit");
            }
        }
    });
});