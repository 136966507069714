define('awesome2/adapters/region', ['exports', 'ember-data', 'awesome2/utils/common-utils', 'awesome2/adapters/base', 'awesome2/config/environment'], function (exports, _emberData, _commonUtils, _base, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const { host, namespace } = _environment.default.DS;
    const baseUrl = host + "/" + namespace;

    exports.default = _base.default.extend({
        availableRegionsForCountry: function (countryId) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/RegionListIn?countryId=' + countryId);
        },
        findByCountryWebpath: function (countryWebpath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/RegionBadgeIn?countryWebPath=' + countryWebpath);
        },
        //TODO remove
        findByGradeWebPath: function (gradeWebPath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/RegionBadgeIn?gradeWebPath=' + gradeWebPath); //TODO
        },
        findByGrapeWebPath: function (grapeWebPath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/RegionBadgeIn?gradeWebPath=' + grapeWebPath); //TODO
        },
        find: function (webpath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/RegionBadgeIn?webPath=' + webpath);
        },
        findByName: function (name) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/RegionBadgeIn?name=' + name);
        }
    });
});