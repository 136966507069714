define('awesome2/components/card/winery/winery-card-overview', ['exports', 'awesome2/utils/common-utils', 'awesome2/mixins/component-base-mixin'], function (exports, _commonUtils, _componentBaseMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_componentBaseMixin.default, {

        tabProducts: 'active',
        tabProductItemPrice: '',
        tabEvent: '',
        origin: "",
        data: null,
        init() {
            this._super(...arguments);
            this.set("origin", (0, _commonUtils.getCurrentUrlEnding)());
            let data = this.get("data");
            if (data.products.length > 0) {
                this.set("tabProducts", 'active');
            } else if (data.productItemPrices.length > 0) {
                this.set("tabProductItemPrice", 'active');
            } else if (data.events.length > 0) {
                //this.set("tabEvent", 'active'); 
                //TODO fix presentation issue with no country found when autonomous load of event card
            }
        },
        actions: {
            setWineryOverviewFilter(filter) {
                this.sendAction("setWineryProductFilter", filter);
            }
        }

    });
});