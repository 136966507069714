define('awesome2/helpers/number-of-days-between', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.numberOfDaysBetween = numberOfDaysBetween;
  function numberOfDaysBetween(params /*, hash*/) {
    let [d1, d2] = params;
    var diff = Math.abs(d1.getTime() - d2.getTime());
    return diff / (1000 * 60 * 60 * 24);
  }

  exports.default = Ember.Helper.helper(numberOfDaysBetween);
});