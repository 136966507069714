define('awesome2/components/share/pinterest-share', ['exports', 'awesome2/components/share/share-button'], function (exports, _shareButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _shareButton.default.extend({
    //layout,
    shareURL: 'https://www.pinterest.com/pin/create/button/',
    classNames: ['btn-pinterest'],
    attributeBindings: ['titleName:title'],
    shareAltIntro: 'Share on pinterest ',

    click() {
      let url = this.get('shareURL');
      url += '?url=' + encodeURIComponent(this.getCurrentUrl());
      this.openSharePopup(url);
    }
  });
});