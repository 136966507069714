define('awesome2/components/share/pdf-share', ['exports', 'awesome2/components/share/share-button'], function (exports, _shareButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _shareButton.default.extend({
    shareURL: '',
    classNames: ['btn-pdf'],
    shareAltIntro: 'Open as PDF ',
    hashtags: '',

    click() {
      let urlProtocolIndex = this.getCurrentUrl().indexOf('//');
      let urlRoot = this.getCurrentUrl().indexOf('/', urlProtocolIndex + 2);
      let urlEnding = this.getCurrentUrl().substring(urlRoot + 1);
      let url = "https://winerylabs.com/artifact/data/" + urlEnding + ".pdf";
      this.openSharePopup(url);
    }
  });
});