define('awesome2/routes/purchase/overview', ['exports', 'awesome2/routes/base', 'ember-local-storage', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _base, _emberLocalStorage, _authenticatedRouteMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend(_authenticatedRouteMixin.default, {
        authenticationRoute: "/accounts/login",
        routeHistory: Ember.inject.service(),
        cart: (0, _emberLocalStorage.storageFor)("cart"),
        queryParams: {
            poKey: {
                replace: true
            }
        },

        model(params) {
            let cart = this.get("cart");
            let poKey = cart.poKey;
            cart.clear();
            return { poKey: poKey };
        },

        beforeModel(transition) {
            this.get("cart").clear();
            const currentRouteName = this.get('routeHistory.current'); // Returns the current route name.
            if ("purchase.checkout" != currentRouteName) {
                this.transitionTo("/purchase");
            }
        }
    });
});