define('awesome2/components/share/twitter-share', ['exports', 'awesome2/components/share/share-button'], function (exports, _shareButton) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _shareButton.default.extend({
    //    //"ember-social-share": "^0.3.0", in package.json
    shareURL: 'https://twitter.com/intent/tweet',
    classNames: ['btn-twitter'],
    //apparently attributeBindings 'titleName:title' cannot be set in ShareButton!! ??
    attributeBindings: ['titleName:title'],
    hashtags: '',
    click() {
      let url = this.get('shareURL');
      url += '?text=' + this.get('title');
      url += '&url=' + encodeURIComponent(this.getCurrentUrl());
      url += this.get('hashtags') ? '&hashtags=' + this.get('hashtags').split(" ").join("") : '';
      url += this.get('via') ? '&via=' + this.get('via') : '';

      this.openSharePopup(url);
    }
  });
});