define('awesome2/routes/wineries/products/product', ['exports', 'awesome2/routes/base', 'lodash'], function (exports, _base, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({
        wineryService: Ember.inject.service("winery"),
        productService: Ember.inject.service("product"),
        model(params) {
            //webpath, product_key
            //var data = this.get('wineryService').wineryDetailsAndProducts(params);
            var data = this.get('productService').findOverview(params.product_key, params.webpath); //inversed!!
            return data;
        },
        afterModel(model, transition) {
            this.set("titleToken", ['field.wine', model.product.product]);
        }
    });
});