define("awesome2/controllers/accounts/register", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    actions: {
      selectLanguage(language) {
        this.set("selectedLanguage", language);
        this.set("model.languageCode", language.code);
      }
    }
  });
});