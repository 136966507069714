define('awesome2/adapters/search', ['exports', 'ember-data', 'awesome2/utils/common-utils', 'awesome2/adapters/base', 'awesome2/config/environment'], function (exports, _emberData, _commonUtils, _base, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const { host, namespace } = _environment.default.DS;

    /**/

    const baseUrl = host + "/" + namespace;

    exports.default = _base.default.extend({
        //use ember data for find and homePageSuggestions since returned objects are the same
        find: function (freeText, filterString, languageCode) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/SearchFreetextIn?languageCode=' + languageCode + '&latitude=0&longitude=0&entityFilter=' + filterString + '&maxResults=5&match=' + freeText);
        },

        homePageSuggestions: function (languageCode, longitude, latitude) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/LastUpdatedEntitiesSuggestionIn?nbOfResult=15&languageCode=' + languageCode + '&latitude=0&longitude=0');
        }

    });
});