define('awesome2/components/search/winery-filters', ['exports', 'awesome2/utils/common-utils'], function (exports, _commonUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        actions: {
            addWineryFilter(event) {
                /*
                //let parentNode = event.target.parentNode;
                let parentNode = event.target;
                let value = parentNode.getAttribute("data-value");
                let type = parentNode.getAttribute("data-type");
                let filter = {
                    type : type,
                    value : value,
                }
                */
                let filter = (0, _commonUtils.extractFilterFromEvent)(event);
                this.sendAction("setWineryCardWineryFilter", filter);
            }
        }
    });
});