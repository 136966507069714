define('awesome2/components/order/purchase-order', ['exports', 'awesome2/mixins/component-base-mixin'], function (exports, _componentBaseMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_componentBaseMixin.default, {

        data: null,

        authManager: Ember.inject.service('session'),
        areTermsAndConditionsAccepted: false,
        isCheckoutDisabled: Ember.computed('areTermsAndConditionsAccepted', function () {
            return !this.get('areTermsAndConditionsAccepted');
        }),
        actions: {
            checkout(id) {
                //TODO store cart; gives back id and send to checkout to transition to page
                this.sendAction("checkout", id);
            },
            processIdentification(id) {
                //TODO store cart; gives back id and send to checkout to transition to page
                this.sendAction("processIdentification", id);
            },
            order(id) {
                this.sendAction("order", id);
            },
            remove(event) {
                this.sendAction("removeAll");
            },
            cancel(event) {
                this.sendAction("cancelOrder");
            },
            removeProductItemId(productItemPriceId, priceType, eventId) {
                this.sendAction("remove", productItemPriceId, priceType, eventId);
            }

        }
    });
});