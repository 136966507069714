define('awesome2/utils/captcha-utils', ['exports', 'awesome2/utils/common-utils', 'awesome2/config/environment'], function (exports, _commonUtils, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = captchaUtils;
  exports.displayCaptchaHtml = displayCaptchaHtml;
  exports.getCaptchaHtml = getCaptchaHtml;

  const { host, namespace } = _environment.default.DS;
  const baseUrl = host + "/" + namespace;

  const captchaEndpoint = baseUrl + "/simple-captcha-endpoint";
  const captchaValidationFormUrl = baseUrl + "/contact";
  const captchaStyleName = "basicCaptcha";

  function captchaUtils() {
    return true;
  }

  function displayCaptchaHtml(that) {
    getHtml(captchaEndpoint, captchaStyleName).then(function (captchaHtml) {
      captchaHtml = changeRelativeToAbsoluteUrls(captchaHtml);
      document.getElementById("botdetect-captcha-snippet").innerHTML = captchaHtml;
      let captchaId = document.getElementById('BDC_VCID_' + captchaStyleName).getAttribute("value");
      that.set("captchaSnippet", Ember.String.htmlSafe(captchaHtml));
      loadScriptIncludes(captchaId, captchaStyleName, that);
    });
  }

  function getCaptchaHtml() {
    getHtml(captchaEndpoint, captchaStyleName).then(function (captchaHtml) {
      captchaHtml = changeRelativeToAbsoluteUrls(captchaHtml);
      document.getElementById("botdetect-captcha-snippet").innerHTML = captchaHtml;
      return Ember.String.htmlSafe(captchaHtml);
      //loadScriptIncludes(captchaId, captchaStyleName, that);
    });
  }

  function getHtml(captchaEndpoint, captchaStyleName) {
    return (0, _commonUtils.fetchCaptcha)(captchaEndpoint + filterCaptchaOption({
      get: 'html',
      c: captchaStyleName
    }));
  };

  function filterCaptchaOption(options) {
    return "?get=html&c=" + options.c;
  }

  // ajax validate captcha on blur event and trigging the 
  // custom 'validatecaptcha' event to fire the validation result
  function _registerUserInputBlurValidation() {
    var instance = _getInstance();

    $('#' + instance.options.userInputID).on('blur', function () {
      var captchaCode = $.trim($(this).val());
      if (captchaCode.length === 0) {
        return;
      }

      var userInputID = this;
      _validateUnsafe(captchaCode, function (isCorrect) {
        if (!isCorrect) {
          instance.reloadImage();
        }
        $(userInputID).trigger('validatecaptcha', [isCorrect]);
      });
    });
  };

  // a custom of $.getScript(), which lets changing the options
  function _getScript(url, options) {
    options = $.extend({
      dataType: 'script',
      cache: false,
      url: url
    }, options || {});
    return $.ajax(options);
  };

  function getScript(url, options) {
    //use inject script
    //https://github.com/MinuteBase/ember-inject-script
    //here the script are just loaded by not executed
    //check with cors
    return (0, _commonUtils.fetchCaptchaScript)(url);
  };

  // load botdetect scripts and execute them
  function loadScriptIncludes(captchaId, captchaStyleName, that) {
    // var captchaId = document.getElementById('BDC_VCID_' + captchaStyleName);
    var scriptIncludeUrl = captchaEndpoint + '?get=script-include&c=' + captchaStyleName + '&t=' + captchaId + '&cs=2';
    getScript(scriptIncludeUrl).then(function () {
      //TODO setTimeout(onLoadScriptsSuccess(that), 200);
    });
  };

  function _loadScriptIncludes() {
    var captchaId = $('#BDC_VCID_' + captchaStyleName).val();
    var scriptIncludeUrl = settings.captchaEndpoint + '?get=script-include&c=' + captchaStyleName + '&t=' + captchaId + '&cs=2';
    _getScript(scriptIncludeUrl).done(function () {
      setTimeout(_onLoadScriptsSuccess, 200);
    });
  };

  // use user input blur validation if the input element has data-correct-captcha attribute
  function _useUserInputBlurValidation() {
    var instance = _getInstance();
    return $('#' + instance.options.userInputID).attr('data-correct-captcha') !== undefined;
  };

  function useUserInputBlurValidation(that) {
    return document.getElementById(that.userInputID).getAttribute('data-correct-captcha') !== undefined;
  };

  // fire the custom event when botdetect scripts are loaded
  function _onLoadScriptsSuccess() {
    if (_useUserInputBlurValidation()) {
      _registerUserInputBlurValidation();
    }
  }

  function onLoadScriptsSuccess(that) {
    if (useUserInputBlurValidation(that)) {
      registerUserInputBlurValidation(that);
    }
  }

  // get captcha endpoint handler from configued captchaEndpoint value,
  // the result can be "simple-captcha-endpoint.ashx", "simple-captcha-endpoint",
  // or "simple-botdetect.php"
  function _getCaptchaEndpointHandler() {
    var splited = settings.captchaEndpoint.split('/');
    return splited[splited.length - 1];
  };

  function getCaptchaEndpointHandler() {
    var splited = captchaEndpoint.split('/');
    return splited[splited.length - 1];
  };

  // get backend base url from configued captchaEndpoint value
  function _getBackendBaseUrl(captchaEndpointHandler) {
    var lastIndex = settings.captchaEndpoint.lastIndexOf(captchaEndpointHandler);
    return settings.captchaEndpoint.substring(0, lastIndex);
  };

  function getBackendBaseUrl(captchaEndpointHandler) {
    var lastIndex = captchaEndpoint.lastIndexOf(captchaEndpointHandler);
    return captchaEndpoint.substring(0, lastIndex);
  };

  // change relative to absolute urls in captcha html markup
  function changeRelativeToAbsoluteUrls(originCaptchaHtml) {
    var captchaEndpointHandler = getCaptchaEndpointHandler();
    var backendUrl = getBackendBaseUrl(captchaEndpointHandler);

    originCaptchaHtml = originCaptchaHtml.replace(/<script.*<\/script>/g, '');
    var relativeUrls = originCaptchaHtml.match(/(src|href)=\"([^"]+)\"/g);

    var relativeUrl,
        relativeUrlPrefixPattern,
        absoluteUrl,
        changedCaptchaHtml = originCaptchaHtml;

    for (var i = 0; i < relativeUrls.length; i++) {
      relativeUrl = relativeUrls[i].slice(0, -1).replace(/src=\"|href=\"/, '');
      relativeUrlPrefixPattern = new RegExp(".*" + captchaEndpointHandler);
      absoluteUrl = relativeUrl.replace(relativeUrlPrefixPattern, backendUrl + captchaEndpointHandler);
      changedCaptchaHtml = changedCaptchaHtml.replace(relativeUrl, absoluteUrl);
    }

    return changedCaptchaHtml;
  };
});