define('awesome2/overrides/route', [], function () {
  'use strict';

  Ember.Route.reopen({
    actions: {
      didTransition() {
        var self = this;
        if (self.get('pageHeading')) {
          this.controller.set('pageHeading', self.get('pageHeading'));
        }
        if (self.get('headerMenuActive')) {
          this.controller.set('headerMenuActive', self.get('headerMenuActive'));
        }
        return true; // bubble the event
      }
    }
  });
});