define('awesome2/components/to-top', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        didInsertElement() {
            $('#toTop').click(function () {
                $('body,html').animate({ scrollTop: 0 });
            });
            $(window).scroll(function () {
                if ($(this).scrollTop() > 480) $('#toTop').addClass('show');else $('#toTop').removeClass('show');
            });
        }
    });
});