define('awesome2/controllers/events/wineries/winery', ['exports', 'ember-local-storage', 'lodash'], function (exports, _emberLocalStorage, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        wineryEventCustomerOrder: (0, _emberLocalStorage.storageFor)("customer-order"),
        session: Ember.inject.service(),

        totalPriceDisplay: Ember.computed('totalPrice', function () {
            return this.get("totalPrice").toFixed(2);
        }),

        totalPrice: Ember.computed('wineryEventCustomerOrder.[]', function () {
            let items = this.get("wineryEventCustomerOrder.content");
            return _lodash.default.sumBy(items, i => parseFloat(i.total));
        }),

        actions: {
            goToLogin() {
                this.get('session').invalidate();
                //query-params redirectPath=(concat (concat (concat '/events/' model.event.eventWebPath ) '/wineries/') model.winery.webPath)
                let model = this.get("model");
                let eventWebPath = model.event.eventWebPath;
                let wineryWebPath = model.winery.webPath;
                let redirectPath = `/events/${eventWebPath}/wineries/${wineryWebPath}`;
                this.transitionToRoute('accounts.login', { queryParams: { 'redirectPath': redirectPath } });
            },
            printWineryEventPriceList() {
                window.print();
            },
            showSlides() {
                console.log("show slides");
            },
            getCustomerInfo() {
                //check login as the current owner of the winery
                //open camera type for input
                console.log("getCustomerInfo");
            },
            order() {
                //check login as the current owner of the winery
                console.log("order");
            },
            resetOrder() {
                this.get("wineryEventCustomerOrder").clear();
                let currentRoute = Ember.getOwner(this).lookup('controller:application').currentPath;
                Ember.getOwner(this).lookup('route:' + currentRoute).refresh();
            }
        }
    });
});