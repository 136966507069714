define('awesome2/authorizers/custom', ['exports', 'ember-simple-auth/authorizers/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    session: Ember.inject.service(),
    authorize(data, block) {
      if (Ember.testing) {
        block('Authorization', 'Bearer beyonce');
      }
      const { token } = data;
      if (this.get('session.isAuthenticated') && token) {
        block('Authorization', `Bearer ${token}`);
      }
    }
  });
});