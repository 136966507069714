define('awesome2/components/card/wine/wine-card-tab-list', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        actions: {
            setWineCardTabListFilter(filter) {
                this.sendAction("setWineryOverviewFilter", filter);
            }
        }
    });
});