define('awesome2/utils/common-utils', ['exports', 'fetch', 'awesome2/adapters/country', 'awesome2/adapters/grape', 'awesome2/adapters/appellation', 'awesome2/adapters/color', 'lodash', 'moment'], function (exports, _fetch, _country, _grape, _appellation, _color, _lodash, _moment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.fetchCaptchaScript = fetchCaptchaScript;
    exports.fetchCaptcha = fetchCaptcha;
    exports.postData = postData;
    exports.getData = getData;
    exports.fetchData = fetchData;
    exports.fetchDataJwt = fetchDataJwt;
    exports.calculateLocale = calculateLocale;
    exports.getLang = getLang;
    exports.getUserI18n = getUserI18n;
    exports.getUserLanguage = getUserLanguage;
    exports.setUserLanguage = setUserLanguage;
    exports.getCountriesInfo2 = getCountriesInfo2;
    exports.getCountriesInfo = getCountriesInfo;
    exports.getGrapesInfo = getGrapesInfo;
    exports.getAppellationsInfo = getAppellationsInfo;
    exports.getColorsInfo = getColorsInfo;
    exports.getJsonFromLocalStorage = getJsonFromLocalStorage;
    exports.getFlagIcon = getFlagIcon;
    exports.getCountryInfo = getCountryInfo;
    exports.getGrapeColor = getGrapeColor;
    exports.getColor = getColor;
    exports.getCurrentUrlEnding = getCurrentUrlEnding;
    exports.setFilter = setFilter;
    exports.webPath = webPath;
    exports.replaceAll = replaceAll;
    exports.getPropertiesOptions = getPropertiesOptions;
    exports.getPropertyOptions = getPropertyOptions;
    exports.toWebPath = toWebPath;
    exports.formatDateFr = formatDateFr;
    exports.extractFilterFromEvent = extractFilterFromEvent;


    const COUNTRY_STORAGE_KEY = "winy.countries.info";
    const GRAPE_STORAGE_KEY = "winy.grapes.info";
    const APPELLATION_STORAGE_KEY = "winy.appellations.info";
    const COLOR_STORAGE_KEY = "winy.colors.info";

    function fetchCaptchaScript(url) {
        // options = $.extend({
        //     dataType: 'script',
        //     cache: false,
        //     url: url
        // }, options || {});
        // return $.ajax(options);
        return (0, _fetch.default)(url, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-type': 'script'
            }
        })
        // .then(res => {
        //     return res.text();
        // })
        // .then(data => {
        //     return data;
        // });
        ;
    }

    function fetchCaptcha(url) {
        return (0, _fetch.default)(url, {
            method: 'GET',
            mode: 'cors'
            // headers : {
            //     'Content-type': 'application/json'
            // }
        }).then(res => {
            return res.text();
        }).then(data => {
            return data;
        });
        ;
    }

    function postData(url) {
        return fetchIt(url, "post");
    }

    function getData(url) {
        return fetchIt(url, "get");
    }

    function fetchData(url) {
        return getData(url);
    }

    function fetchDataJwt(url, method) {
        let myjwt = JSON.parse(localStorage.getItem("ember_simple_auth-session")).authenticated.token;
        return (0, _fetch.default)(url, {
            method: method,
            mode: 'cors',
            headers: {
                'Content-type': 'application/json',
                'Authorization': 'Bearer ' + myjwt
            }
        }).then(function (response) {
            return response.json();
        })
        /* check that the default ESA behaviour works*/
        .catch(function (error) {
            //invalidate session
            //TODO use throw exception instead
            throw { "session": "invalidate" };
        });
    }

    function fetchIt(url, method) {
        return (0, _fetch.default)(url, {
            method: method,
            mode: 'cors',
            headers: {
                'Content-type': 'application/json'
            }
        }).then(function (response) {
            return response.json();
        });
    }

    exports.default = {
        fetchData: fetchData
    };
    function calculateLocale(locales) {
        // whatever you do to pick a locale for the user:
        const language = navigator.languages[0] || navigator.language || navigator.userLanguage;

        return locales.includes(language.toLowerCase()) ? language : 'en-GB';
    }

    function getLang() {
        if (navigator.languages != undefined) return navigator.languages[0];else return navigator.language;
    }

    //language
    function getUserI18n() {
        return getUserLanguage().split("-")[0];
    }

    function getUserLanguage() {
        var localStorageUserLanguage = window.localStorage.getItem("winy.user.language");
        if (localStorageUserLanguage == undefined || localStorageUserLanguage == "undefined") {
            var language = window.navigator.userLanguage || window.navigator.language;
            setUserLanguage(language);
            return language;
        }
        return localStorageUserLanguage;
    }

    function setUserLanguage(locale) {
        window.localStorage.setItem("winy.user.language", locale);
    }

    //local storage : https://stackoverflow.com/questions/14814472/caching-remote-data-in-local-storage-with-emberdata?utm_medium=organic&utm_source=google_rich_qa&utm_campaign=google_rich_qa

    function getCountriesInfo2() {
        var c = window.localStorage.getItem(COUNTRY_STORAGE_KEY);
        var countriesInfo = c ? JSON.parse(c) : null;
        if (countriesInfo == undefined) {
            var adapter = _country.default.create();
            var promise = adapter.findAll().then(data => {
                return data.CountryDescriptionOut;
            });
            var promises = {
                promise: promise
            };
            Ember.RSVP.hash(promises).then(function (data) {
                countriesInfo = data.promise;
                window.localStorage.setItem(COUNTRY_STORAGE_KEY, JSON.stringify(countriesInfo));
            });
        }
        return countriesInfo;
    }

    function getCountriesInfo() {
        return getJsonFromLocalStorage(COUNTRY_STORAGE_KEY, _country.default.create(), function (data) {
            return data.CountryDescriptionOut;
        });
    }

    function getGrapesInfo() {
        return getJsonFromLocalStorage(GRAPE_STORAGE_KEY, _grape.default.create(), function (data) {
            return data.CepageDescriptionOut;
        });
    }

    function getAppellationsInfo() {
        return getJsonFromLocalStorage(APPELLATION_STORAGE_KEY, _appellation.default.create(), function (data) {
            return data.AppelationBadgeOut;
        });
    }

    function getColorsInfo() {
        return getJsonFromLocalStorage(COLOR_STORAGE_KEY, _color.default.create(), function (data) {
            return data.ColorInfoOut;
        });
    }

    function getJsonFromLocalStorage(key, adapter, dataCallback) {
        var c = window.localStorage.getItem(key);
        var result = c && c != "undefined" ? JSON.parse(c) : null;
        if (!result) {
            var promise = adapter.findAll().then(data => {
                return dataCallback(data);
            });
            var promises = {
                promise: promise
            };
            Ember.RSVP.hash(promises).then(function (data) {
                result = data.promise;
                window.localStorage.setItem(key, JSON.stringify(result));
            });
        }
        return result;
    }

    function getFlagIcon(countryWebPath) {
        let country = getCountryInfo(countryWebPath);
        if (country) {
            return country.flagIcon;
        }
        return;
    }

    function getCountryInfo(countryWebPath) {
        var c = window.localStorage.getItem(COUNTRY_STORAGE_KEY);
        var countriesInfo = c && c != "undefined" ? JSON.parse(c) : null;
        if (!countriesInfo) return;
        return _lodash.default.filter(countriesInfo, { 'webPath': countryWebPath })[0];
    }

    function getGrapeColor(grapeWebPath) {
        var c = window.localStorage.getItem(GRAPE_STORAGE_KEY);
        var grapesInfo = c && c != "undefined" ? JSON.parse(c) : null;
        if (!grapesInfo) return;
        return _lodash.default.filter(grapesInfo, { 'webPath': grapeWebPath })[0].rbg; //TODO change to rgb
    }

    function getColor(colorName) {
        var c = window.localStorage.getItem(COLOR_STORAGE_KEY);
        var colorInfo = c && c != "undefined" ? JSON.parse(c) : null;
        if (!colorInfo) return;
        return _lodash.default.filter(colorInfo, { 'name': colorName })[0].rgb;
    }

    function getCurrentUrlEnding() {
        let currentUrl = window.location.href;
        let urlProtocolIndex = currentUrl.indexOf('//');
        let urlRoot = currentUrl.indexOf('/', urlProtocolIndex + 2);
        let urlEnding = currentUrl.substring(urlRoot + 1);
        return urlEnding;
    }

    function setFilter(that, filter, type) {
        if (filter && filter.type == type) {
            if (that.get(type) == filter.value) {
                //TODO change with contains
                that.set(type, null);
            } else {
                that.set(type, filter.value);
            }
        }
    }

    function webPath(input) {
        return replaceAll(input.toLowerCase(), " ", "-");
    }

    function replaceAll(str, find, replace) {
        return str.replace(new RegExp(find, 'g'), replace);
    }

    function getPropertiesOptions(list, property, filter) {
        // ex:getPropertiesOptions(wineries, 'cepageNames', params.wineryCepage)
        //getPropertiesOptions(wineries, 'cepageNames', 'chardonnay')
        if (filter) {
            filter = filter.toString();
        }
        //TODO property is a list and not a unique value
        var v = _lodash.default.countBy(list, property);

        return Object.entries(v).map(([k, v]) => ({
            'name': k,
            'nb': v,
            'active': filter == k.toString()
        }));
    }

    function getPropertyOptions(list, property, filter) {
        if (filter) {
            filter = filter.toString();
        }
        var v = _lodash.default.countBy(list, property);

        return Object.entries(v).map(([k, v]) => ({
            'name': k,
            'nb': v,
            'active': filter == k.toString()
        }));
    }

    function toWebPath(param) {
        if (param) {
            return param.trim().toLowerCase().split(' ').join('-');
        } else {
            return "";
        }
    }

    function formatDateFr(date) {
        return (0, _moment.default)(date).format('DD MMMM YYYY');
    }

    function extractFilterFromEvent(event) {
        //remove duplicate code with product-filter.js (addProductFilter)
        //let parentNode = event.target.parentNode;
        let parentNode = event.target;
        let value = parentNode.getAttribute("data-value");
        let type = parentNode.getAttribute("data-type");
        let filter = {
            type: type,
            value: value
        };
        return filter;
    }
});