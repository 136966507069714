define('awesome2/instance-initializers/i18n', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize(appInstance) {
        let translationsFetcher = appInstance.lookup('service:translationsFetcher');
        translationsFetcher.fetch();
    }

    exports.default = {
        name: 'i18n',
        initialize: initialize
    };
});