define('awesome2/components/card/appellation/appellation-card-overview', ['exports', 'awesome2/mixins/component-base-mixin'], function (exports, _componentBaseMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_componentBaseMixin.default, {

        actions: {
            handleClick: function (event) {}
        }
    });
});