define("awesome2/helpers/value-default", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.valueDefault = valueDefault;
  function valueDefault(params /*, hash*/) {
    let [value, defaultValue] = params;
    if (params.length == 0) return "";
    if (params.length == 1) return params[0];else {
      if (value) {
        return value;
      } else {
        return defaultValue;
      }
    }
  }

  exports.default = Ember.Helper.helper(valueDefault);
});