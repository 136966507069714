define('awesome2/models/customer-purchase', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _emberData.default.Model.extend({

        customerEmail: (0, _attr.default)('string'),
        customer: _emberData.default.belongsTo('user'),

        //https://discuss.emberjs.com/t/ember-computed-sum/9786
        totalPriceArray: Ember.computed.mapBy('productLines', 'totalPrice'),
        totalPrice: Ember.computed.sum('totalPriceArray'),
        totalPriceDisplay: Ember.computed('totalPrice', function () {
            return this.get("totalPrice").toFixed(2);
        }),
        //list of product-lines
        productLines: _emberData.default.hasMany('product-line'),

        wineryProductLines: _emberData.default.hasMany('product-line')

    });
});