define('awesome2/routes/events/wineries/winery', ['exports', 'awesome2/routes/base', 'ember-route-history/mixins/routes/route-history'], function (exports, _base, _routeHistory) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend(_routeHistory.default, {
        eventService: Ember.inject.service("event"),
        model(params) {
            return this.get('eventService').findWineryEventInfo(params);
        },
        afterModel(model, transition) {
            let domain = model.winery.domain;
            let event = model.event.eventName + ' ' + model.event.year;
            let title = event + ' - ' + domain;
            this.set("titleToken", ['field.event', title]);
        }
    });
});