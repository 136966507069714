define('awesome2/initializers/i18n', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: 'i18n',
    after: 'ember-i18n',
    initialize: function (app) {
      app.inject('model', 'i18n', 'service:i18n');
      app.inject('controller', 'i18n', 'service:i18n');
      app.inject('component', 'i18n', 'service:i18n');
      app.inject('route', 'i18n', 'service:i18n');
      //let i18n = container.lookup('service:i18n');
      //i18n.set('locale', calculateLocale(i18n.get('locales')));
    }
    /* */
  };
});