define('awesome2/adapters/user', ['exports', 'ember-data', 'ember-simple-auth/mixins/data-adapter-mixin', 'awesome2/utils/common-utils', 'awesome2/adapters/base', 'awesome2/config/environment'], function (exports, _emberData, _dataAdapterMixin, _commonUtils, _base, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const { host, namespace } = _environment.default.DS;
    const baseUrl = host + "/" + namespace;

    exports.default = _base.default.extend(_dataAdapterMixin.default, {

        authorizer: 'authorizer:custom',
        fetch(email) {
            return (0, _commonUtils.fetchDataJwt)(baseUrl + '/data/sdd/UserInfoIn?email=' + email);
        },

        fetchCurrentPurchaseOrder(email) {
            return (0, _commonUtils.fetchDataJwt)(baseUrl + '/data/sdd/UserPurchaseOrderIn?email=' + email);
        }

    });
});