define('awesome2/components/card/wine/wine-card-overview', ['exports', 'awesome2/utils/common-utils', 'awesome2/mixins/component-base-mixin'], function (exports, _commonUtils, _componentBaseMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_componentBaseMixin.default, {

        //TODO current url ending aware common with winery-card-overview (see how to extends)
        origin: "",
        init() {
            this._super(...arguments);
            //FREE-ACCESS-WINERY 1
            //this.set("origin", getCurrentUrlEnding())
        }
        //END common code
    });
});