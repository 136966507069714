define('awesome2/utils/winery-utils', ['exports', 'lodash', 'awesome2/utils/common-utils'], function (exports, _lodash, _commonUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.filterWineries = filterWineries;
    exports.initProductWineryOptions = initProductWineryOptions;
    exports.prepareRegion = prepareRegion;
    exports.address = address;
    exports.addressStreet = addressStreet;
    function filterWineries(wineries, wineryFilters) {

        if (wineryFilters.wineryCountry) {
            wineries = _lodash.default.filter(wineries, { countryName: wineryFilters.wineryCountry });
        }
        if (wineryFilters.wineryRegion) {
            wineries = _lodash.default.filter(wineries, { regionName: wineryFilters.wineryRegion });
        }
        if (wineryFilters.wineryBio) {
            wineries = _lodash.default.filter(wineries, { isBio: wineryFilters.wineryBio == 'true' });
        }
        if (wineryFilters.wineryCepage) {
            let cepageWebPath = (0, _commonUtils.toWebPath)(wineryFilters.wineryCepage);
            wineries = _lodash.default.filter(wineries, e => {
                if (e.cepageNames) {
                    return e.cepageNames.includes(cepageWebPath);
                } else {
                    return false;
                }
            });
        }
        return wineries;
    }

    function initProductWineryOptions(wineries, params) {

        var distinctCountries = (0, _commonUtils.getPropertyOptions)(wineries, 'country', params.wineryCountry);
        var distinctRegions = (0, _commonUtils.getPropertyOptions)(wineries, 'region', params.wineryRegion);
        var distinctCepages = (0, _commonUtils.getPropertiesOptions)(wineries, 'cepageNames', params.wineryCepage);
        var distinctBio = (0, _commonUtils.getPropertyOptions)(wineries, 'isBio', params.wineryBio);

        return {
            country: distinctCountries,
            region: distinctRegions,
            cepage: distinctCepages,
            bio: distinctBio
        };
    }

    //TODO region utils

    function prepareRegion(region) {
        region.cepages = region.cepageNames ? region.cepageNames.split(',') : []; //TODO replace by cepageNames
        region.wineryCepages = region.wineryCepageNames ? region.wineryCepageNames.split(',') : []; //TODO put in function
        region.appellations = region.appellationNames ? region.appellationNames.split(',') : []; //TODO put in function
        return region;
    }

    function address(contact) {
        let addressSt = addressStreet(contact);
        let addressZipCity = `${contact.contactPostCode} ${contact.contactCity}`;
        return `${addressSt} - ${addressZipCity}`;
    }

    function addressStreet(contact) {
        return contact.contactStreet2 ? `${contact.contactStreet1} - ${contact.contactStreet2}` : `${contact.contactStreet1}`;
    }
});