define('awesome2/components/upload/image-upload', ['exports', 'awesome2/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    environment: _environment.default.ENVIRONMENT,
    tagName: ''

  });
});