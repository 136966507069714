define('awesome2/initializers/inject-route-history', ['exports', 'ember-route-history/initializers/inject-route-history'], function (exports, _injectRouteHistory) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _injectRouteHistory.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _injectRouteHistory.initialize;
    }
  });
});