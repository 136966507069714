define('awesome2/components/snippet/field-sorter-snippet', ['exports', 'lodash', 'awesome2/utils/common-utils'], function (exports, _lodash, _commonUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        order: null,
        key: null,
        d: Ember.computed('order', 'name', function () {
            let order = this.get("order");
            let key = this.get("key");
            return _lodash.default.find(order, { 'name': key });
        }),
        actions: {
            forwardActionSorter(event) {
                //remove duplicate code with product-filter.js (addProductFilter)
                /*           let parentNode = event.target.parentNode;
                           let value = parentNode.getAttribute("data-value");
                           let type = parentNode.getAttribute("data-type");
                           let filter = {
                               type : type,
                               value : value,
                           }
                           */
                let filter = (0, _commonUtils.extractFilterFromEvent)(event);
                this.sendAction("setWineCardProductSorter", filter);
            }
        }
    });
});