define('awesome2/helpers/format-to-web-path', ['exports', 'awesome2/utils/common-utils'], function (exports, _commonUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatToWebPath = formatToWebPath;
  function formatToWebPath(params /*, hash*/) {

    let [param] = params;
    return (0, _commonUtils.toWebPath)(param);
    /*
    if (param) {
      return param.trim().toLowerCase().split(' ').join('-');
    } else {
      return "";
    }
    */
  }

  exports.default = Ember.Helper.helper(formatToWebPath);
});