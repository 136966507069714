define('awesome2/components/snippet/modal/wp-modal/header/title', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    //  layout,
    tagName: 'h4',
    classNames: ['modal-title']
  });
});