define('awesome2/adapters/newsletter', ['exports', 'ember-data', 'awesome2/utils/common-utils', 'awesome2/adapters/base', 'npm:append-query', 'awesome2/config/environment'], function (exports, _emberData, _commonUtils, _base, _npmAppendQuery, _environment) {
				'use strict';

				Object.defineProperty(exports, "__esModule", {
								value: true
				});

				const { host, namespace } = _environment.default.DSNewsletter;
				const baseUrl = host + "/" + namespace;

				exports.default = _base.default.extend({

								//blog

								/* connector to BlogList info */
								blogList: function (params) {
												return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/BlogListIn' + filterBlogList(params));
								},
								requestNewsletter: function (email) {
												return $.post(baseUrl + '/data/sdd/RequestNewsletterIn', {
																email: email,
																firstName: '',
																lastName: ''
												});
								},
								revokeNewsletter: function (email) {
												return $.post(baseUrl + '/data/sdd/RevokeNewsletterIn', {
																email: email
												});
								}
				});


				//blog

				/* filter for BlogList entity */
				function filterBlogList(filter) {
								if (filter) {
												return (0, _npmAppendQuery.default)("", {
																"webPath": filter.webPath,
																"isActive": filter.isActive,
																"limit": filter.limit,
																"offset": filter.offset
												}, { removeNull: true });
								}
								return "";
				}
});