define('awesome2/routes/upload/image', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const { get, set } = Ember;

  exports.default = Ember.Route.extend({
    model() {
      return this.store.createRecord('image');
    },
    uploadPhoto: (0, _emberConcurrency.task)(function* (file) {
      let product = this.modelFor('product');
      let photo = this.store.createRecord('photo', {
        product,
        filename: get(file, 'name'),
        filesize: get(file, 'size')
      });

      try {
        file.readAsDataURL().then(function (url) {
          if (get(photo, 'url') == null) {
            set(photo, 'url', url);
          }
        });

        let response = yield file.upload('/api/images/upload');
        set(photo, 'url', response.headers.Location);
        yield photo.save();
      } catch (e) {
        photo.rollback();
      }
    }).maxConcurrency(3).enqueue(),

    actions: {
      uploadImage(file) {
        get(this, 'uploadPhoto').perform(file);
      }
    }
  });
});