define('awesome2/components/card/vintage/vintage-card-item', ['exports', 'lodash', 'awesome2/mixins/component-base-mixin', 'ember-local-storage', 'awesome2/utils/purchase-utils'], function (exports, _lodash, _componentBaseMixin, _emberLocalStorage, _purchaseUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_componentBaseMixin.default, {
        tagName: '',
        data: null,
        //TODO add in component-cart-mixin
        cart: (0, _emberLocalStorage.storageFor)("cart"),
        numberInShoppingCart: Ember.computed('cart.[]', function () {
            let cartContent = this.get("cart.content");
            //let productItemId = this.get("data.productItemId");
            let productItemPriceId = this.get("data.productItemPriceId");
            let idComposite = (0, _purchaseUtils.getCompositeId)(productItemPriceId, "winery", null); //vintage-card-item is only used in the context of winery (home)
            let itemQuantity = _lodash.default.filter(cartContent, { 'idComposite': idComposite });
            if (itemQuantity.length == 0) {
                return 0;
            } else {
                return itemQuantity[0] | itemQuantity[0].quantity;
            }
        }),
        isInShoppingCart: Ember.computed('numberInShoppingCart', function () {
            let numberInShoppingCart = this.get("numberInShoppingCart");
            return numberInShoppingCart > 0;
        }),

        actions: {
            //TODO MODAL-WITH-OBJECT to refactor
            setProductItemId(productItemId, productId, wineryId, productWebPath, wineryWebPath, modalDisplayPrice, modalCurrencyName) {
                this.sendAction("forwardToModal", productItemId, productId, wineryId, productWebPath, wineryWebPath, modalDisplayPrice, modalCurrencyName);
            }
        }

    });
});