define('awesome2/services/product', ['exports', 'awesome2/adapters/product', 'awesome2/adapters/winery', 'awesome2/adapters/vintage', 'awesome2/utils/business-utils', 'awesome2/utils/product-utils', 'lodash'], function (exports, _product, _winery, _vintage, _businessUtils, _productUtils, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        find(webPath, wineryWebPath) {
            var adapter = _product.default.create();
            return adapter.findByWebPath(webPath, wineryWebPath);
        },
        findById(id) {
            var adapter = _product.default.create();
            return adapter.findById(id);
        },
        findProductItemPriceByEventIdAndWineryId(eventId, wineryId) {
            var adapter = _product.default.create();
            return adapter.findProductItemPriceByEventIdAndWineryId(eventId, wineryId);
        },
        findProductItemPriceByWineryId(wineryId) {
            var adapter = _product.default.create();
            return adapter.findProductItemPriceByWineryId(wineryId);
        },
        findByWineryWebPath(wineryWebPath, params) {
            var adapter = _product.default.create();
            var productPromise = adapter.findByWineryWebPath(wineryWebPath).then(function (data) {
                return data.WineryProductItemBadgeOut;
            });

            var wineryAdapter = _winery.default.create();
            var wineryPromise = wineryAdapter.findByWebpath(wineryWebPath) //TODO convert to findByWeb P ath
            .then(function (data) {
                return data.WineryPublicOut[0];
            });
            var promises = {
                winery: wineryPromise,
                products: productPromise
            };
            return Ember.RSVP.hash(promises).then(function (data) {
                (0, _productUtils.prepareProducts)(data, params);
                return data;
            });
        },
        findSales(params) {
            var adapter = _product.default.create();
            var winerySalesPromise = adapter.winerySales().then(function (data) {
                return (0, _productUtils.groupByWinery)(data.WineryWinePriceItemOut);
            });

            var eventPresalesPromise = adapter.eventPresales().then(function (data) {
                return (0, _productUtils.groupByEvent)(data.EventWinePriceItemOut); //TODO group by event + winery
            });
            var promises = {
                productItemPrices: winerySalesPromise,
                eventPresalePrices: eventPresalesPromise
            };
            return Ember.RSVP.hash(promises).then(function (data) {
                //prepareProducts(data, params);
                //debugger
                return data;
            });
        },
        findOverview(webPath, wineryWebPath) {
            var adapter = _product.default.create();
            var productPromise = adapter.findByWebPath(webPath, wineryWebPath).then(function (data) {
                var item = data.WineryProductItemBadgeOut[0];
                //TODO replace with composite webpath
                item.webPath = item.productWebPath;
                return (0, _productUtils.prepareProduct)(item);
            });
            var productConservationPromise = adapter.findConservationMethodByProductWebPath(webPath).then(function (data) {
                return prepareProductConservations(data.WineryProductConservationBadgeOut);
            });
            var wineryAdapter = _winery.default.create();
            var wineryPromise = wineryAdapter.findByWebpath(wineryWebPath) //TODO convert to findByWeb P ath
            .then(function (data) {
                return data.WineryPublicOut[0];
            });
            var vintageAdapter = _vintage.default.create();
            var vintagePromise = vintageAdapter.findByWineryWebPathAndProductWebPath(wineryWebPath, webPath).then(function (data) {
                return data.ProductanditemlistOut;
            });
            var promises = {
                winery: wineryPromise,
                conservationProcessings: productConservationPromise,
                product: productPromise,
                vintages: vintagePromise
            };
            return Ember.RSVP.hash(promises).then(function (data) {
                return data;
            });
        }
    });


    function prepareProductConservations(data) {
        let conservationProcessings = [];
        if (data.length > 0) {
            _lodash.default.each(data, function (item) {
                let conservationProcessing = (0, _businessUtils.prepareConservationProcessing)(item);
                conservationProcessings.push(conservationProcessing);
            });
        }
        return conservationProcessings;
    }
});