define('awesome2/services/newsletter', ['exports', 'ember-data', 'awesome2/adapters/newsletter', 'lodash'], function (exports, _emberData, _newsletter, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        //blog
        blogList(params) {
            var adapter = _newsletter.default.create();
            return adapter.blogList(params).then(data => {
                _lodash.default.each(data.BlogListOut, e => prepareBlog(e));
                return data.BlogListOut;
            });
        }

    });


    function prepareBlog(blog) {
        blog.tags = blog.tags ? blog.tags.split(',') : [];
    }
});