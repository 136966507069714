define('awesome2/controllers/events/index', ['exports', 'lodash', 'awesome2/utils/business-utils'], function (exports, _lodash, _businessUtils) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        queryParams: ['country', 'searchFreeText'],
        country: null,
        searchFreeText: null,
        hasCountryFilter: Ember.computed.notEmpty('country'),
        perspective: 'event',

        hasFilter: Ember.computed('hasCountryFilter', function () {
            return this.get('hasCountryFilter');
        }),

        setInnerFilter(value) {
            (0, _businessUtils.toDisplayEvent)(this.get("model").events.EventInfoOut, value);
            (0, _businessUtils.setDisplayEvents)(this.get("model").events, this.get("model").events.EventInfoOut);
            (0, _businessUtils.setDisplayCount)(this.get("model").events, this.get("model").events.EventInfoOut);

            this.set("searchFreeText", value);
        },

        actions: {
            setCountryFilter(webPath) {
                this.set("country", webPath);
            },
            addFilter(event) {
                var key = $(event.target).data("value");
                this.get("optionFilterDdl").forEach(function (item) {
                    if (item.key == key) {
                        Ember.set(item, 'class', 'active');
                    } else {
                        Ember.set(item, 'class', '');
                    }
                });
            },
            handleInnerFilter(value) {
                Ember.run.debounce(this, this.setInnerFilter, value, 500);
            }
        }

    });
});