define('awesome2/components/order/purchase-order-line', ['exports', 'ember-local-storage', 'awesome2/utils/purchase-utils', 'awesome2/mixins/component-base-mixin'], function (exports, _emberLocalStorage, _purchaseUtils, _componentBaseMixin) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_componentBaseMixin.default, {
        tagName: '',

        model: null,
        data: null,
        quantity: null,
        cart: (0, _emberLocalStorage.storageFor)("cart"),

        quantityChanged: Ember.observer('data.quantity', function () {
            //TODO use idComposite
            let quantity = this.get("data.quantity");
            let productItemPriceId = this.get("data.productItemPriceId");
            let eventId = this.get("data.eventId");
            let priceType = this.get("data.priceType");
            let idComposite = (0, _purchaseUtils.getCompositeId)(productItemPriceId, priceType, eventId);
            let cart = this.get("cart");
            var obj = cart.findBy('idComposite', idComposite);
            let wineryId = obj.wineryId;
            let productId = obj.productId;
            let productWebPath = obj.productWebPath;
            let wineryWebPath = obj.wineryWebPath;
            //        let priceType = obj.priceType;
            //        let eventId = obj.eventId;
            (0, _purchaseUtils.addInCart)(this, productItemPriceId, quantity, productId, wineryId, productWebPath, wineryWebPath, priceType, eventId);
        }),

        actions: {
            remove(productItemPriceId, priceType, eventId) {
                this.sendAction("remove", productItemPriceId, priceType, eventId);
            }
        }

    });
});