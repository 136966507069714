define('awesome2/adapters/base', ['exports', 'ember-data', 'awesome2/config/environment'], function (exports, _emberData, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const { host, namespace } = _environment.default.DS;
    const baseUrl = host + "/" + namespace;

    exports.default = _emberData.default.JSONAPIAdapter.extend({
        //baseUrl : baseUrl,
    });
});