define('awesome2/components/snippet/cookies-warning-snippet', ['exports', 'lodash'], function (exports, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const { keys } = Object;

    exports.default = Ember.Component.extend({
        tagName: "",
        hasAcknowledgeCookiesWarning: Ember.computed('cookies', function () {
            let cookieService = this.get('cookies');
            let cookies = cookieService.read();
            return cookies.hasAcknowledgeCookiesWarning ? cookies.hasAcknowledgeCookiesWarning : false;
        }),
        cookies: Ember.inject.service(),
        actions: {
            close() {
                //has acknowledge cookies warning
                this.set("hasAcknowledgeCookiesWarning", true);
                //set this info in a ... cookie
                let cookieService = this.get('cookies');
                //           cookieService.write('now', new Date().getTime());
                cookieService.write('hasAcknowledgeCookiesWarning', true);
                /*        
                            let cookies = cookieService.read();
                            return keys(cookies).reduce((acc, key) => {
                              let value = cookies[key];
                              acc.push({ name: key, value });
                        
                              return acc;
                            }, []);
                            */
            }
        }
    });
});