define('awesome2/components/card/vintage/vintage-card-list', ['exports', 'awesome2/mixins/component-base-mixin', 'ember-local-storage', 'awesome2/utils/purchase-utils', 'lodash'], function (exports, _componentBaseMixin, _emberLocalStorage, _purchaseUtils, _lodash) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_componentBaseMixin.default, {
        tagName: '',

        cart: (0, _emberLocalStorage.storageFor)("cart"),
        modal: false,
        modalQuantity: null,
        modalProductItemId: null,
        modalProductId: null,
        modalWineryId: null,
        modalProductWebPath: null,
        modalWineryWebPath: null,
        modalDisplayPrice: null,
        modalCurrencyName: null,
        perspective: null,
        disableDecrease: Ember.computed('modalQuantity', function () {
            let modalQuantity = this.get("modalQuantity");
            let modalQuantityInt = parseInt(modalQuantity);
            return modalQuantityInt <= 1;
        }),
        /**/
        actions: {
            //TODO MODAL-WITH-OBJECT to refactor
            toModal(productItemId, productId, wineryId, productWebPath, wineryWebPath, modalDisplayPrice, modalCurrencyName) {
                let cart = this.get("cart");
                //TODO remove duplicate
                let idComposite = (0, _purchaseUtils.getCompositeId)(productItemId, "winery");

                var obj = cart.findBy('idComposite', idComposite);
                //var obj = cart.findBy('id', productItemId);
                let quantity = obj ? obj.quantity : 1;
                this.set("modalQuantity", quantity);
                this.set("modalProductItemId", productItemId);
                this.set("modalProductId", productId);
                this.set("modalWineryId", wineryId);
                this.set("modalProductWebPath", productWebPath);
                this.set("modalWineryWebPath", wineryWebPath);
                this.set("modalPriceType", "winery");
                this.set("modalDisplayPrice", modalDisplayPrice);
                this.set("modalCurrencyName", modalCurrencyName);
                this.set('modal', true);
            }

        }
    });
});