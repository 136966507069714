define('awesome2/routes/developer', ['exports', 'awesome2/adapters/developer'], function (exports, _developer) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({

        model(params) {
            var adapter = _developer.default.create();
            return adapter.findAll().then(function (data) {
                return {
                    serverRootInfo: data.SystemInfoOut[0].paramValue,
                    sitePublicApi: data.SystemInfoOut[1].paramValue,
                    sitePublicApiSampleClient: data.SystemInfoOut[2].paramValue
                };
            }).catch(function (error) {
                console.log("error = " + error);
            });
        }

    });
});