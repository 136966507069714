define('awesome2/controllers/accounts/login', ['exports', 'awesome2/config/environment', 'ember-local-storage'], function (exports, _environment, _emberLocalStorage) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const { rootUrl } = _environment.default.USER_MANAGEMENT_URL;
    const userManagementUrl = rootUrl + '/userfrosting';

    exports.default = Ember.Controller.extend({

        identification: null,
        password: null,
        userRegistrationUrl: userManagementUrl + "/account/register", //TODO remove duplicate
        user: (0, _emberLocalStorage.storageFor)("user"),
        cart: (0, _emberLocalStorage.storageFor)("cart"),
        session: Ember.inject.service(),
        actions: {
            authenticate: function () {

                // this.transitionToRoute("events.wineries.winery", 
                // {
                //     winery : {
                //         domain : "domaine-des-pierrettes"
                //     },
                //     event : {
                //         event : "louvino-2021"
                //     },
                //     event_webpath:"louvino-2021",
                //     winery_webpath:"domaine-des-pierrettes"
                // });

                let userLogin = this.get("user");
                let identification = this.get("identification");
                userLogin.set("login", identification);
                var credentials = this.getProperties('identification', 'password'),
                    authenticator = 'authenticator:jwt';
                this.get('session').authenticate(authenticator, credentials).catch(reason => {
                    this.set('errorMessage', reason.error || reason);
                }).then(() => {
                    let model = this.get("model");
                    this.transitionToRoute(model.redirectPath);
                    //or this.transitionToRoute("events.wineries.winery", but put the model)
                });
            }
        }
    });
});