define('awesome2/utils/notification-utils', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.notifySuccess = notifySuccess;
    exports.notifyFailure = notifyFailure;
    function notifySuccess(that, i18n) {
        that.get('notifications').success(that.get('i18n').t(i18n), {
            autoClear: true
        });
    }

    function notifyFailure(that, i18n) {
        that.get('notifications').error(that.get('i18n').t(i18n), {
            autoClear: true
        });
    }

    exports.default = {
        notifySuccess: notifySuccess
    };
});