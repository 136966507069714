define('awesome2/services/session', ['exports', 'ember-data', 'ember-simple-auth/services/session', 'ember-local-storage'], function (exports, _emberData, _session, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _session.default.extend({

    store: Ember.inject.service(),
    user: (0, _emberLocalStorage.storageFor)("user"),

    currentUser: Ember.computed('isAuthenticated', function () {
      if (this.get('isAuthenticated')) {
        //const promise = this.get('store').queryRecord('user', {})
        //return DS.PromiseObject.create({ promise: promise })
        var currentUser = this.get("store").createRecord('user');
        let user = this.get("user");
        let login = user.get("login");
        currentUser.set("email", login);
        return currentUser;
      }
    })

  });
});