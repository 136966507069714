define("awesome2/helpers/country-flag", ["exports", "awesome2/utils/common-utils", "awesome2/helpers/format-to-web-path"], function (exports, _commonUtils, _formatToWebPath) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.countryFlag = countryFlag;
  function countryFlag(params /*, hash*/) {
    return (0, _commonUtils.getFlagIcon)((0, _formatToWebPath.formatToWebPath)(params));
  }

  exports.default = Ember.Helper.helper(countryFlag);
});