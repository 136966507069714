define('awesome2/components/search/inner-search-grape-filter', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        appellations: null,

        actions: {
            addFilter(event) {
                var value = event.target.getAttribute("data-value");
                this.sendAction("sendGrape", value);
            }
        }
    });
});