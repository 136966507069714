define('awesome2/models/feedback', ['exports', 'ember-data', 'ember-data/attr'], function (exports, _emberData, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    email: (0, _attr.default)('string'),
    category: (0, _attr.default)('string'),
    comment: (0, _attr.default)('string'),
    origin: (0, _attr.default)('string'),
    languageCode: (0, _attr.default)('languageCode'),
    ip: (0, _attr.default)('ip')
  });
});