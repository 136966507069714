define('awesome2/components/form/event/event-request-form', ['exports', 'awesome2/adapters/post-entity', 'awesome2/utils/notification-utils'], function (exports, _postEntity, _notificationUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    tagName: '',

    isValidEmail: Ember.computed.match('model.email', /^.+@.+\..+$/),
    isValidFirstName: Ember.computed.gte('model.firstName.length', 2),
    isValidLastName: Ember.computed.gte('model.lastName.length', 2),
    isValidEvent: Ember.computed.gte('model.event.length', 5),
    isValidDescription: Ember.computed.gte('model.description.length', 5),

    isDisabled: Ember.computed('isValidEmail', 'isValidFirstName', 'isValidLastName', 'isValidDescription', 'isValidEvent', function () {
      return !(this.get('isValidEmail') && this.get('isValidFirstName') && this.get('isValidLastName') && this.get('isValidEvent') && this.get('isValidDescription'));
    }),
    isSent: false,

    actions: {
      request(request) {
        this.set('isSent', true);
        var adapter = _postEntity.default.create();
        adapter.requestEvent(this.get("model")).then(data => {
          this.set('isSent', false);
          (0, _notificationUtils.notifySuccess)(this, "notification.event-successfully-requested");
        }).catch(x => {
          this.set('isSent', false);
          (0, _notificationUtils.notifyFailure)(this, "notification.unexpected-error-processing-request");
          console.log("error " + x.responseJSON.cause);
        });
      }
    }
  });
});