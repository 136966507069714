define('awesome2/components/types/google-cal', ['exports', 'ember-add-calendar-button/components/types/google-cal'], function (exports, _googleCal) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _googleCal.default;
    }
  });
});