define('awesome2/mixins/component-base-mixin', ['exports', 'awesome2/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const { host, namespace } = _environment.default.DS;
    //mixin https://dockyard.com/blog/2015/11/09/best-practices-extend-or-mixin
    //https://spin.atomicobject.com/2016/06/06/ember-scroll-to-top/

    const baseUrl = host + "/" + namespace;
    exports.default = Ember.Mixin.create({
        baseUrl: baseUrl,
        rootUrl: host,
        imageUrl: host + "/images",
        environment: _environment.default.ENVIRONMENT
    });
});