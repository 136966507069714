define('awesome2/helpers/number-of-days', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.numberOfDays = numberOfDays;
  function numberOfDays(params /*, hash*/) {
    var diff = Math.abs(Date.now() - new Date(params));
    return diff / (1000 * 60 * 60 * 24);
  }

  exports.default = Ember.Helper.helper(numberOfDays);
});