define('awesome2/services/language', ['exports', 'awesome2/adapters/language'], function (exports, _language) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({

    availableLanguages() {
      // this simulates fetching data across the network
      var adapter = _language.default.create();
      return adapter.availableTranslation();
    },
    cities() {
      // this simulates fetching data across the network
      return new Ember.RSVP.Promise(function (resolve) {
        Ember.run.later(function () {
          resolve(["Paris", "Washington DC", "Bogota", "Nairobi", "Auckland"]);
        }, 3000);
      });
    }

  });
});