define('awesome2/routes/accounts/info', ['exports', 'ember-simple-auth/mixins/application-route-mixin', 'ember-simple-auth/mixins/authenticated-route-mixin', 'ember-local-storage'], function (exports, _applicationRouteMixin, _authenticatedRouteMixin, _emberLocalStorage) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
        userService: Ember.inject.service("user"),
        authManager: Ember.inject.service('session'),
        titleToken: ['nav.user-info'],
        user: (0, _emberLocalStorage.storageFor)("user"),

        model(params) {
            //var user = this.get("store").createRecord('user');
            let user = this.get("user");
            let login = user.get("login");
            return this.get('userService').fetch(login).then(data => {
                return data.UserInfoOut[0];
            }).catch(data => {
                //in the event of not authorized
                //invalidate session
                //route to login page
                //TODO should be handled at each 403 http status 
                //this.get('authManager').invalidate();
                /*
                let user = this.get("user");
                let login = user.login;
                user.clear();
                this.transitionTo("accounts.login");
                */
            });
        }
    });
});