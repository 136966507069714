define('awesome2/routes/home', ['exports', 'awesome2/routes/base', 'awesome2/adapters/event', 'awesome2/adapters/winery'], function (exports, _base, _event, _winery) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _base.default.extend({

        model(params) {
            var eA = _event.default.create();
            var wA = _winery.default.create();

            var eventSummaryPromise = eA.eventSummary(params).then(data => {
                return data.EventSummaryOut;
            });

            var wineryCountrySummaryPromise = wA.wineryCountrySummary(params).then(data => {
                return data.WineryCountrySummaryOut;
            });

            var wineryRegionSummaryPromise = wA.wineryRegionSummary(params).then(data => {
                return data.WineryRegionSummaryOut;
            });
            var promises = {
                wineryCountrySummary: wineryCountrySummaryPromise,
                wineryRegionSummary: wineryRegionSummaryPromise,
                eventSummary: eventSummaryPromise
            };
            return Ember.RSVP.hash(promises).then(data => {
                return data;
            });
        }
    });
});