define('awesome2/components/snippet/icon-snippet', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',

        icon: '',
        iconFamily: '',
        color: '',
        backgroundColor: '',
        myStyle: Ember.computed('color', 'backgroundColor', function () {
            var colorStyle = this.get('color') ? "color : #" + this.get('color') : '';
            var colorBackgroundStyle = this.get('backgroundColor') ? "background-color : #" + this.get('backgroundColor') : '';
            return Ember.String.htmlSafe(colorStyle + ';' + colorBackgroundStyle);
        }),
        myIcon: Ember.computed('icon', 'iconFamily', function () {
            return Ember.String.htmlSafe(this.get('icon') + ' ' + this.get('iconFamily'));
        })
    });
});