define('awesome2/adapters/place', ['exports', 'ember-data', 'awesome2/utils/common-utils', 'awesome2/adapters/base', 'awesome2/config/environment'], function (exports, _emberData, _commonUtils, _base, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const { host, namespace } = _environment.default.DS;
    const baseUrl = host + "/" + namespace;

    exports.default = _base.default.extend({
        find: function (webpath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/LieuDitBadgeIn?webPath=' + webpath);
        },
        findByRegionWebPath: function (regionWebPath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/LieuDitBadgeIn?regionWebPath=' + regionWebPath);
        },
        findByCountryWebPath: function (countryWebPath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/LieuDitBadgeIn?countryWebPath=' + countryWebPath);
        },
        findByAppellationWebPath: function (appellationWebPath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/LieuDitBadgeIn?appellationWebPath=' + appellationWebPath);
        },
        availableLieuDitsForRegion: function (regionId) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/LieuDitListIn?regionId=' + regionId);
        }
    });
});