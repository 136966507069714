define('awesome2/adapters/event', ['exports', 'awesome2/utils/common-utils', 'awesome2/adapters/base', 'npm:append-query', 'awesome2/config/environment'], function (exports, _commonUtils, _base, _npmAppendQuery, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const { host, namespace } = _environment.default.DS;
    const baseUrl = host + "/" + namespace;

    exports.default = _base.default.extend({
        findAll: function (params) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/EventInfoIn' + formatEventInfoFilter(params));
        },
        findByWebpath: function (webPath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/EventInfoIn?webPath=' + webPath);
        },
        findByWineryWebpath: function (wineryWebPath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/ParticipantsForEventIn?wineryWebPath=' + wineryWebPath);
        },
        findByEventAndWineryWebpath: function (eventWebPath, wineryWebPath) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/ParticipantsForEventIn?wineryWebPath=' + wineryWebPath + '&eventWebPath=' + eventWebPath);
        },
        //the email is provided by the jwt token resolution
        // hasWineryEventOwnership: function(wineryWebPath, eventWebPath, email){
        //     return fetchData(baseUrl+'/data/sdd/HasWineryEventOwnershipIn?wineryWebPath='+wineryWebPath+'&eventWebPath='+eventWebPath+"&userEmail="+email);
        // },
        //the email is provided by the jwt token resolution
        hasWineryOwnership: function (wineryWebPath) {
            return (0, _commonUtils.fetchDataJwt)(baseUrl + '/data/sdd/HasWineryOwnershipIn?wineryWebPath=' + wineryWebPath, "GET"); //+"&userEmail="+email);
        },
        eventSummary: function (params) {
            return (0, _commonUtils.fetchData)(baseUrl + '/data/sdd/EventSummaryIn' + filterEventSummary(params));
        }

    });


    function formatEventInfoFilter(filters) {
        if (filters) {
            return (0, _npmAppendQuery.default)("", {
                "countryWebPath": filters.country,
                "webPath": filters.webPath,
                "timeline": filters.timeline,
                "order_by": filters.orderBy,
                "fromDateGreaterThan": filters.fromDateGreaterThan

            }, { removeNull: true });
        }
        return "";
    }

    /* filter for EventSummary entity */
    function filterEventSummary(filter) {
        if (filter) {
            return (0, _npmAppendQuery.default)("", {
                "webPath": filter.webPath,
                "countryWebPath": filter.countryWebPath,
                "timeline": filter.timeline
            }, { removeNull: true });
        }
        return "";
    }
});