define('awesome2/adapters/purchase', ['exports', 'ember-data', 'awesome2/utils/common-utils', 'awesome2/adapters/base', 'awesome2/config/environment'], function (exports, _emberData, _commonUtils, _base, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    const { host, namespace } = _environment.default.DS;
    const baseUrl = host + "/" + namespace;

    exports.default = _base.default.extend({

        //TODO protect with jwt
        storePurchaseOrder: function (login, gender, firstName, lastName, languageCode, json) {
            return $.post(baseUrl + '/data/sdd/StorePurchaseOrderIn', {
                login: login,
                gender: gender,
                firstName: firstName,
                lastName: lastName,
                json: json,
                languageCode: languageCode
            });
        },

        confirmPurchaseOrder: function (login, gender, firstName, lastName, languageCode, key, json) {
            return $.post(baseUrl + '/data/sdd/ConfirmPurchaseOrderIn', {
                login: login,
                gender: gender,
                firstName: firstName,
                lastName: lastName,
                languageCode: languageCode,
                key: key,
                json: json
            });
        }

    });
});