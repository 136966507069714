define('awesome2/routes/wizard/show', ['exports', 'awesome2/adapters/payplans'], function (exports, _payplans) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model(params) {

            var adapter = _payplans.default.create();
            return adapter.find(params.payplan_id).then(function (data) {
                data.params = params;
                return data.PayPlanConditionMatrixOut;
            }).catch(function (error) {
                console.log("error = " + error);
                //reject(error);
            });
            //https://guides.emberjs.com/v2.14.0/models/
            //return this.get('store').findRecord('payplan', params.payplan_id);
        },
        queryParams: {
            payplanId: {
                replace: true
            }
        }
    });
});