define('awesome2/controllers/base', ['exports', 'awesome2/config/environment'], function (exports, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({

        environment: _environment.default.ENVIRONMENT,

        session: Ember.inject.service('session'),
        actions: {
            invalidateSession() {
                this.get('session').invalidate();
            }
        }

    });
});