define('awesome2/services/user', ['exports', 'awesome2/adapters/user'], function (exports, _user) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        fetch(email) {
            var adapter = _user.default.create();
            return adapter.fetch(email);
        },

        fetchCurrentPurchaseOrder(email) {
            var adapter = _user.default.create();
            return adapter.fetchCurrentPurchaseOrder(email);
        }
    });
});