define('awesome2/components/snippet/watermark-snippet', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        label: null,
        labelDisplay: Ember.computed('label', function () {
            return Ember.String.htmlSafe(`${this.get('label')} <br/><br/>`.repeat(2));
        })

    });
});