define('awesome2/controllers/feedback', ['exports', 'awesome2/adapters/post-entity', 'awesome2/models/feedback', 'awesome2/controllers/base', 'awesome2/utils/notification-utils'], function (exports, _postEntity, _feedback, _base, _notificationUtils) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({

    queryParams: ['origin'],
    origin: null,

    //TUTO simple computed value
    isValidEmail: Ember.computed.match('model.email', /^.+@.+\..+$/),
    isValidCategory: Ember.computed.gte('model.category.length', 2),
    isValidFreeComment: Ember.computed.notEmpty('model.comment'),

    isSent: false,

    //TUTO nested computed value
    isDisabledOnAttribute: Ember.computed('isValidFreeComment', 'isValidEmail', 'isValidCategory', function () {
      return !(this.get('isValidFreeComment') && this.get('isValidEmail') && this.get('isValidCategory'));
    }),
    isDisabledOnSend: Ember.computed('isSent', function () {
      return this.get('isSent');
    }),
    isDisabled: Ember.computed('isDisabledOnAttribute', 'isDisabledOnSend', function () {
      return this.get('isDisabledOnAttribute') || this.get('isDisabledOnSend');
    }),

    actions: {
      handleOption(event) {
        //TUTO DOM manipulation with jQuery
        $("#category").val(event.srcElement.innerText);
        this.get("model").set("category", event.srcElement.innerText);
      },
      feedback(newFeedback) {
        this.set('isSent', true);
        if (this.get("origin")) {
          this.set("model.origin", this.get("origin"));
        }
        var adapter = _postEntity.default.create();
        adapter.feedback(this.get("model")).then(data => {
          (0, _notificationUtils.notifySuccess)(this, "notification.feedback-submitted");
          //reset model
          //DOES NOT work 2nd time this.set("model", {});
          //DOES NOT work at all this.set("model", Feedback.create());
          this.set('isSent', false);
          this.set("model.email", "");
          this.set("model.category", "");
          this.set("model.comment", "");
        }).catch(data => {
          (0, _notificationUtils.notifyFailure)(this, "notification.error");
          this.set('isSent', false);
        });
      }
    }

  });
});